import React, {Component, ReactElement} from "react";
import {Brand, BrandsApi} from "../../../service/domain/brands";
import DynamicSelect from "../../common/forms/select-named-dynamic";

export interface BrandFilterProps {
  service: BrandsApi;
  onSelect: (item: Brand | null) => void;
  onLoaded?: (items: Brand[]) => void;
  initialValue?: string;
  disabled?: boolean;
}

export default class BrandFilter extends Component<BrandFilterProps> {
  render(): ReactElement {
    const {initialValue, disabled, service, onSelect, onLoaded} = this.props;
    return (
      <React.Fragment>
        <dt>Brand</dt>
        <dd className="select-wrapper">
          <DynamicSelect<Brand>
            load={service.getBrands}
            onSelect={onSelect}
            onLoaded={onLoaded}
            initialValue={initialValue}
            disabled={disabled}
          />
        </dd>
      </React.Fragment>
    );
  }
}
