import {Button, Checkbox, TextField} from "@material-ui/core";
import React, {ChangeEvent, Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {changeHandler} from "../../../common/forms";
import {PersonDetails} from "../../../service/domain/persons";
import {IServices} from "../../../service/services";
import Alert, {AlertSeverity} from "../../common/alert";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import {exportFile} from "../../../common/files";

export interface SearchAccountProps {
  services: IServices;
}

export interface SearchAccountState {
  search: string;
  error: ApplicationError | null;
  waiting: boolean;
  personDetails: PersonDetails | null;
  notFound: boolean;
  includeProps: boolean;
}

class SearchAccountPage extends Component<
  SearchAccountProps,
  SearchAccountState
> {
  constructor(props: SearchAccountProps) {
    super(props);

    this.state = {
      search: "",
      error: null,
      waiting: false,
      personDetails: null,
      notFound: false,
      includeProps: false,
    };
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    changeHandler.call(this, event);
  }

  search(): void {
    if (this.state.waiting) {
      return;
    }
    const {search} = this.state;

    this.setState({
      personDetails: null,
      waiting: true,
      error: null,
      notFound: false,
    });

    this.props.services.persons
      .getPersonDetails(search.trim(), this.state.includeProps)
      .then(
        (data) => {
          this.setState({
            personDetails: data,
            waiting: false,
            notFound: data === null,
          });
        },
        (error) => {
          this.setState({
            error,
            waiting: false,
          });
        }
      );
  }

  download(): void {
    const {personDetails} = this.state;
    if (personDetails === null) {
      return;
    }

    exportFile(
      `${personDetails.email}.json`,
      JSON.stringify(personDetails, undefined, 4),
      "application/json"
    );
  }

  toggleProps(): void {
    this.setState({
      includeProps: !this.state.includeProps,
    });
  }

  render(): ReactElement {
    const {error, personDetails, notFound, search, waiting, includeProps} =
      this.state;
    return (
      <div className="wrapper accounts-support">
        {waiting && <Loader className="overlay" />}
        <h1>Search accounts</h1>
        <section>
          <dl>
            <dt>
              <label>Enter an email address</label>
            </dt>
            <dd>
              <TextField
                value={search}
                name="search"
                autoComplete="off"
                onChange={this.onChange.bind(this)}
              />
            </dd>
            <dt>Include properties (slow operation)</dt>
            <dd>
              <Checkbox
                checked={includeProps}
                onClick={() => this.toggleProps()}
              />
            </dd>
          </dl>
          <Button onClick={() => this.search()}>Search</Button>
        </section>
        {error && (
          <ErrorPanel
            error={error}
            dismiss={() => {
              this.setState({error: null});
            }}
          />
        )}
        {personDetails && (
          <div>
            <p>Result for: {personDetails.email}</p>
            <pre>{JSON.stringify(personDetails, undefined, 4)}</pre>
            <Button onClick={() => this.download()}>Download</Button>
          </div>
        )}
        {notFound && (
          <Alert
            title="Not found"
            message="No account was found for the given email address."
            severity={AlertSeverity.info}
          />
        )}
      </div>
    );
  }
}

export default SearchAccountPage;
