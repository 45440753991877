import defer from "lodash/defer";
import React, {Component, ReactElement, ChangeEvent} from "react";
import {OrganizationsApi} from "../../../service/domain/organizations";
import {ApplicationError} from "../../../common/errors";
import {TextField} from "@material-ui/core";
import {changeHandler} from "../../../common/forms";

export interface OrganizationNameFieldProps {
  organizationsService: OrganizationsApi;
  onChange?: (value: string) => void;
  value?: string | null;
  disabled?: boolean;
}

export interface OrganizationNameFieldState {
  error?: ApplicationError;
  value: string;
  valueError: boolean;
  valueHelperText: string;
}

export default class OrganizationNameField extends Component<
  OrganizationNameFieldProps,
  OrganizationNameFieldState
> {
  constructor(props: OrganizationNameFieldProps) {
    super(props);

    this.state = {
      value: props.value || "",
      valueError: false,
      valueHelperText: "",
    };
  }

  public get value(): string {
    return this.state.value;
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    changeHandler.call(this, event);

    defer(() => {
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }
    });
  }

  async validate(): Promise<boolean> {
    let error = "";
    const value = this.state.value.trim();

    if (!value) {
      error = "Please insert a value";
    }

    if (error) {
      this.setState({
        valueError: true,
        valueHelperText: error,
      });
      return false;
    }

    this.setState({
      valueError: false,
      valueHelperText: "",
    });
    return true;
  }

  onBlur(): void {
    const value = this.state.value;

    if (value !== value.trim()) {
      this.setState({
        value: value.trim(),
      });
    }
    defer(() => {
      this.validate();
    });
  }

  render(): ReactElement {
    const {value, valueError, valueHelperText} = this.state;
    return (
      <TextField
        variant="outlined"
        error={valueError}
        helperText={valueHelperText}
        name="value"
        value={value}
        autoFocus
        autoComplete="off"
        onChange={this.onChange.bind(this)}
        onBlur={this.onBlur.bind(this)}
        disabled={this.props.disabled}
      />
    );
  }
}
