import debounce from "lodash/debounce";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {SortOrder} from "../../common/tables";
import {CommonFiltersState} from "../common/common-filters";
import CommonFiltersView from "../common/common-filters";
import {ApplicationError} from "../../../common/errors";
import {
  ApplicationTableItem,
  UpsertApplicationInput,
} from "../../../service/domain/applications";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {
  ItemProperty,
  ItemAction,
  StaticTable,
} from "../../common/tables/tables";
import EditOutlined from "@material-ui/icons/EditOutlined";
import {IServices} from "../../../service/services";
import {
  loadData,
  showEditViewInDialog,
  dismissDialog,
  addErrorToDialog,
} from "../common/view-functions";
import EditApplicationForm from "./application-edit-form";

export interface ApplicationsTableProps {
  services: IServices;
  noExtraButtons?: boolean;

  onItemClick?: (item: ApplicationTableItem) => void;
}

export interface ApplicationsTableState {
  loading: boolean;
  error?: ApplicationError;
  items: ApplicationTableItem[];
  selectedItem?: ApplicationTableItem;
  total: number;
  page: number;
  filters: CommonFiltersState;
  sortProperty: string;
  sortOrder: SortOrder;
  confirm: ConfirmDialogProps;
}

function getProperties(): ItemProperty<ApplicationTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: ApplicationTableItem) => {
        return item.id;
      },
    },
    {
      id: "name",
      label: "Name",
      render: (item: ApplicationTableItem) => {
        return item.name;
      },
    },
    {
      id: "tenantName",
      label: "Tenant name",
      render: (item: ApplicationTableItem) => {
        return item.tenantName;
      },
    },
    {
      id: "invitationRedirectUrl",
      label: "Invitation redirect URL",
      render: (item: ApplicationTableItem) => {
        return item.invitationRedirectUrl;
      },
    },
  ];
}

export default class ApplicationsTable extends Component<
  ApplicationsTableProps,
  ApplicationsTableState
> {
  constructor(props: ApplicationsTableProps) {
    super(props);

    this.state = {
      loading: true,
      error: undefined,
      items: [],
      total: 0,
      page: 1,
      sortOrder: "asc",
      sortProperty: "name",
      filters: {
        search: "",
        selectedBrandId: "",
        selectedOrganizationId: "",
      },
      confirm: closedDialog(),
    };
  }

  componentDidMount(): void {
    this.load();
  }

  applyFilters = debounce(() => {
    this.load();
  }, 300);

  load(): void {
    this.setState({
      loading: true,
      error: undefined,
    });

    const {filters, sortProperty, sortOrder} = this.state;

    this.props.services.applications
      .getApplications(
        filters.selectedBrandId,
        `${sortProperty} ${sortOrder}`,
        filters.search
      )
      .then(
        (data: ApplicationTableItem[]) => {
          this.setState({
            items: data,
            total: data.length,
            error: undefined,
            loading: false,
          });
        },
        (error: ApplicationError) => {
          this.setState({
            error,
            loading: false,
          });
        }
      );
  }

  onPageChange(page: number): void {
    this.setState({page});

    setTimeout(() => {
      this.load();
    }, 0);
  }

  onFiltersChange(filters: CommonFiltersState): void {
    this.setState({
      page: 1,
      filters,
    });

    this.applyFilters();
  }

  onSort(prop: string, order: SortOrder): void {
    this.setState({
      sortOrder: order,
      sortProperty: prop,
    });

    this.applyFilters();
  }

  render(): ReactElement {
    const {confirm, items, loading, error} = this.state;

    return (
      <div>
        {loading && <Loader className="overlay" />}
        <CommonFiltersView onChange={this.onFiltersChange.bind(this)} />
        {error !== undefined && <ErrorPanel error={error} />}
        {error === undefined && (
          <StaticTable<ApplicationTableItem>
            properties={getProperties()}
            defaultSortOrder="asc"
            defaultSortProperty="name"
            onSort={this.onSort.bind(this)}
            actions={this.getActions()}
            detailsRoute={"/applications/"}
            items={items}
          />
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  async onEditClick(item: ApplicationTableItem): Promise<void> {
    // this is possible only when the brand service is configured
    const {services} = this.props;
    const {applications} = services;
    const details = await loadData(this, () => {
      return applications.getApplicationDetails(item.id);
    });

    if (details === null) {
      // TODO: handle this unlikely scenario, show some information
      return;
    }

    showEditViewInDialog(
      this,
      `Edit application "${item.name}".`,
      <EditApplicationForm
        details={details}
        services={services}
        onUpsert={(u: UpsertApplicationInput) => this.handleUpdate(u, item)}
        onCancel={() => dismissDialog(this)}
        onError={(e) => addErrorToDialog(this, e)}
      />
    );
  }

  handleUpdate(
    update: UpsertApplicationInput,
    item: ApplicationTableItem
  ): void {
    // update only properties that are visible on the table
    item.name = update.name;
    item.tenantName = update.tenantName;
    item.invitationRedirectUrl = update.invitationRedirectUrl;

    dismissDialog(this);
  }

  getActions(): Array<ItemAction<ApplicationTableItem>> {
    return [
      {
        title: "Edit",
        icon: <EditOutlined />,
        onClick: this.onEditClick.bind(this),
      },
    ];
  }
}
