export enum AppRoles {
  User = "ATUser",
  SuperUser = "ATSuperUser",
  HCPRead = "HCPRead",
  SendInvitation = "SendInvitation",
  InvitationRead = "InvitationRead",
  InvitationWrite = "InvitationWrite",
  InvitationRequestRead = "InvitationRequestRead",
  InvitationRequestWrite = "InvitationRequestWrite",
  OrganizationRead = "OrganizationRead",
  OrganizationWrite = "OrganizationWrite",
  OrganizationDelete = "OrganizationDelete",
  DashboardLinking = "ATDashboardLinking",
}

export const ROLES = {
  ATUser: {
    displayName: "User",
    description: `This is the most basic role, it grants you read access to
    the system, and the ability to invite HCPs for existing organizations.`,
  },
  ATSuperUser: {
    displayName: "Super User",
    description:
      "You can do everything, you probably belong to the Cloud Heroes Team.",
  },
  OrganizationWrite: {
    displayName: "Organization Write",
    description: `This role grants you write access for organizations (HCBs),
    therefore you can create new organizations and update them.`,
  },
  OrganizationDelete: {
    displayName: "Organization Delete",
    description: `This role grants you rights to delete organizations (HCBs),
    use it carefully!`,
  },
  ATDashboardLinking: {
    displayName: "Dashboard&Linking",
    description: `This role grants you access to the Dashboard&Linking
    feature. If you also have write rights for organizations, you can enable
    and disable this feature.`,
  },
  HCPRead: {
    displayName: "Read HCPs",
    description: `Normally only the list of HCP Admins is enabled. This role
    enables the list of regular HCPs, too.`,
  },
};
