import ServiceSettings from "./settings";
import {ApplicationInsights} from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: ServiceSettings.monitoringKey,
    enableAutoRouteTracking: true,
  },
});
appInsights.loadAppInsights();
appInsights.trackPageView();

export default appInsights;
