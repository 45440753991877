import {uniqueId} from "lodash";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import ErrorPanel from "../error";
import Loader from "../loader";

export enum OperationStatus {
  pending = "pending",
  success = "success",
  failure = "failure",
}

export interface Operation {
  description: string;
  status: OperationStatus;
  error: ApplicationError | null;
}

export interface OperationsTableProps {
  operations: Operation[];
}

export default class OperationsTable extends Component<OperationsTableProps> {
  renderStatus(status: OperationStatus): ReactElement {
    switch (status) {
      case OperationStatus.pending:
        return <Loader className="tiny" />;

      case OperationStatus.failure:
        return <i className="fa fa-ban ko" aria-hidden="true"></i>;

      case OperationStatus.success:
        return <i className="fa fa-check-circle ok" aria-hidden="true"></i>;
    }
  }

  render(): ReactElement {
    const {operations} = this.props;
    return (
      <div className="operations-table">
        <table>
          <tbody>
            {operations.map((item) => {
              return (
                <tr key={uniqueId("entry-")}>
                  <td>{item.description}</td>
                  <td>{this.renderStatus(item.status)}</td>
                  <td>{item.error && <ErrorPanel error={item.error} />}</td>
                </tr>
              );
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
    );
  }
}
