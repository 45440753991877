import Alert, {AlertSeverity} from "../../common/alert";
import DynamicMultiCheckbox from "../../common/forms/multi-checkbox-dynamic";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import MarketSelect from "../markets/markets-select";
import NamedSelect from "../../common/forms/select-named";
import OrganizationNameField from "./organization-name-field";
import OrganizationNumberField from "./organization-number-field";
import React, {Component, ReactElement} from "react";
import {ApplicationError, InterfaceError} from "../../../common/errors";
import {Brand, BrandsApi} from "../../../service/domain/brands";
import {Button, Grid} from "@material-ui/core";
import {Category} from "../../../service/domain/categories";
import {MarketsApi} from "../../../service/domain/markets";

import {
  OrganizationsApi,
  UpdateOrganizationInput,
  OrganizationDetails,
} from "../../../service/domain/organizations";

export interface EditOrganizationProps {
  details: OrganizationDetails;
  brandsService: BrandsApi;
  marketsService: MarketsApi;
  organizationsService: OrganizationsApi;
  onCancel?: () => void;
  onUpdate: (update: UpdateOrganizationInput) => void;
  buttons?: ReactElement[];
  readonly?: boolean;
}

export interface EditOrganizationState {
  waiting: boolean;
  error?: ApplicationError;
  confirmEditWarning: string;
  possibleCategories: Category[];
}

export default class EditOrganizationForm extends Component<
  EditOrganizationProps,
  EditOrganizationState
> {
  private nameField: React.RefObject<OrganizationNameField>;
  private numberField: React.RefObject<OrganizationNumberField>;
  private brandsControl: React.RefObject<DynamicMultiCheckbox<Brand>>;
  private marketsControl: React.RefObject<MarketSelect>;
  private categoryControl: React.RefObject<NamedSelect<Category>>;

  constructor(props: EditOrganizationProps) {
    super(props);

    this.state = this.initialState();

    this.nameField = React.createRef();
    this.numberField = React.createRef();
    this.brandsControl = React.createRef();
    this.marketsControl = React.createRef();
    this.categoryControl = React.createRef();
  }

  initialState(): EditOrganizationState {
    return {
      waiting: false,
      confirmEditWarning: "",
      possibleCategories: [],
    };
  }

  async validate(): Promise<boolean> {
    const results = await Promise.all([
      this.nameField.current?.validate(),
      this.numberField.current?.validate(),
      this.marketsControl.current?.validate(),
    ]);

    return results.every((item) => item === true);
  }

  async save(): Promise<void> {
    if (!(await this.validate())) {
      return;
    }

    this.setState({
      waiting: true,
      error: undefined,
    });

    const {details} = this.props;

    const name = this.nameField.current?.value;
    const number = this.numberField.current?.value;
    const brands = this.brandsControl.current?.value;
    const markets = this.marketsControl.current?.value;
    const categoryId = this.categoryControl.current?.value;

    if (!name || !number || !brands || !markets) {
      throw new InterfaceError("Expected populated values.");
    }

    const update = {
      id: details.id,
      name: name,
      number: number,
      brands: brands.map((item) => item.id),
      markets: markets.map((item) => item.id),
      categoryId: categoryId ? categoryId : null,
    };

    try {
      await this.props.organizationsService.updateOrganization(update);

      this.setState({
        waiting: false,
      });
      this.props.onUpdate(update);
    } catch (error) {
      this.setState({
        waiting: false,
        error,
      });
    }
  }

  cancel(): void {
    this.setState(this.initialState());

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  setConfirmEditWarning(): void {
    this.setState({
      confirmEditWarning:
        "After you have confirmed the changes, " +
        "organization's" +
        " administrators will receive an email informing that the " +
        "Dashboard&Linking is turned on.",
    });
  }

  dismissConfirmEditWarning(): void {
    this.setState({
      confirmEditWarning: "",
    });
  }

  onBrandSelect(brands: Brand[]): void {
    if (brands.length === 1) {
      // check if category selection should be displayed
      const selectedBrand = brands[0];

      if (selectedBrand.categories && selectedBrand.categories.length > 0) {
        this.setState({
          possibleCategories: selectedBrand.categories,
        });
        return;
      }
    }

    if (this.state.possibleCategories.length > 0)
      this.setState({
        possibleCategories: [],
      });
  }

  onBrandsLoaded(brands: Brand[]): void {
    const selectedBrands = this.props.details.brands;

    if (selectedBrands.length === 1) {
      // check if category selection should be displayed
      const selectedBrand = selectedBrands[0];
      const brandDetails = brands.find((item) => item.id === selectedBrand.id);

      if (
        brandDetails &&
        brandDetails.categories &&
        brandDetails.categories.length > 0
      ) {
        this.setState({
          possibleCategories: brandDetails.categories,
        });
      }
    }
  }

  render(): ReactElement {
    const {
      buttons,
      details,
      organizationsService,
      onCancel,
      readonly,
      brandsService,
      marketsService,
    } = this.props;
    const {waiting, confirmEditWarning, error, possibleCategories} =
      this.state;

    return (
      <div className="organization-edit-form">
        {waiting && <Loader className="overlay" />}
        {confirmEditWarning && (
          <Grid item xs={12}>
            <Alert
              title="Email notification"
              message={confirmEditWarning}
              severity={AlertSeverity.info}
              dismiss={() => this.dismissConfirmEditWarning()}
            />
          </Grid>
        )}
        <dl>
          <dt>Name</dt>
          <dd>
            <OrganizationNameField
              organizationsService={organizationsService}
              value={details.name}
              ref={this.nameField}
              disabled={readonly}
            />
          </dd>
          <dt>Number</dt>
          <dd>
            <OrganizationNumberField
              id={details.id}
              value={details.number}
              ref={this.numberField}
              organizationsService={organizationsService}
              disabled={readonly}
            />
          </dd>
          <dt>Brands</dt>
          <dd className="checkboxs-wrapper">
            <DynamicMultiCheckbox<Brand>
              load={brandsService.getBrands}
              selected={details.brands}
              readonly={readonly}
              ref={this.brandsControl}
              onSelect={(selection) => this.onBrandSelect(selection)}
              onLoaded={(brands) => this.onBrandsLoaded(brands)}
              autoSelectSingle
            />
          </dd>
          {possibleCategories && possibleCategories.length > 0 && (
            <>
              <dt>Category</dt>
              <dd>
                <NamedSelect
                  items={possibleCategories}
                  initialValue={details.categoryId || undefined}
                  ref={this.categoryControl}
                  autoSelectSingle
                />
              </dd>
            </>
          )}
          <dt>Markets</dt>
          <dd className="checkboxs-wrapper">
            <MarketSelect
              marketsService={marketsService}
              selected={details.markets}
              readonly={readonly}
              ref={this.marketsControl}
            />
          </dd>
        </dl>
        <div className="form-buttons">
          {onCancel && (
            <Button
              onClick={() => this.cancel()}
              autoFocus
              className="cancel-button"
            >
              Cancel
            </Button>
          )}
          {buttons}
          <Button
            onClick={() => this.save()}
            className="confirm-button"
            color="secondary"
            disabled={readonly}
          >
            Confirm
          </Button>
        </div>
        {error && <ErrorPanel error={error} />}
      </div>
    );
  }
}
