import Query from "../../common/query";
import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {get} from "../../common/fetch";
import {Market, MarketsApi} from "../domain/markets";

export default class OneCustomerMarketsApi implements MarketsApi {
  @cache()
  getMarkets(sortBy: string, search?: string): Promise<Market[]> {
    const query = Query.write({
      sortBy,
      search,
    });
    return get<Market[]>(`${BaseURL}/markets${query}`);
  }
}
