import { ValueError, InterfaceError } from "./errors";

/**
 * Validates a single email address value.
 */
export function validateEmail(value: string): boolean {
  return /^([a-zA-Z0-9_\-.+]+)@([a-zA-Z0-9_\-.+]+)\.([a-zA-Z]{2,5}){1,25}$/
    .test(value);
}


/**
 * Splits a string into several email addresses, validating each and throwing
 * a ValueError at the first invalid value.
 */
export function splitEmails(value: string): string[] {
  if (!value) {
    return [];
  }

  return value.toLowerCase().split(/,|;|\s/g)
    .filter(item => !!item)
    .map(item => {
      const singleValue = item.trim();

      if (!validateEmail(singleValue)) {
        throw new ValueError(singleValue);
      }

      return singleValue;
    });
}


/**
 * Splits a string into several email addresses, validating each and populating
 * a given array with invalid values.
 *
 * Duplicate value are removed automatically.
 */
export function splitEmailsCollectErrors(
  value: string,
  errors: string[]
): string[] {
  if (!value) {
    return [];
  }
  if (errors.length) {
    throw new InterfaceError("errors parameter must be an empty array.");
  }

  return value.toLowerCase().split(/,|;|\s/g)
    .filter(item => !!item)
    .map(item => {
      const singleValue = item.trim();

      if (!validateEmail(singleValue)) {
        errors.push(singleValue);
        return "";
      }

      return singleValue;
    })
    .filter(item => !!item)
    .filter((value, index, self) => self.indexOf(value) === index);
}
