import AlertPanel, {AlertSeverity} from "../../common/alert";
import DynamicMultiCheckbox from "../../common/forms/multi-checkbox-dynamic";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import OrganizationDetailsView from "./organization-details";
import OrganizationNameField from "./organization-name-field";
import OrganizationNumberField from "./organization-number-field";
import React, {Component, ReactElement} from "react";
import {ApplicationError, InterfaceError} from "../../../common/errors";
import {Brand, BrandsApi} from "../../../service/domain/brands";
import {Button} from "@material-ui/core";
import {Market, MarketsApi} from "../../../service/domain/markets";
import {
  OrganizationsApi,
  OrganizationDetails,
} from "../../../service/domain/organizations";
import MarketSelect from "../markets/markets-select";
import {Category} from "../../../service/domain/categories";
import NamedSelect from "../../common/forms/select-named";

export interface NewOrganizationProps {
  selectedBrands?: Array<Brand | string>;
  brandsService: BrandsApi;
  marketsService: MarketsApi;
  organizationsService: OrganizationsApi;
  onCreated?: (item: OrganizationDetails) => void;
  readonly?: boolean;
  disableButtons?: boolean;
}

export interface NewOrganizationState {
  waiting: boolean;
  error?: ApplicationError;
  details?: OrganizationDetails;
  possibleCategories: Category[];
}

export default class NewOrganizationForm extends Component<
  NewOrganizationProps,
  NewOrganizationState
> {
  private nameField: React.RefObject<OrganizationNameField>;
  private numberField: React.RefObject<OrganizationNumberField>;
  private brandsControl: React.RefObject<DynamicMultiCheckbox<Brand>>;
  private marketsControl: React.RefObject<MarketSelect>;
  private categoryControl: React.RefObject<NamedSelect<Category>>;

  constructor(props: NewOrganizationProps) {
    super(props);

    this.state = this.initialState();

    this.nameField = React.createRef();
    this.numberField = React.createRef();
    this.brandsControl = React.createRef();
    this.marketsControl = React.createRef();
    this.categoryControl = React.createRef();
  }

  initialState(): NewOrganizationState {
    return {
      waiting: false,
      error: undefined,
      possibleCategories: [],
    };
  }

  async validate(): Promise<boolean> {
    const results = await Promise.all([
      this.nameField.current?.validate(),
      this.numberField.current?.validate(),
      this.marketsControl.current?.validate(),
    ]);

    return results.every((item) => item === true);
  }

  async save(): Promise<boolean> {
    if (!(await this.validate())) {
      return false;
    }

    this.setState({
      waiting: true,
      error: undefined,
    });

    const name = this.nameField.current?.value;
    const number = this.numberField.current?.value;
    const brands = this.brandsControl.current?.value;
    const markets = this.marketsControl.current?.value;
    const categoryId = this.categoryControl.current?.value;

    if (!name || !number || !brands || !markets) {
      throw new InterfaceError("Expected populated values.");
    }

    try {
      const details = await this.props.organizationsService.createOrganization(
        {
          name: name,
          number: number,
          brands: brands.map((item) => item.id),
          markets: markets.map((item) => item.id),
          categoryId: categoryId ? categoryId : null,
        }
      );
      this.setState({
        waiting: false,
        details,
      });

      const onCreated = this.props.onCreated;
      if (onCreated) {
        onCreated(details);
      }

      return true;
    } catch (error) {
      this.setState({
        waiting: false,
        error,
      });

      return false;
    }
  }

  getMarkets(): Promise<Market[]> {
    return this.props.marketsService.getMarkets("", "");
  }

  onResetClick(): void {
    this.setState({
      waiting: false,
      error: undefined,
      details: undefined,
    });
  }

  onBrandSelect(brands: Brand[]): void {
    if (brands.length === 1) {
      // check if category selection should be displayed
      const selectedBrand = brands[0];

      if (selectedBrand.categories && selectedBrand.categories.length > 0) {
        this.setState({
          possibleCategories: selectedBrand.categories,
        });
        return;
      }
    }

    if (this.state.possibleCategories.length > 0)
      this.setState({
        possibleCategories: [],
      });
  }

  render(): ReactElement {
    const {readonly, organizationsService, selectedBrands, disableButtons} =
      this.props;
    const {waiting, error, details, possibleCategories} = this.state;

    if (details) {
      // an organization has been created, show a summary view
      return (
        <div>
          <AlertPanel
            title="Organization created successfully"
            message=""
            severity={AlertSeverity.success}
          />
          <OrganizationDetailsView {...details} />
          <div className="buttons-area">
            <Button onClick={() => this.onResetClick()}>
              Create a new one
            </Button>
          </div>
        </div>
      );
    }

    return (
      <div>
        {waiting && <Loader className="overlay" />}
        <dl>
          <dt>Name</dt>
          <dd className="left-pad">
            <OrganizationNameField
              organizationsService={organizationsService}
              disabled={readonly}
              ref={this.nameField}
            />
          </dd>
          <dt>Number</dt>
          <dd className="left-pad">
            <OrganizationNumberField
              organizationsService={organizationsService}
              disabled={readonly}
              ref={this.numberField}
            />
          </dd>
          <dt>Brands</dt>
          <dd>
            <DynamicMultiCheckbox<Brand>
              load={this.props.brandsService.getBrands}
              selected={selectedBrands}
              disabled={selectedBrands}
              readonly={readonly}
              ref={this.brandsControl}
              onSelect={(selection) => this.onBrandSelect(selection)}
              onLoaded={(brands) => this.onBrandSelect(brands)}
              autoSelectSingle
            />
          </dd>
          {possibleCategories && possibleCategories.length > 0 && (
            <>
              <dt>Category</dt>
              <dd className="left-pad">
                <NamedSelect
                  items={possibleCategories}
                  ref={this.categoryControl}
                  autoSelectSingle
                />
              </dd>
            </>
          )}
          <dt>Markets</dt>
          <dd>
            <MarketSelect
              marketsService={this.props.marketsService}
              readonly={readonly}
              ref={this.marketsControl}
            />
          </dd>
        </dl>
        {!disableButtons && (
          <div className="buttons-area">
            <Button onClick={() => this.save()} disabled={readonly}>
              Confirm
            </Button>
          </div>
        )}
        {error && <ErrorPanel error={error} />}
      </div>
    );
  }
}
