import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {
  OrganizationsApi,
  OrganizationTableItem,
  UpdateOrganizationInput,
} from "../../../service/domain/organizations";
import {PaginatedSet} from "../../../service/domain/lists";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {BrandsApi} from "../../../service/domain/brands";
import {CommonFiltersState} from "../common/common-filters";
import CommonFiltersView from "../common/common-filters";
import Nullable from "../../common/nullable-value";
import {ApplicationError} from "../../../common/errors";
import EditOrganizationForm from "./organization-edit";
import EditOutlined from "@material-ui/icons/EditOutlined";

import {
  DynamicTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";
import {MarketsApi} from "../../../service/domain/markets";
import {
  loadData,
  showEditViewInDialog,
  dismissDialog,
} from "../common/view-functions";
import {User} from "../../../service/user";
import {AppRoles} from "../../../service/roles";
import formatDate from "../../../common/format-date";

function getProperties(): ItemProperty<OrganizationTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: OrganizationTableItem) => {
        return item.id;
      },
    },
    {
      id: "name",
      label: "Name",
      render: (item: OrganizationTableItem) => {
        return <Nullable value={item.name}>{item.name}</Nullable>;
      },
    },
    {
      id: "number",
      label: "Number",
      render: (item: OrganizationTableItem) => {
        return <Nullable value={item.number}>{item.number}</Nullable>;
      },
    },
    {
      id: "creationTime",
      label: "Creation time",
      render: (item: OrganizationTableItem) => {
        return formatDate(item.creationTime);
      },
    },
    {
      id: "updateTime",
      label: "Last update time",
      render: (item: OrganizationTableItem) => {
        return formatDate(item.updateTime);
      },
    },
  ];
}

export interface OrganizationsTableProps {
  user: User;
  organizationsService: OrganizationsApi;
  brandsService: BrandsApi;
  marketsService: MarketsApi;

  // enables a forced brand id: used for those contexts where the user
  // first selects a brand, then an organization
  brandId?: string;

  selectable?: true;
  selectOne?: true;
  noExtraButtons?: boolean;
  onItemClick?: (item: OrganizationTableItem) => void;
}

export interface OrganizationsTableState {
  loading: boolean;
  error?: ApplicationError;
  selectedItem?: OrganizationTableItem;
  filters: CommonFiltersState;
  confirm: ConfirmDialogProps;
  canDisplayTable: boolean;
}

export default class OrganizationsTable extends Component<
  OrganizationsTableProps,
  OrganizationsTableState
> {
  private table: React.RefObject<
    DynamicTable<OrganizationTableItem, CommonFiltersState>
  >;

  constructor(props: OrganizationsTableProps) {
    super(props);

    this.state = {
      loading: false,
      filters: {
        search: "",
        selectedBrandId: "",
        selectedOrganizationId: "",
      },
      confirm: closedDialog(),
      canDisplayTable: false,
    };
    this.table = React.createRef();
  }

  fetch(
    page: number,
    sortBy: string,
    filters: CommonFiltersState
  ): Promise<PaginatedSet<OrganizationTableItem>> {
    const {brandId} = this.props;

    return this.props.organizationsService.getOrganizations(
      page,
      sortBy,
      filters.search,
      brandId ? brandId : filters.selectedBrandId
    );
  }

  onFiltersChange(filters: CommonFiltersState): void {
    this.setState({
      filters,
    });
  }

  onItemClick(item: OrganizationTableItem): void {
    this.setState({
      selectedItem: item,
    });
    if (this.props.onItemClick) {
      this.props.onItemClick(item);
    }
  }

  async onEditClick(item: OrganizationTableItem): Promise<void> {
    // this is possible only when the brand service is configured
    const {brandsService, marketsService, organizationsService} = this.props;

    if (!brandsService || !marketsService) {
      return;
    }

    const details = await loadData(this, async () => {
      return organizationsService.getOrganizationById(item.id);
    });

    if (details === null) {
      // TODO: handle this unlikely scenario, show some information
      return;
    }

    showEditViewInDialog(
      this,
      `Edit organization "${item.name || item.number || item.id}".`,
      <EditOrganizationForm
        details={details}
        brandsService={brandsService}
        marketsService={marketsService}
        organizationsService={organizationsService}
        onUpdate={(u: UpdateOrganizationInput) =>
          this.handleOrganizationUpdate(item, u)
        }
        onCancel={() => dismissDialog(this)}
      />
    );
  }

  handleOrganizationUpdate(
    item: OrganizationTableItem,
    update: UpdateOrganizationInput
  ): void {
    // update only properties that are visible on the table
    item.name = update.name;
    item.number = update.number;

    dismissDialog(this);
  }

  onBrandsLoaded(): void {
    setTimeout(() => {
      this.setState({
        canDisplayTable: true,
      });
    }, 10);
  }

  render(): ReactElement {
    const {
      brandId,
      brandsService,
      marketsService,
      selectable,
      noExtraButtons,
    } = this.props;
    const {loading, confirm, filters, canDisplayTable} = this.state;
    const properties = getProperties();

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        <CommonFiltersView
          brandId={brandId}
          brandsService={brandsService}
          marketsService={marketsService}
          onChange={this.onFiltersChange.bind(this)}
          onBrandsLoaded={this.onBrandsLoaded.bind(this)}
        />
        {canDisplayTable && (
          <DynamicTable<OrganizationTableItem, CommonFiltersState>
            items={[]}
            defaultSortOrder="desc"
            defaultSortProperty="updateTime"
            properties={properties}
            filters={filters}
            provider={this.fetch.bind(this)}
            actions={this.getActions()}
            ref={this.table}
            onItemClick={this.onItemClick.bind(this)}
            selectOnClick={selectable}
            selectable={selectable}
            detailsRoute={noExtraButtons ? undefined : "/organizations/"}
          />
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<OrganizationTableItem>> {
    if (this.props.noExtraButtons) {
      return [];
    }
    const user = this.props.user;

    return [
      {
        title: "Edit",
        icon: <EditOutlined />,
        onClick: this.onEditClick.bind(this),
        disabled: (): boolean => {
          return !user.hasRole(AppRoles.OrganizationWrite);
        },
      },
    ];
  }
}
