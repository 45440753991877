import React, {Component, ReactElement} from "react";
import DynamicSelect from "../../common/forms/select-named-dynamic";
import {ApplicationsApi} from "../../../service/domain/applications";
import {TemplateCulture, EmailType} from "../../../service/domain/emails";
import {
  loadCulturesData,
  getDisplayName,
  filterCulturesByMarket,
} from "../../../common/cultures";
import {getMarketContext} from "../../../service/context";

export interface ApplicationEmailTemplateProps {
  service: ApplicationsApi;
  applicationId: string;
  onSelect: (item: TemplateCulture | null) => void;
  onLoaded?: (items: TemplateCulture[]) => void;
  initialValue?: string;
  brandId: string;
}

// eslint-disable-next-line max-len
export default class ApplicationEmailTemplateSelect extends Component<ApplicationEmailTemplateProps> {
  async load(): Promise<TemplateCulture[]> {
    const {service, applicationId, brandId} = this.props;

    let data = await service.getApplicationEmailTemplates(
      applicationId,
      EmailType.Invitation,
      brandId
    );

    const cultureData = await loadCulturesData();

    const market = getMarketContext();

    if (market) {
      // the user is operating in the context of a specific market
      data = filterCulturesByMarket(data, market.code, market.name);
    }

    const items = data.map((template) => {
      const code = template.cultureCode.toLowerCase();
      return {
        id: code,
        name:
          cultureData !== undefined ? getDisplayName(code, cultureData) : code,
        templateId: template.id,
      };
    });

    items.sort((a: TemplateCulture, b: TemplateCulture): number => {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
      return 0;
    });

    return items;
  }

  render(): ReactElement {
    return (
      <React.Fragment>
        <dt>Culture</dt>
        <dd className="select-wrapper">
          <DynamicSelect<TemplateCulture>
            by={this.props.applicationId}
            load={this.load.bind(this)}
            onSelect={this.props.onSelect}
            onLoaded={this.props.onLoaded}
            initialValue={this.props.initialValue}
          >
            {this.props.children}
          </DynamicSelect>
        </dd>
      </React.Fragment>
    );
  }
}
