import debounce from "lodash/debounce";
import * as files from "../../../common/files";
import Alert, {AlertProps, AlertSeverity} from "../../common/alert";
import clone from "lodash/clone";
import FileExamples, {FileExample} from "../../common/forms/file-examples";
import FileInfo from "../../common/forms/file-info";
import FileInput from "../../common/forms/file-input";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import uniqueId from "lodash/uniqueId";
import {ApplicationError, InterfaceError} from "../../../common/errors";
import {
  ApplicationDetails,
  ApplicationTableItem,
} from "../../../service/domain/applications";
import {Brand} from "../../../service/domain/brands";
import {DialogSize} from "../../common/dialogs/size";
import {EmailType} from "../../../service/domain/emails";
import {
  BulkInvitationEntry,
  BulkInvitationsOutput,
} from "../../../service/domain/invitations";
import {
  OrganizationTableItem,
  EmptyItemsOrganizationsResponseError,
} from "../../../service/domain/organizations";
import {ParseError, UnsupportedFileType} from "../../../common/errors";
import {TextField} from "@material-ui/core";
import {validateEmail} from "../../../common/emails";
import {
  StaticTable,
  ItemProperty,
  TableButton,
  ButtonColor,
} from "../../common/tables/tables";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import InvitationSettingsView, {
  InvitationSettings,
} from "./invitation-settings";
import {parseSync as parseCSV} from "../../../common/csv";
import {ciEquals} from "../../../common/strings";
import {getValidationClassName} from "../../../common/forms";
import {PaginatedSet} from "../../../service/domain/lists";
import ErrorPanel from "../../common/error";
import {IServices} from "../../../service/services";
import {HCPRole} from "../../../service/domain/employees";
import {dismissDialog} from "../common/view-functions";
import {defer} from "lodash";
import {
  getCulturesDataSync,
  getDisplayName,
  CultureInfo,
  filterCulturesByMarket,
} from "../../../common/cultures";
import {getMarketContext} from "../../../service/context";

export interface BulkInvitationsProps {
  services: IServices;
}

const MaxBatchSize = 300;

enum BulkInvitationEntryStatus {
  valid = "valid",
  invalid = "invalid",
  success = "success",
  failure = "failure",
}

const Examples: FileExample[] = [
  {
    id: "csv-full",
    name: "CSV, many properties",
    href: "/bulk-invitations-examples/example.csv",
  },
  {
    id: "csv-emails-culture",
    name: "CSV, only emails and cultures",
    href: "/bulk-invitations-examples/example-emails-culture.csv",
  },
  {
    id: "plain-only-emails",
    name: "Plain text, emails only",
    href: "/bulk-invitations-examples/example.txt",
  },
  {
    id: "json-full",
    name: "JSON, many properties",
    href: "/bulk-invitations-examples/example.json",
  },
];

export interface BulkInvitationUIEntry {
  id: string;
  applicationId: string;
  applicationName: string;
  applicationError: boolean;
  applicationHelperText: string;
  organizationId: string;
  organizationName: string;
  organizationNumber: string;
  email: string;
  emailError: boolean;
  emailHelperText: string;
  brandError: boolean;
  brandHelperText: string;
  organizationError: boolean;
  organizationHelperText: string;
  cultureCode: string;
  cultureCodeError: boolean;
  cultureCodeHelperText: string;
  brandId: string;
  brandName?: string;
  redirectUrl: string;
  status: BulkInvitationEntryStatus;
  validating: boolean;
}

function uiEntryToDomainEntry(
  item: BulkInvitationUIEntry
): BulkInvitationEntry {
  return {
    entryId: item.id,
    applicationId: item.applicationId,
    brandId: item.brandId,
    organizationId: item.organizationId,
    emailAddress: item.email,
    cultureCode: item.cultureCode,
  };
}

export interface BulkInvitationsState {
  loading: boolean;
  waiting: boolean;
  error?: ApplicationError;
  output?: BulkInvitationsOutput;

  selectedFile: File | null;
  fileProblem?: AlertProps;
  entries: BulkInvitationUIEntry[];

  confirm: ConfirmDialogProps;
  allValid: boolean;

  maxSizeWarning?: AlertProps;
}

interface EmailFieldProps {
  item: BulkInvitationUIEntry;
  onBlur: () => void;
  disabled?: boolean;
}

interface EmailFieldState {
  value: string;
}

class EmailInput extends Component<EmailFieldProps, EmailFieldState> {
  constructor(props: EmailFieldProps) {
    super(props);
    this.state = {value: props.item.email};
  }

  onChange(value: string): void {
    value = value.trim().toLowerCase();
    this.props.item.email = value;
    this.setState({value});
  }

  render(): ReactElement {
    const {disabled, item} = this.props;
    const {value} = this.state;

    // This component enables fast editing of a single field when
    // the table contains many elements.
    // For simplicity, the information whether the email is invalid
    // comes from the parent.
    return (
      <TextField
        error={item.emailError}
        helperText={item.emailHelperText}
        name="value"
        value={value}
        required
        fullWidth
        autoComplete="off"
        disabled={disabled}
        onChange={(event) => this.onChange(event.target.value)}
        onBlur={() => this.props.onBlur()}
      />
    );
  }
}

function getProperties(
  table: BulkInvitations,
  cultures: {[key: string]: CultureInfo} | undefined
): ItemProperty<BulkInvitationUIEntry>[] {
  const properties: ItemProperty<BulkInvitationUIEntry>[] = [
    {
      id: "id",
      label: "Id",
      hidden: true,
      render: (item: BulkInvitationUIEntry) => {
        return item.id;
      },
    },
    {
      id: "email",
      label: "Email",
      render: (item: BulkInvitationUIEntry) => {
        return (
          <EmailInput
            item={item}
            disabled={table.disabled || table.state.waiting}
            onBlur={() => table.onEmailBlur()}
          />
        );
      },
    },
    {
      id: "culture",
      label: "Culture",
      render: (item: BulkInvitationUIEntry) => {
        return (
          <TextField
            error={item.cultureCodeError}
            helperText={item.cultureCodeHelperText}
            value={getDisplayName(item.cultureCode, cultures)}
            disabled
            fullWidth
            autoComplete="off"
            onClick={() => {
              if (item.cultureCodeError) {
                table.onConfigureClick([item]);
              }
            }}
            className={getValidationClassName(!item.cultureCodeError)}
          />
        );
      },
    },
    {
      id: "organizationName",
      label: "Organization name",
      render: (item: BulkInvitationUIEntry) => {
        return (
          <TextField
            error={item.organizationError}
            helperText={item.organizationHelperText}
            value={item.organizationName}
            disabled
            fullWidth
            autoComplete="off"
            onClick={() => {
              if (item.organizationError) {
                table.onConfigureClick([item]);
              }
            }}
            className={getValidationClassName(!item.organizationError)}
          />
        );
      },
    },
    {
      id: "organizationNumber",
      label: "Organization number",
      render: (item: BulkInvitationUIEntry) => {
        return (
          <TextField
            error={item.organizationError}
            helperText={item.organizationHelperText}
            value={item.organizationNumber}
            disabled
            fullWidth
            autoComplete="off"
            onClick={() => {
              if (item.organizationError) {
                table.onConfigureClick([item]);
              }
            }}
            className={getValidationClassName(!item.organizationError)}
          />
        );
      },
    },
    {
      id: "brandName",
      label: "Brand",
      render: (item: BulkInvitationUIEntry) => {
        return (
          <TextField
            error={item.brandError}
            helperText={item.brandHelperText}
            value={item.brandName}
            disabled
            fullWidth
            autoComplete="off"
            onClick={() => {
              if (item.brandError) {
                table.onConfigureClick([item]);
              }
            }}
            className={getValidationClassName(!item.brandError)}
          />
        );
      },
    },
    {
      id: "application",
      label: "Application",
      render: (item: BulkInvitationUIEntry) => {
        return (
          <TextField
            error={item.applicationError}
            helperText={item.applicationHelperText}
            name="brand"
            value={item.applicationName}
            disabled
            fullWidth
            autoComplete="off"
            className={getValidationClassName(!item.applicationError)}
          />
        );
      },
    },
    {
      id: "status",
      label: "Valid",
      render: (item: BulkInvitationUIEntry) => {
        if (item.validating) {
          return <Loader className="mini" />;
        }

        if (item.status === BulkInvitationEntryStatus.valid) {
          return <i className="fa fa-check-circle ok" aria-hidden="true"></i>;
        }

        if (item.status === BulkInvitationEntryStatus.failure) {
          return <i className="fa fa-ban ko" aria-hidden="true"></i>;
        }

        if (item.status === BulkInvitationEntryStatus.success) {
          return <i className="fa fa-telegram super" aria-hidden="true"></i>;
        }

        return (
          <span title={table.getItemErrorsText(item)}>
            <i className="fa fa-ban ko" aria-hidden="true"></i>
          </span>
        );
      },
    },
  ];

  for (const prop of properties) {
    prop.notSortable = true;
  }

  return properties;
}

function uniqueEntryId(): string {
  return uniqueId("entry-");
}

export default class BulkInvitations extends Component<
  BulkInvitationsProps,
  BulkInvitationsState
> {
  private _brands: Brand[];
  private fileInput: React.RefObject<FileInput>;

  constructor(props: BulkInvitationsProps) {
    super(props);

    this.state = this.initialState();
    this.fileInput = React.createRef();

    // prefetch brands in background
    this._brands = [];
    this.fetchBrands();
    (window as any).control = this;
  }

  dismissDialog = (): void => dismissDialog(this);

  reset(): void {
    this.setState(this.initialState());
    this.fileInput.current?.clearSelection();
  }

  public get disabled(): boolean {
    return !!this.state.output;
  }

  initialState(): BulkInvitationsState {
    return {
      waiting: false,
      loading: false,
      selectedFile: null,
      entries: [],
      confirm: closedDialog(),
      allValid: false,
      output: undefined,
    };
  }

  private async fetchBrands(): Promise<void> {
    try {
      this._brands = await this.props.services.brands.getBrands();
    } catch (error) {
      if (error instanceof ApplicationError && !error.data) {
        error.data = "An error occurred while fetching brands data.";
      }

      this.setState({
        error,
      });
    }
  }

  private toEntries(array: Array<any>): BulkInvitationUIEntry[] {
    return array.map(this.toEntry);
  }

  private toEntry(item: any): BulkInvitationUIEntry {
    return {
      id: uniqueEntryId(),
      applicationId: item.applicationId || "",
      applicationName: item.applicationName || "",
      applicationError: false,
      applicationHelperText: "",
      organizationId: item.organizationId || "",
      organizationName: item.organizationName || "",
      organizationNumber: item.organizationNumber || "",
      organizationError: false,
      organizationHelperText: "",
      email: item.email || item.emailAddress || "",
      emailError: false,
      emailHelperText: "",
      brandError: false,
      brandHelperText: "",
      cultureCode: item.culture || item.cultureCode || "",
      cultureCodeError: false,
      cultureCodeHelperText: "",
      brandId: item.brandId || "",
      brandName: item.brand || item.brandName || "",
      redirectUrl: "",
      status: BulkInvitationEntryStatus.invalid,
      validating: false,
    };
  }

  private getBrandByName(brands: Brand[], name: string): Brand | undefined {
    return brands.find((item) => ciEquals(item.name, name));
  }

  /**
   * Validates the brand, and applies automatic fix if there either brand id
   * or brand name are correct. This is to support source files having either
   * brand id or name correct.
   */
  private async validateBrand(entry: BulkInvitationUIEntry): Promise<void> {
    if (!this._brands) {
      await this.fetchBrands();
    }

    let brand: Brand | undefined;
    const brandId = entry.brandId;
    const brandName = entry.brandName;

    if (brandId) {
      brand = this._brands.find((item) => item.id === brandId);

      if (brand) {
        // all fine. but make sure that the brand displayed on page is correct
        if (brand.name !== brandName) {
          entry.brandName = brand.name;
        }
      } else if (brandName) {
        // brand not found by id: try to get one by matching name
        brand = this.getBrandByName(this._brands, brandName);

        if (brand) {
          entry.brandId = brand.id;
        }
      }
    } else if (brandName) {
      // This will be the most common scenario for file imports,
      // because the users are likely to import files containing brand names
      // and not brand ids (however this code handles all scenarios)
      brand = this.getBrandByName(this._brands, brandName);

      if (brand) {
        entry.brandId = brand.id;
      }
    }

    if (brand) {
      entry.brandError = false;
      entry.brandHelperText = "";
    } else {
      entry.brandError = true;
      entry.brandHelperText =
        brandId || brandName ? "Brand not found" : "Please select a brand";
    }
  }

  private _copyOrgData(
    organization: OrganizationTableItem,
    entry: BulkInvitationUIEntry
  ) {
    entry.organizationId = organization.id;
    entry.organizationName = organization.name || "";
    entry.organizationNumber = organization.number || "";
    entry.organizationError = false;
    entry.organizationHelperText = "";
  }

  private validateOrganizationByResults(
    organizations: PaginatedSet<OrganizationTableItem>,
    entry: BulkInvitationUIEntry,
    altCheck?: (item: OrganizationTableItem) => boolean
  ): void {
    const total = organizations.total;
    let organization: OrganizationTableItem | undefined;

    if (total === 1) {
      // fine
      organization = organizations.items[0];

      if (!organization) {
        // server side error
        throw new EmptyItemsOrganizationsResponseError();
      }

      this._copyOrgData(organization, entry);
    } else if (total === 0) {
      // no organization found
      entry.organizationError = true;
      entry.organizationHelperText = "Organization not found";
    } else if (total > 1) {
      // not fine: there is ambiguity;

      if (altCheck) {
        // try to detect the right organization by another criteria
        organization = organizations.items.find(altCheck);
      }

      if (organization) {
        this._copyOrgData(organization, entry);
      } else {
        entry.organizationError = true;
        entry.organizationHelperText =
          "There is ambiguity: please select an exact organization";
      }
    }
  }

  private async validateOrganization(
    entry: BulkInvitationUIEntry
  ): Promise<void> {
    let organization: OrganizationTableItem | null;
    let organizations: PaginatedSet<OrganizationTableItem> | undefined;
    const organizationId = entry.organizationId;
    const organizationName = entry.organizationName;
    const organizationNumber = entry.organizationNumber;
    const service = this.props.services.organizations;

    entry.organizationError = false;
    entry.organizationHelperText = "";

    if (organizationId) {
      // fetch organization by id.
      organization = await service.getOrganizationById(organizationId);

      if (organization) {
        // all fine. but make sure that other values are right
        if (organization.name !== organizationName) {
          entry.organizationName = organization.name || "";
        }
        if (organization.number !== organizationNumber) {
          entry.organizationNumber = organization.number || "";
        }
      } else {
        // wrong ID: since id is not displayed, and we have number or name
        // alternative, remove it
        if (organizationNumber || organizationName) {
          entry.organizationId = "";
          return await this.validateOrganization(entry);
        }

        entry.organizationError = true;
        entry.organizationHelperText = "Invalid organization id";
      }
    } else if (organizationNumber) {
      // fetch organization by number: if many are returned, then there is
      // ambiguity and the user must select an exact organization
      organizations = await service.getOrganizations(
        1,
        "",
        "",
        "",
        "",
        organizationNumber
      );

      this.validateOrganizationByResults(organizations, entry, (item) => {
        return item.name === organizationName;
      });
    } else if (organizationName) {
      // fetch organization by name: if many are returned, then there is
      // ambiguity and the user must select an exact organization
      organizations = await service.getOrganizations(
        1,
        "",
        "",
        "",
        organizationName
      );
      this.validateOrganizationByResults(organizations, entry);
    } else {
      // no parameter selected
      entry.organizationError = true;
      entry.organizationHelperText = "Please select an organization";
    }
  }

  private async validateApplication(
    entry: BulkInvitationUIEntry
  ): Promise<void> {
    let application: ApplicationDetails | null;
    let applications: ApplicationTableItem[] | undefined;
    const applicationId = entry.applicationId;
    const applicationName = entry.applicationName;
    const service = this.props.services.applications;

    entry.applicationError = false;
    entry.applicationHelperText = "";

    if (applicationId) {
      application = await service.getApplicationDetails(applicationId);

      if (application === null) {
        // not found

        // wrong ID: since id is not displayed, try by name
        if (applicationName) {
          entry.applicationId = "";
          return await this.validateApplication(entry);
        }

        entry.applicationError = true;
        entry.applicationHelperText = "Application not found";
      }
    } else if (applicationName) {
      applications = await service.getApplications("", "", applicationName);
      this.validateApplicationByResults(applications, entry);
    } else {
      // no parameter selected
      entry.applicationError = true;
      entry.applicationHelperText = "Please select an application";
    }
  }

  private async validateApplicationBrandCombo(
    entry: BulkInvitationUIEntry
  ): Promise<void> {
    const applicationId = entry.applicationId;
    const brandId = entry.brandId;
    const service = this.props.services.applications;

    if (!applicationId || !brandId) {
      throw new InterfaceError("Expected applicationId and brandId.");
    }

    // no worries about repeating calls, since we are using short lived
    // caching
    const application = await service.getApplicationDetails(applicationId);

    if (application === null) {
      // this should never happen, because we just validated the application,
      // and we are using caching.
      entry.applicationError = true;
      entry.applicationHelperText = "Application not found";
      return;
    }

    const matchingBrand = application.brands.find(
      (item) => item.id === brandId
    );

    if (!matchingBrand) {
      // application / brand mismatch
      entry.applicationError = true;
      entry.applicationHelperText = `The application ${application.name} is
        not associated with the selected brand.`;
      return;
    }
  }

  private async validateOrganizationBrandCombo(
    entry: BulkInvitationUIEntry
  ): Promise<void> {
    const brandId = entry.brandId;
    const organizationId = entry.organizationId;
    const service = this.props.services.organizations;

    if (!brandId || !organizationId) {
      throw new InterfaceError("Expected organizationId and brandId.");
    }

    // no worries about repeating calls, since we are using short lived
    // caching
    const organization = await service.getOrganizationById(organizationId);

    if (organization === null) {
      // this should never happen, because we just validated the application,
      // and we are using caching.
      entry.organizationError = true;
      entry.organizationHelperText = "Application not found";
      return;
    }

    const matchingBrand = organization.brands.find(
      (item) => item.id === brandId
    );

    if (!matchingBrand) {
      // application / brand mismatch
      entry.organizationError = true;
      entry.organizationHelperText = `The organization ${organization.name} is
        not associated with the selected brand.`;
      return;
    }
  }

  validateApplicationByResults(
    applications: ApplicationTableItem[],
    entry: BulkInvitationUIEntry
  ): void {
    const applicationId = entry.applicationId;
    const applicationName = entry.applicationName;

    const matchingApps = applications.filter(
      (item) =>
        ciEquals(applicationId, item.id) ||
        ciEquals(applicationName, item.name)
    );

    if (matchingApps.length === 1) {
      // fine
      const matchingApp = matchingApps[0];
      entry.applicationId = matchingApp.id;
      entry.applicationName = matchingApp.name;
    } else if (matchingApps.length === 0) {
      entry.applicationError = true;
      entry.applicationHelperText = "Application not found";
    } else if (matchingApps.length > 1) {
      // not find, ambiguity (by name)
      entry.applicationError = true;
      entry.applicationHelperText =
        "There is ambiguity: please select an exact application";
    }
  }

  private async validateNotAdmin(entry: BulkInvitationUIEntry): Promise<void> {
    const email = entry.email;
    const organizationId = entry.organizationId;
    const service = this.props.services.employees;

    if (!email || !organizationId) {
      throw new InterfaceError("Expected organizationId and brandId.");
    }

    const admins = await service.getEmployees(
      1,
      "",
      email,
      "",
      organizationId,
      HCPRole.administrator
    );

    const existingAdmin = admins.items.find((item) =>
      ciEquals(item.email, email)
    );
    if (existingAdmin) {
      entry.emailError = true;
      entry.emailHelperText =
        "The recipient is already an administrator " +
        "of the selected organization.";
    }
  }

  private async validateCulture(entry: BulkInvitationUIEntry): Promise<void> {
    // need to ensure that a template in the desired culture exists
    const service = this.props.services.applications;
    const {applicationId, brandId, cultureCode} = entry;

    entry.cultureCodeError = false;
    entry.cultureCodeHelperText = "";

    if (cultureCode) {
      // validate
      if (applicationId && brandId) {
        const market = getMarketContext();
        let templates = await service.getApplicationEmailTemplates(
          applicationId,
          EmailType.Invitation,
          brandId
        );

        if (market) {
          // the user is operating in the context of a specific market
          templates = filterCulturesByMarket(
            templates,
            market.code,
            market.name
          );
        }

        const matchingTemplate = templates.find((item) =>
          ciEquals(item.cultureCode, cultureCode)
        );

        if (!matchingTemplate) {
          entry.cultureCodeError = true;
          entry.cultureCodeHelperText =
            `There are no emails configured in "${cultureCode}" ` +
            "for the selected application and brand";
        }
      }
    } else {
      entry.cultureCodeError = true;
      entry.cultureCodeHelperText = "Please select a language";
    }
  }

  private parseAsJson(text: string): any[] {
    try {
      const data = JSON.parse(text);

      if (data instanceof Array) {
        // TODO: ensure that items are assigned with an id
        return data;
      }

      if (data.entries) {
        return data.entries;
      }
    } catch (error) {
      throw new ParseError(`${error}`);
    }

    return [];
  }

  private parseAsCSV(text: string): any[] {
    try {
      return parseCSV(text);
    } catch (error) {
      throw new ParseError(`${error}`);
    }
  }

  private parsePlainText(text: string): any[] {
    try {
      const lines = text.split(/\n/g).filter((value) => !!value);

      return lines.map((line) => {
        return {
          emailAddress: line,
        };
      });
    } catch (error) {
      throw new ParseError(`${error}`);
    }
  }

  async parseFile(file: File): Promise<BulkInvitationUIEntry[]> {
    const type = file.type;
    let contents: string;

    // in Windows, csv files have mime ms-excel :D
    if (type.indexOf("ms-excel") > -1) {
      if (file.name.endsWith(".csv")) {
        contents = await files.readFileAsText(file);
        return this.toEntries(this.parseAsCSV(contents));
      }
    }

    switch (type) {
      case "application/json":
        contents = await files.readFileAsText(file);
        return this.toEntries(this.parseAsJson(contents));
      case "text/csv":
        contents = await files.readFileAsText(file);
        return this.toEntries(this.parseAsCSV(contents));
      case "text/plain":
        // read one email address by line
        contents = await files.readFileAsText(file);
        return this.toEntries(this.parsePlainText(contents));
      default:
        throw new UnsupportedFileType(type);
    }
  }

  async onFileSelect(file: File | null): Promise<void> {
    this.setState({
      selectedFile: file,
    });

    if (file === null) {
      this.setState({
        fileProblem: undefined,
        entries: [],
      });
      return;
    }

    let entries: BulkInvitationUIEntry[];

    try {
      entries = await this.parseFile(file);
    } catch (error) {
      if (error instanceof UnsupportedFileType) {
        this.setState({
          fileProblem: {
            title: "Unsupported file type",
            message:
              "The selected file is not supported. " +
              "Please select a JSON or CSV file.",
            severity: AlertSeverity.info,
          },
          entries: [],
        });
        return;
      }

      if (error instanceof ParseError) {
        this.setState({
          fileProblem: {
            title: "Cannot parse the selected file",
            message: `The selected file could not be parsed. Error: ${error}`,
            severity: AlertSeverity.info,
          },
          entries: [],
        });
        return;
      }

      throw error;
    }

    this.validateItemsDebounced();

    this.setState({
      entries,
      fileProblem: undefined,
    });
  }

  getTableButtons(): Array<TableButton<BulkInvitationUIEntry>> {
    const addButton: TableButton<BulkInvitationUIEntry> = {
      label: "Add",
      onClick: this.onAddClick.bind(this),
      disabled: this.disabled,
    };

    if (!this.state.entries.length) {
      return [addButton];
    }

    const buttons = [
      addButton,
      {
        label: "Remove selected",
        onClick: this.onRemoveClick.bind(this),
        disabled: this.disabled,
      },
      {
        label: "Validate",
        onClick: this.onValidateClick.bind(this),
        disabled: this.disabled,
      },
      {
        label: "Configure",
        onClick: this.onConfigureClick.bind(this),
        disabled: this.disabled,
      },
      {
        label: "Confirm",
        onClick: this.onConfirmClick.bind(this),
        disabled: !this.state.allValid || this.disabled,
        color: ButtonColor.secondary,
      },
    ];

    if (this.disabled) {
      buttons.push({
        label: "Reset",
        onClick: () => this.reset(),
        color: ButtonColor.secondary,
      });
    }

    return buttons;
  }

  onAddClick(): void {
    const entries = this.state.entries;

    // NB: when adding a new entry, copy the last one in the table
    // except the email address
    const lastItem = entries[entries.length - 1];
    if (lastItem) {
      const clonedItem = clone(lastItem);
      clonedItem.id = uniqueEntryId();
      clonedItem.email = "";
      clonedItem.status = BulkInvitationEntryStatus.invalid;
      entries.push(clonedItem);
    } else {
      entries.push(this.toEntry({}));
    }

    this.setState({
      entries,
      allValid: false, // because the new item does not have an email address
    });

    defer(() => {
      this.validateBatchSize();
    });
  }

  onRemoveClick(items: BulkInvitationUIEntry[]): void {
    // removes selected items, only selected items to avoid mistakes
    if (items.length === 0) {
      return;
    }

    const remaining = this.state.entries.filter((item) => {
      return items.indexOf(item) === -1;
    });
    const allValid = remaining.every(
      (item) => this.itemHasErrors(item) === false
    );

    this.setState({
      entries: remaining,
      allValid,
    });

    if (!remaining.length) {
      // the user removed all items from the source file,
      // so clear the selected file
      this.fileInput.current?.clearSelection();
    }

    defer(() => {
      this.validateBatchSize();
    });
  }

  onValidateClick(): void {
    this.validateItems();
  }

  /**
   * Validates items that are in the form.
   */
  async validateItems(entries?: BulkInvitationUIEntry[]): Promise<boolean> {
    this.setState({
      allValid: false,
    });
    if (!entries) {
      entries = this.state.entries;
    }
    for (const entry of entries) {
      entry.validating = true;
    }
    let hasErrors = false;

    const results = await Promise.all(
      entries.map((item) => this.validateItem(item))
    );

    hasErrors = results.some((item) => !item);

    if (!this.validateBatchSize(entries)) {
      hasErrors = true;
    }

    this.refresh();
    this.setState({
      allValid: !hasErrors,
    });
    return !hasErrors;
  }

  validateBatchSize(entries?: BulkInvitationUIEntry[]): boolean {
    if (!entries) {
      entries = this.state.entries;
    }

    if (entries.length > MaxBatchSize) {
      this.setState({
        maxSizeWarning: {
          title: "Feature not supported",
          message:
            `It is not supported to send more than ${MaxBatchSize} ` +
            "invitations in a single batch. " +
            "For more information, contact `cloudheroes@demant.com`.",
          severity: AlertSeverity.warning,
        },
      });
      return false;
    }
    this.setState({
      maxSizeWarning: undefined,
    });
    return true;
  }

  validateEmail(entry: BulkInvitationUIEntry): void {
    if (!validateEmail(entry.email)) {
      entry.emailError = true;
      entry.emailHelperText = "Please insert a valid email address";
    } else {
      entry.emailError = false;
      entry.emailHelperText = "";
    }
  }

  itemIsValid(item: BulkInvitationUIEntry): boolean {
    return !this.itemHasErrors(item);
  }

  itemHasErrors(item: BulkInvitationUIEntry): boolean {
    return (
      item.emailError ||
      item.organizationError ||
      item.applicationError ||
      item.cultureCodeError ||
      item.brandError
    );
  }

  async validateItem(entry: BulkInvitationUIEntry): Promise<boolean> {
    this.validateEmail(entry);

    await Promise.all([
      this.validateBrand(entry),
      this.validateOrganization(entry),
      this.validateApplication(entry),
    ]);

    if (
      !(
        entry.emailError ||
        entry.brandError ||
        entry.applicationError ||
        entry.organizationError
      )
    ) {
      // validate application - brand pair,
      // organization - brand pair,
      // and application - culture code
      await Promise.all([
        this.validateNotAdmin(entry),
        this.validateCulture(entry),
        this.validateApplicationBrandCombo(entry),
        this.validateOrganizationBrandCombo(entry),
      ]);

      // validate that the item is not a duplicate of another
      if (this.itemIsValid(entry)) {
        const duplicate = this.state.entries.find(
          (item) =>
            item !== entry &&
            this.itemIsValid(item) &&
            ciEquals(entry.email, item.email) &&
            item.organizationId === entry.organizationId &&
            item.brandId === entry.brandId &&
            item.applicationId === entry.applicationId &&
            item.cultureCode === entry.cultureCode
        );

        if (duplicate) {
          // use email error to display the error on the left
          entry.emailError = true;
          entry.emailHelperText = "This item is a duplicate of another one";
        }
      }
    }
    const hasErrors = this.itemHasErrors(entry);

    entry.status = hasErrors
      ? BulkInvitationEntryStatus.invalid
      : BulkInvitationEntryStatus.valid;

    entry.validating = false;
    return !hasErrors;
  }

  localizeAllSomeOne(
    length: number,
    allText: string,
    someText: string,
    oneText: string
  ): string {
    if (length === 0 || length === this.state.entries.length) {
      if (length === 1) {
        return oneText;
      }
      return allText;
    }

    if (length === 1) {
      return oneText;
    }

    return someText;
  }

  refresh = debounce(
    () => {
      this.refreshNoDelay();
    },
    100,
    {leading: false, trailing: true}
  );

  validateItemsDebounced = debounce(
    () => {
      this.validateItems();
    },
    100,
    {leading: false, trailing: true}
  );

  refreshNoDelay(): void {
    this.setState({
      entries: [...this.state.entries],
    });
  }

  onEmailBlur(): void {
    // Note: all items are validated because when an email is updated,
    // the table might contain duplicates
    this.validateItemsDebounced();
  }

  getCommonValue(
    items: BulkInvitationUIEntry[],
    property: keyof BulkInvitationUIEntry
  ): string | boolean | null {
    let value: string | boolean | undefined;

    for (const item of items) {
      if (value === undefined) {
        value = item[property];
      } else {
        if (value !== item[property]) {
          return null;
        }
      }
    }
    return value || null;
  }

  async onConfirmClick(): Promise<void> {
    if (!this.state.allValid) {
      return;
    }

    this.setState({
      waiting: true,
    });

    const service = this.props.services.invitations;
    const entries = this.state.entries.map(uiEntryToDomainEntry);

    try {
      const result = await service.submitBulkInvitations({
        entries,
      });
      this.handleResultEntries(result);
      this.setState({
        waiting: false,
        output: result,
      });
    } catch (error) {
      this.setState({
        waiting: false,
        error,
      });
    }
  }

  private handleResultEntries(output: BulkInvitationsOutput): void {
    const entries = this.state.entries;
    const results = output.results;

    for (const entry of results) {
      const originalItem = entries.find((item) => item.id === entry.entryId);

      if (!originalItem) {
        // should never happen
        continue;
      }

      if (entry.errorMessage) {
        // hej, we have to display the error somewhere!
        originalItem.emailError = true;
        originalItem.emailHelperText = entry.errorMessage;
        originalItem.status = BulkInvitationEntryStatus.failure;
      } else {
        // because retry is supported
        originalItem.emailError = false;
        originalItem.emailHelperText = "";
        originalItem.status = BulkInvitationEntryStatus.success;
      }
    }
  }

  onConfigureClick(items: BulkInvitationUIEntry[]): void {
    const {services} = this.props;

    const title = this.localizeAllSomeOne(
      items.length,
      "Configure all items",
      "Configure the selected items",
      "Configure the selected item"
    );

    if (items.length === 0) {
      items = this.state.entries;
    }
    let currentSettings: InvitationSettings | undefined;

    // when all items have the same settings, prepopulate the
    // invitation settings view
    const commonBrandId = this.getCommonValue(items, "brandId") as
      | string
      | null;
    const commonOrganizationId = this.getCommonValue(
      items,
      "organizationId"
    ) as string | null;
    const commonApplicationId = this.getCommonValue(items, "applicationId") as
      | string
      | null;

    this.setState({
      confirm: {
        open: true,
        title,
        description: "",
        fragment: (
          <InvitationSettingsView
            services={services}
            selectedBrandId={commonBrandId}
            selectedApplicationId={commonApplicationId}
            selectedOrganizationId={commonOrganizationId}
            onChange={(settings) => {
              currentSettings = settings;
              return;
            }}
          />
        ),
        close: () => {
          this.dismissDialog();
        },
        confirm: () => {
          if (currentSettings === undefined) {
            return this.dismissDialog();
          }
          for (const entry of items) {
            entry.organizationId = currentSettings.organization?.id || "";
            entry.organizationName = currentSettings.organization?.name || "";
            entry.organizationNumber =
              currentSettings.organization?.number || "";
            entry.brandId = currentSettings.brand?.id || "";
            entry.brandName = currentSettings.brand?.name || "";
            entry.cultureCode = currentSettings.cultureCode || "";
            entry.applicationId = currentSettings.application?.id || "";
            entry.applicationName = currentSettings.application?.name || "";
          }

          this.validateItems(items);

          return this.dismissDialog();
        },
        size: DialogSize.medium,
      },
    });
  }

  getItemErrorsText(entry: BulkInvitationUIEntry): string {
    return [
      entry.emailHelperText,
      entry.cultureCodeHelperText,
      entry.brandHelperText,
      entry.organizationHelperText,
      entry.applicationHelperText,
    ].join("\r\n");
  }

  render(): ReactElement {
    const {
      error,
      waiting,
      selectedFile,
      fileProblem,
      entries,
      confirm,
      maxSizeWarning,
      output,
    } = this.state;
    const disabled = this.disabled;
    const cultures = getCulturesDataSync();

    return (
      <div id="bulk-invitations-region">
        {waiting && <Loader className="overlay" />}
        <dl>
          <dt>Source</dt>
          <dd>
            <FileInput
              name="file-source"
              accept="text/csv; application/json; text/plain"
              onSelect={this.onFileSelect.bind(this)}
              disabled={disabled}
              ref={this.fileInput}
            />
            {selectedFile && (
              <FileInfo
                name={selectedFile.name}
                size={selectedFile.size}
                type={selectedFile.type}
                lastModified={selectedFile.lastModified}
              />
            )}
          </dd>
          <dt>Download a template</dt>
          <dd>
            <FileExamples items={Examples} />
          </dd>
        </dl>
        {fileProblem && <Alert {...fileProblem} />}
        <React.Fragment>
          <StaticTable<BulkInvitationUIEntry>
            properties={getProperties(this, cultures)}
            defaultSortOrder="asc"
            defaultSortProperty="email"
            items={entries}
            buttons={this.getTableButtons()}
            selectable={!disabled}
          />
          {maxSizeWarning && <Alert {...maxSizeWarning} />}
          {error && <ErrorPanel error={error} />}
        </React.Fragment>
        <ConfirmDialog {...confirm} />
        {output && this.renderOutput(output)}
      </div>
    );
  }

  renderOutput(output: BulkInvitationsOutput): ReactElement | null {
    let info: AlertProps;
    const results = output.results;

    if (!results || !results.length) {
      return null;
    }

    if (results.every((item) => !!item.errorMessage)) {
      // full failure
      info = {
        title: "Failure",
        message: "All invitations failed.",
        severity: AlertSeverity.error,
      };
    } else if (results.every((item) => !item.errorMessage)) {
      // full success
      info = {
        title: "Success!",
        message: "All invitations were sent properly.",
        severity: AlertSeverity.success,
      };
    } else {
      // partial failure
      info = {
        title: "Partial failure",
        message: "One or more invitations failed.",
        severity: AlertSeverity.warning,
      };
    }

    info.dismiss = () => {
      this.reset();
    };

    return <Alert {...info} />;
  }
}
