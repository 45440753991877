import React, {ReactElement} from "react";
import Head from "../common/head";

export default function About(): ReactElement {
  const videoTutorials = [
    {
      title: "New HCP Admin invitation form.",
      href: "https://web.microsoftstream.com/video/0f9557f1-bd6c-4621-881e-09ba0e7fe447?channelId=7723e109-e4f6-4959-8abb-69ac0c77444b",
    },
    {
      title: "Bulk HCP Admin invitations form.",
      href: "https://web.microsoftstream.com/video/c8a3082f-0094-47ce-8abb-aac6bba517b2?channelId=7723e109-e4f6-4959-8abb-69ac0c77444b",
    },
  ];

  const userGuideUrl =
    "https://demant.sharepoint.com/:b:/s/TeamHCPAdminPortal/EZopaLhQ6F1PkI81dogWemUBUd3A_-VINw21n0-bHbfZJg?e=dE2Ufh";

  return (
    <div className="wrapper">
      <Head title="Home" />
      <h1>Welcome to the Demant Admin Tool</h1>
      <section>
        <p>
          This portal was created to enable a number of administrative tasks
          related to Demant HCPs, HCP Admins, organizations, and invitations.
          For example, to inspect the list of HCP Admins, organizations,
          markets, and invitations; send new HCP Admin invitations; interact
          with these entities.
          <a href={userGuideUrl} target="_blank" rel="noopener noreferrer">
            &nbsp;To learn more about the system, read the user guide.
          </a>
        </p>
      </section>
      <hr />
      <section>
        <h2>Getting started</h2>
        <ul>
          <li>Learn how to use the main menu on the left of the page.</li>
          <li>
            Navigate within the various pages to see how they are structured.
          </li>
          <li>
            See how table views work: search for results, filter, sort, use the
            pagination controls.
          </li>
          <li>
            Read the&nbsp;
            <a href={userGuideUrl} target="_blank" rel="noopener noreferrer">
              user guide
            </a>
            &nbsp;to know more about the system.
          </li>
        </ul>
      </section>
      <section>
        <h2>Video tutorials</h2>
        <a href="https://web.microsoftstream.com/channel/7723e109-e4f6-4959-8abb-69ac0c77444b">
          Demant channel in Microsoft Stream.
        </a>
        <ol>
          {videoTutorials.map((item) => (
            <li key={item.title}>
              <a href={item.href} target="_blank" rel="noopener noreferrer">
                {item.title}
              </a>
            </li>
          ))}
        </ol>
      </section>
    </div>
  );
}
