import {ApplicationError} from "../../common/errors";
import {PaginatedSet} from "./lists";
import {OrganizationTableItem} from "./organizations";

export enum HCPRole {
  administrator = "Admin",
  hcp = "Joined",
  undef = "",
}

export interface EmployeeTableItem {
  id: string;
  personId: string;
  firstName: string;
  lastName: string;
  countryCode: string | null;
  organization: OrganizationTableItem;
  email: string;
  role: string;
  hasPI?: boolean;
  piError?: ApplicationError;
  creationTime: string;
}

export interface PersonInformation {
  firstName: string;
  lastName: string;
  countryCode: string | null;
}

export interface EmployeesApi {
  getEmployees(
    page: number,
    sortBy: string,
    search: string,
    brandId: string,
    organizationId: string,
    role: HCPRole
  ): Promise<PaginatedSet<EmployeeTableItem>>;

  removeEmployee(id: string): Promise<void>;

  getPersonInformation(personId: string): Promise<PersonInformation>;
}
