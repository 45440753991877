/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {CustomHeaders} from "./fetch";

class CachedItem<T> {
  private _item: T;
  private _time: number;
  private _maxAgeMs?: number;

  public get item(): T {
    return this._item;
  }

  public get time(): number {
    return this._time;
  }

  constructor(item: T, maxAgeMs?: number) {
    this._item = item;
    this._time = new Date().getTime();
    this._maxAgeMs = maxAgeMs;
  }

  public get expired(): boolean {
    if (this._maxAgeMs === undefined) {
      return false;
    }
    return new Date().getTime() - this._time > this._maxAgeMs;
  }
}

/**
 * Wraps a function to cache its result after the first call, by input
 * parameters! Use this decorator for functions that return items that
 * don't change often: markets, brands, applications.
 *
 * The cached value is kept in memory.
 *
 * TODO: support invalidating the cache from external code (maybe register
 * an event handler)
 */
export function cache(
  maxAgeMs?: number
): (
  target: any,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<any>
) => TypedPropertyDescriptor<any> {
  return (
    target: any,
    propertyKey: string,
    descriptor: TypedPropertyDescriptor<any>
  ): TypedPropertyDescriptor<any> => {
    // if something can be cached by input parameters,
    // we can also cache the calls that retrieve the values
    const cache: {[key: string]: CachedItem<any>} = {};
    const callsCache: {[key: string]: Promise<any> | undefined} = {};

    const originalMethod = descriptor.value;

    // eslint-disable-next-line space-before-function-paren
    descriptor.value = async function (...args: any[]): Promise<any> {
      const key =
        args && args.length
          ? JSON.stringify(args) + JSON.stringify(CustomHeaders)
          : JSON.stringify(CustomHeaders);
      let cachedResponse: CachedItem<any> | undefined = cache[key];
      let currentCall: Promise<any> | undefined = callsCache[key];

      if (cachedResponse && cachedResponse.expired) {
        delete cache[key];
        delete callsCache[key];
        cachedResponse = undefined;
        currentCall = undefined;
      }

      if (cachedResponse !== undefined && cachedResponse.expired === false) {
        return new Promise((resolve, reject) => {
          setTimeout(() => {
            if (cachedResponse !== undefined) {
              resolve(cachedResponse.item);
            } else {
              // This should never happen
              reject("Object was modified");
            }
          }, 0);
        });
      }

      if (currentCall === undefined) {
        currentCall = originalMethod.apply(this, args);
        callsCache[key] = currentCall;
      }

      cachedResponse = await currentCall;
      cache[key] = new CachedItem(cachedResponse, maxAgeMs);
      return cachedResponse;
    };

    return descriptor;
  };
}
