/**
 * Utilities to work with custom event handlers.
 */

export function listen(
  element: HTMLElement | Window,
  key: string,
  handler: (e: any) => void
): void {
  element.addEventListener(key, handler, false);
}

export function stopListening(
  element: HTMLElement | Window,
  key: string,
  handler: (e: any) => void
): void {
  element.removeEventListener(key, handler);
}

export function trigger<T>(
  element: HTMLElement | Window,
  key: string,
  data: T
): void {
  element.dispatchEvent(new CustomEvent(key, {bubbles: true, detail: data}));
}
