import {BaseURL} from ".";
import {Brand, BrandsApi} from "../domain/brands";
import {cache} from "../../common/caching";
import {get} from "../../common/fetch";

export default class OneCustomerBrandsApi implements BrandsApi {
  @cache()
  getBrands(): Promise<Brand[]> {
    return get<Brand[]>(`${BaseURL}/brands`);
  }
}
