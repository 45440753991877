import AlertPanel, {AlertProps, AlertSeverity} from "../../common/alert";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {GraphCategory} from "../../../service/domain/reports";
import {IServices} from "../../../service/services";
import {Button} from "@material-ui/core";
import {dateToYYYYMMDD} from "../../../common/date";
import DynamicSelect from "../../common/forms/select-named-dynamic";
import GenericDialog, {
  GenericDialogProps,
} from "../../common/dialogs/generic-dialog";

export interface GraphsProps {
  services: IServices;
}

export interface GraphsState {
  loading: boolean;
  selectedDate: Date | null;
  graphs: GraphCategory[];
  alert: AlertProps | null;
  dialog: GenericDialogProps | null;
}

export interface ReportDate {
  id: string;
  name: string;
  value: Date;
}

export default class Graphs extends Component<GraphsProps, GraphsState> {
  constructor(props: GraphsProps) {
    super(props);

    this.state = {
      loading: false,
      selectedDate: null,
      graphs: [],
      alert: null,
      dialog: null,
    };
  }

  async loadReportsList(): Promise<ReportDate[]> {
    const {services} = this.props;

    let count = 10;
    let first = true;
    const date = new Date();
    const values: ReportDate[] = [];

    do {
      count -= 1;
      const info = await services.reports.getReportsInfoByDate(date);

      if (info !== null) {
        const id = dateToYYYYMMDD(date);
        const reportTime = new Date(info.time);
        values.push({
          id,
          name: id,
          value: reportTime,
        });

        if (first) {
          first = false;
          this.loadGraphs(new Date(reportTime.getTime()));
        }
      } else {
        if (!first) {
          break;
        }
      }

      date.setDate(date.getDate() - 1);
    } while (count > 0);

    return values;
  }

  sortGraphs(graphs: GraphCategory[]): void {
    graphs.forEach((category) => {
      category.items.sort((a, b) => {
        if (a.name === undefined) {
          return 1;
        }
        if (b.name === undefined) {
          return -1;
        }
        const a_index = a.name.indexOf("HCP");
        const b_index = b.name.indexOf("HCP");
        if (a_index > -1 && b_index === -1) {
          return -1;
        }
        if (b_index > -1 && a_index === -1) {
          return 1;
        }
        return 0;
      });
    });
  }

  async loadGraphs(date: Date): Promise<void> {
    const {services} = this.props;

    this.setState({
      loading: false,
      alert: null,
      selectedDate: date,
    });

    const graphs = await services.reports.getReportsByDate(date);
    this.sortGraphs(graphs);

    if (!graphs.length) {
      this.setState({
        alert: {
          title: "Reports are not available",
          message: "If was not possible to collect reports information.",
          severity: AlertSeverity.info,
        },
        loading: false,
        graphs: [],
      });
      return;
    }

    this.setState({
      loading: false,
      graphs: graphs,
      alert: null,
    });
  }

  print(): void {
    window.print();
  }

  showInformation(): void {
    this.setState({
      dialog: {
        title: "Daily reports",
        description:
          "These reports are generated daily, using the information about " +
          "HCPs, organizations, and end users who create local accounts in " +
          "our users catalogs. Currently, these reports aggregate all " +
          "information across all markets and brands, they are not market or " +
          "brand specific!",
        open: true,
        buttons: [
          {
            id: "close",
            label: "Close",
            onClick: () => {
              this.setState({dialog: null});
            },
          },
        ],
        close: () => {
          this.setState({dialog: null});
        },
      },
    });
  }

  onSelect(date: ReportDate | null): void {
    if (date !== null) {
      this.loadGraphs(date.value);
    }
  }

  renderGraphs(): ReactElement {
    const {loading, graphs, alert} = this.state;

    if (loading) {
      return <Loader />;
    }

    if (alert) {
      return <AlertPanel {...alert} />;
    }

    return (
      <div>
        {graphs.map((graph, index) => {
          return (
            <section key={graph.category} className={graph.category}>
              <h3 className={"graph-category" + (index === 0 ? " first" : "")}>
                {graph.category}
              </h3>
              <div className="graphs-container">
                {graph.items.map((item) => {
                  if (item.file.indexOf(".json") > -1) {
                    return <React.Fragment key={item.file}></React.Fragment>;
                  }
                  return (
                    <div key={item.file}>
                      <img title={item.name} src={item.file} alt={item.name} />
                    </div>
                  );
                })}
              </div>
            </section>
          );
        })}
      </div>
    );
  }

  render(): ReactElement {
    const {selectedDate, dialog} = this.state;
    return (
      <div>
        <div className="reports-head-bar">
          {selectedDate !== null && (
            <div>
              <h5>Reports collected at: {selectedDate.toLocaleString()}</h5>
            </div>
          )}
          <div>
            <DynamicSelect<ReportDate>
              load={this.loadReportsList.bind(this)}
              onSelect={this.onSelect.bind(this)}
              disallowEmpty={true}
            >
              {this.props.children}
            </DynamicSelect>
          </div>
          <div className="buttons">
            <Button size="small" onClick={() => this.showInformation()}>
              Info
            </Button>
            <Button size="small" onClick={() => this.print()}>
              Print
            </Button>
          </div>
        </div>
        {this.renderGraphs()}
        {dialog && <GenericDialog {...dialog}></GenericDialog>}
      </div>
    );
  }
}
