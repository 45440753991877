import AccountPage from "./account/account-page";
import AboutPage from "./about";
import ApplicationsPage from "./applications/applications-page";
import Home from "./home";
import InvitationsPage from "./invitations/invitations-page";
import MarketsPage from "./markets/markets-page";
import AccountsSupportPage from "./support/accounts-support-page";
import {getEmployeesPage} from "./employees/employees-page";
import {getOrganizationsPage} from "./organizations/organizations-page";
import {ReactElement} from "react";
import {User} from "../../service/user";

interface Route {
  path: string;
  exact: boolean;
  main: () => ReactElement;
  auth?: (user: User) => boolean;
}

function _getRoutes(user: User): Route[] {
  return [
    {
      path: "/",
      exact: true,
      main: AboutPage,
    },
    {
      path: "/employees",
      exact: false,
      main: getEmployeesPage(user),
    },
    {
      path: "/invitations",
      exact: false,
      main: InvitationsPage,
    },
    {
      path: "/organizations",
      exact: false,
      main: getOrganizationsPage(user),
    },
    {
      path: "/applications",
      exact: false,
      main: ApplicationsPage,
      auth: (user) => user.superUser,
    },
    {
      path: "/markets",
      exact: false,
      main: MarketsPage,
      auth: (user: User): boolean => {
        return user.handlesManyMarkets();
      },
    },
    {
      path: "/reports",
      exact: false,
      main: Home,
    },
    {
      path: "/accounts",
      exact: false,
      main: AccountsSupportPage,
      auth: (user: User): boolean => {
        return user.superUser || user.hasRole("Support");
      },
    },
    {
      path: "/account",
      exact: false,
      main: () => AccountPage(user),
    },
  ];
}

export function getRoutes(user: User): Route[] {
  return _getRoutes(user).filter(
    (item) => item.auth === undefined || item.auth(user)
  );
}
