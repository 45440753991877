import React, {ReactElement} from "react";
import ApplicationForm from "./application-new";
import ApplicationsTable from "./applications-table";
import {Services} from "../../../service/services";
import Head from "../../common/head";
import {Tabs} from "../../common/tabs";
import {RouteConfiguration} from "../../../common/routing";
import {useParams} from "react-router-dom";
import ApplicationPage from "./application-page";

function Applications(): ReactElement {
  return (
    <React.Fragment>
      <Head title="Applications" />
      <ApplicationsTable services={Services} />
    </React.Fragment>
  );
}

function ApplicationDetails() {
  const {id} = useParams();
  return (
    <div>
      <Head title="Application details" />
      <ApplicationPage id={id} services={Services} />
    </div>
  );
}

function NewApplication(): ReactElement {
  return (
    <React.Fragment>
      <Head title="New application" />
      <ApplicationForm services={Services} />
    </React.Fragment>
  );
}

const subRoutes: RouteConfiguration[] = [
  {
    path: "/applications",
    name: "Applications",
    exact: true,
    main: Applications,
  },
  {
    path: "/applications/new",
    name: "New application",
    exact: true,
    main: NewApplication,
  },
  {
    path: "/applications/:id",
    name: "Application details",
    exact: false,
    hidden: true,
    main: ApplicationDetails,
  },
];

export default function ApplicationsPage(): ReactElement {
  return Tabs(subRoutes);
}
