import AlertPanel, {AlertSeverity} from "../../common/alert";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError, NotFoundError} from "../../../common/errors";
import {Button} from "@material-ui/core";
import {IServices} from "../../../service/services";
import {
  ApplicationDetails,
  UpsertApplicationInput,
} from "../../../service/domain/applications";
import EditApplicationForm from "./application-edit-form";
import ApplicationDetailsView from "./application-details";
import {Link} from "react-router-dom";

export interface NewApplicationProps {
  services: IServices;
}

export interface NewApplicationState {
  waiting: boolean;
  error?: ApplicationError;
  details?: ApplicationDetails;
}

export default class NewApplicationForm extends Component<
  NewApplicationProps,
  NewApplicationState
> {
  constructor(props: NewApplicationProps) {
    super(props);
    this.state = this.initialState();
  }

  initialState(): NewApplicationState {
    return {
      waiting: false,
      error: undefined,
    };
  }

  onResetClick(): void {
    this.setState({
      waiting: false,
      error: undefined,
      details: undefined,
    });
  }

  async onCreated(data: UpsertApplicationInput): Promise<void> {
    const service = this.props.services.applications;
    try {
      const details = await service.getApplicationDetails(data.id);

      this.setState({
        details: details || undefined,
        error: details ? undefined : new NotFoundError(),
      });
    } catch (error) {
      this.setState({
        error,
      });
    }
  }

  render(): ReactElement {
    const {services} = this.props;
    const {waiting, error, details} = this.state;

    if (details) {
      return (
        <div>
          <AlertPanel
            title="Application created successfully"
            message=""
            severity={AlertSeverity.success}
          />
          <ApplicationDetailsView {...details} />
          <div className="buttons-area">
            <Button onClick={() => this.onResetClick()}>
              Create a new one
            </Button>
            <Link to={`/applications/${details.id}`}>
              <Button>Go to the edit view</Button>
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div>
        {waiting && <Loader className="overlay" />}
        <section>
          <h2>Application settings</h2>
          <EditApplicationForm
            details={{
              id: "",
              name: "",
              tenantName: "",
              invitationRedirectUrl: "",
              brands: [],
              markets: [],
            }}
            editableId
            services={services}
            onUpsert={this.onCreated.bind(this)}
          />
        </section>
        {error && <ErrorPanel error={error} />}
      </div>
    );
  }
}
