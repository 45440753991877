import ErrorPanel from "../../components/common/error";
import FaceIcon from "@material-ui/icons/Face";
import MenuIcon from "@material-ui/icons/Menu";
import Public from "@material-ui/icons/Public";
import React, {Component, ReactElement} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {clearMarketContext, restoreMarketContext} from "../../service/context";
import {DialogSize} from "../common/dialogs/size";
import {ForbiddenError} from "../../common/errors";
import {getMainMenu, secondaryListItems} from "./menu";
import {getRoutes} from "./routes";
import {IServices} from "../../service/services";
import {Market} from "../../service/domain/markets";
import {clearTokens, readSessionIdToken} from "../../auth/api";
import {trigger} from "../../common/events";
import {User} from "../../service/user";
import {UserContextView} from "./user-context";
import {
  CssBaseline,
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  Drawer,
  Divider,
  List,
} from "@material-ui/core";
import GenericDialog, {
  GenericDialogProps,
  closedDialog,
} from "../common/dialogs/generic-dialog";

interface LayoutProps {
  services: IServices;
  impersonating: boolean;
}

interface LayoutState {
  market: Market | null;
  drawerOpen: boolean;
  selectedMenuItem?: HTMLElement;
  impersonatingDialog: GenericDialogProps;
  user: User;
}

const DrawerOpenKey = "DRAWER_OPEN";

function getMarketContextTitle(user: User, market: Market): string {
  if (user.handlesAllMarkets()) {
    return `Impersonating a local market employee from ${market.name}`;
  }
  return `Market: ${market.name}`;
}

const UnauthorizedUserMessage = `Login was successful, but you are not
 authorized to use the Demant Admin Tool. Please require access to
 service administrators.`;

export default class Layout extends Component<LayoutProps, LayoutState> {
  constructor(props: LayoutProps) {
    super(props);

    // prefetch brands
    try {
      props.services.brands.getBrands();
    } catch (error) {
      // ignore
    }

    const market = restoreMarketContext();
    const user = User.fromAccessToken(readSessionIdToken());

    this.state = {
      drawerOpen: false,
      selectedMenuItem: undefined,
      market,
      impersonatingDialog: this.getImpersonatingDialog(user, market),
      user,
    };
    this.registerGlobalEventListeners();
  }

  openImpersonatingDialog(): void {
    const {user, market} = this.state;
    this.setState({
      impersonatingDialog: this.getImpersonatingDialog(user, market),
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  onMarketUpdate(e: any): void {
    const market = (e as CustomEvent<Market>).detail as Market | null;

    this.setState({
      market: market,
    });
  }

  registerGlobalEventListeners(): void {
    // Note: we never unmount the Layout component,
    // so there is no risk of memory leaks here; however for best practice
    // event listeners are unregistered when the component unmounts
    window.addEventListener("market-change", this.onMarketUpdate.bind(this));

    window.addEventListener("storage", (e: StorageEvent) => {
      // This event handler is called When the local storage changes
      // in another browser tab; here we handle multiple tabs
      if (e.key === "MARKET") {
        const market = e.newValue ? JSON.parse(e.newValue) : null;
        // trigger a market change event, so that App applies
        // the proper MUI theme
        trigger(window, "market-change", market);

        // since the change happened in another tab (another instance of the
        // SPA!), here we display a dialog to explain the situation
        this.setState({
          market: market,
          impersonatingDialog: this.getImpersonatingDialog(
            this.state.user,
            market
          ),
        });

        if (!market) {
          // we need to clear the context completely on this page, too
          clearMarketContext();
        }
      }
    });
  }

  componentWillUnmount(): void {
    window.removeEventListener(
      "market-change",
      this.onMarketUpdate.bind(this)
    );
  }

  getImpersonatingDialog(
    user: User,
    market: Market | null
  ): GenericDialogProps {
    if (!market || !this.props.impersonating) {
      return closedDialog();
    }

    return {
      title: getMarketContextTitle(user, market),
      description:
        "The portal displays the information seen by a " +
        "local market employee from the selected market.",
      open: true,
      close: (): void => {
        this.setState({});
      },
      size: DialogSize.medium,
      buttons: [
        {
          id: "stop-impersonating",
          label: "Stop impersonating",
          onClick: (): void => {
            clearMarketContext();
            this.dismissImpersonatingInfo();
            trigger(window, "market-change", null);
          },
        },
        {
          id: "ok",
          label: "OK",
          onClick: (): void => {
            this.dismissImpersonatingInfo();
          },
        },
      ],
    };
  }

  dismissImpersonatingInfo(): void {
    const impersonatingDialog = this.state.impersonatingDialog;
    impersonatingDialog.open = false;
    this.setState({
      impersonatingDialog,
    });
  }

  readInitialOpen(): boolean {
    const drawerOpen = localStorage.getItem(DrawerOpenKey);
    return drawerOpen === "1" || drawerOpen === null;
  }

  setInitialOpen(value: boolean): void {
    localStorage.setItem(DrawerOpenKey, value ? "1" : "0");
  }

  componentDidMount(): void {
    this.setState({
      drawerOpen: this.readInitialOpen(),
    });

    // if the user is not authorized, remove the token from cache
    // so that if the user is added to groups, when the page is refreshed it
    // gets a new token from AAD
    const {user} = this.state;
    if (!user.hasUserRole()) {
      clearTokens();
    }
  }

  toggleDrawer(): void {
    const isOpen = this.state.drawerOpen;

    this.setState({drawerOpen: !isOpen});
    this.setInitialOpen(!isOpen);
  }

  handleMenuClick(event: React.MouseEvent<HTMLElement>): void {
    this.setState({
      selectedMenuItem: event.currentTarget,
    });
  }

  getClassName(): string {
    const {impersonating} = this.props;
    const {drawerOpen} = this.state;
    return [
      "theme-default",
      drawerOpen ? "ui-drawer-open" : "ui-drawer-closed",
      impersonating ? "ui-impersonating" : "",
    ]
      .filter((item) => !!item)
      .join(" ");
  }

  render(): ReactElement {
    const {services} = this.props;
    const {market, impersonatingDialog, user} = this.state;
    const open = this.state.drawerOpen;
    const isAuthorized = user.hasUserRole();
    const routes = getRoutes(user);
    return (
      <UserContextView user={user} services={services}>
        <div id="admin-layout" className={this.getClassName()}>
          <CssBaseline />
          <Router>
            <AppBar position="static">
              <Toolbar className="main-toolbar">
                <div className="bar-contents">
                  <img
                    id="logo"
                    src="/demant-logo-negative.svg"
                    width="121px"
                    height="25px"
                    alt="Demant"
                  />
                  <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    className="headline"
                  >
                    Admin Tool
                  </Typography>
                  <div>
                    <div>
                      <nav className="ui-menu-parent" role="navigation">
                        <ul className="ui-menu">
                          {market && (
                            <li
                              onClick={() => this.openImpersonatingDialog()}
                              title={getMarketContextTitle(user, market)}
                            >
                              <span className="current-market">
                                {market.name}
                              </span>
                              <span tabIndex={0}>
                                {user.handlesAllMarkets() ? (
                                  <FaceIcon />
                                ) : (
                                  <Public />
                                )}
                              </span>
                            </li>
                          )}
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            {isAuthorized && (
              <React.Fragment>
                <Drawer
                  id="main-drawer"
                  variant="permanent"
                  className="drawer"
                  open={open}
                >
                  <div className="drawer-toggle-btn">
                    <IconButton onClick={() => this.toggleDrawer()}>
                      <MenuIcon />
                    </IconButton>
                  </div>
                  <Divider />
                  <List id="main-menu">{getMainMenu(user)}</List>
                  <Divider />
                  <List>{secondaryListItems}</List>
                </Drawer>
                <main>
                  <div id="content-area">
                    <Switch>
                      {routes.map((route, index) => (
                        <Route
                          key={index}
                          path={route.path}
                          exact={route.exact}
                        >
                          {route.main}
                        </Route>
                      ))}
                    </Switch>
                  </div>
                </main>
              </React.Fragment>
            )}
            {!isAuthorized && (
              <ErrorPanel
                error={new ForbiddenError(UnauthorizedUserMessage)}
              />
            )}
            {market && <GenericDialog {...impersonatingDialog} />}
          </Router>
        </div>
      </UserContextView>
    );
  }
}
