import {BaseURL} from ".";
import {GraphCategory, ReportInfo, ReportsApi} from "../domain/reports";
import {get, getOptional} from "../../common/fetch";
import {dateToYYYYMMDD} from "../../common/date";

interface ContainerContext {
  storageName: string;
  storageSAS: string;
}

function getContainerURL(context: ContainerContext): string {
  return `https://${context.storageName}.blob.core.windows.net/reports`;
}

function getUrlByDate(
  context: ContainerContext,
  date: Date,
  path: string
): string {
  return `${getContainerURL(context)}/${dateToYYYYMMDD(date)}/${path}${
    context.storageSAS
  }`;
}

async function getContainerContext(): Promise<ContainerContext> {
  // TODO: remove the replacement, once the other issue with /api/v1 is
  // resolved;
  const url =
    BaseURL.indexOf("azurewebsites") > -1
      ? BaseURL.replace("/api/v1", "").replace("/v1", "")
      : BaseURL.replace("/api/v1", "/v1");
  return await get<ContainerContext>(`${url}/dat/reports/context`);
}

export default class OneCustomerReportsApi implements ReportsApi {
  async getReportsInfoByDate(date: Date): Promise<ReportInfo | null> {
    const context = await getContainerContext();

    return await getOptional<ReportInfo>(
      getUrlByDate(context, date, "info.json"),
      undefined,
      false
    );
  }

  async getReportsByDate(date: Date): Promise<GraphCategory[]> {
    const context = await getContainerContext();

    const graphs = await getOptional<GraphCategory[]>(
      getUrlByDate(context, date, "graphs.json"),
      undefined,
      false
    );

    if (graphs === null) {
      // no graphs available for the given date
      return [];
    }

    // this API provides full URLs to the caller
    graphs.forEach((graph) => {
      graph.items.forEach((item) => {
        item.file = getUrlByDate(context, date, item.file);
      });
    });

    return graphs;
  }
}
