import {AppRoles} from "./roles";
import JwtDecode from "jwt-decode";
import {JwtToken} from "../auth/api";

function isTokenExpired(jwt: JwtToken): boolean {
  // check if expires with a marging of 5 seconds
  if (new Date().getTime() >= jwt.exp * 1000 - 5000) {
    return true;
  }
  return false;
}

export class User {
  private _token: JwtToken | null;
  private _roles: string[];
  private _superUser: boolean;

  get token(): JwtToken | null {
    return this._token;
  }

  get roles(): string[] {
    return this._roles;
  }

  get name(): string {
    return this._token?.name || "";
  }

  get email(): string {
    return this._token?.preferred_username || "";
  }

  get superUser(): boolean {
    return this._superUser;
  }

  constructor(token?: JwtToken) {
    if (token) {
      const roles = token?.roles || [];
      this._token = token;
      this._roles = roles;
      this._superUser = roles.includes(AppRoles.SuperUser);
    } else {
      this._token = null;
      this._roles = [];
      this._superUser = false;
    }
  }

  static fromAccessToken(token: string | null): User {
    if (!token) {
      return new User();
    }

    try {
      const jwt = JwtDecode<JwtToken>(token);
      if (isTokenExpired(jwt)) {
        return new User();
      }
      return new User(jwt);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      // TODO: display a nice error in an HTML view (low priority because this
      // is an unlikely scenario)
      window.alert("An error occurred while parsing the access token.");

      return new User();
    }
  }

  hasRole(role: string): boolean {
    if (this._superUser) {
      return true;
    }
    return this._roles.includes(role);
  }

  hasUserRole(): boolean {
    return this.hasRole(AppRoles.User);
  }

  hasDashboardLinkingRole(): boolean {
    return this.hasRole(AppRoles.DashboardLinking);
  }

  /**
   * Returns a value indicating whether the user can interact with more than
   * one market.
   */
  handlesManyMarkets(): boolean {
    if (this.handlesAllMarkets()) {
      return true;
    }
    return this.getMarkets().length > 1;
  }

  /**
   * Returns a value indicating whether the user can handle all markets.
   *
   * When a user can operate on all markets, it can impersonate a local market
   * employee of a specific market.
   */
  handlesAllMarkets(): boolean {
    return this.superUser || this.getMarkets().length === 0;
  }

  private getRolesBySuffix(suffix: string): string[] {
    const roles: string[] = [];
    for (const role of this._roles) {
      if (role.startsWith(suffix)) {
        roles.push(role.substring(suffix.length));
      }
    }
    return roles;
  }

  /**
   * Returns a list of markets this user is enabled to interact with.
   */
  getMarkets(): string[] {
    return this.getRolesBySuffix("Market_");
  }

  /**
   * Returns a list of brands this user is enabled to interact with.
   */
  getBrands(): string[] {
    return this.getRolesBySuffix("Brand_");
  }
}
