import defer from "lodash/defer";
import Panel from "../../common/panel";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {IServices, Services} from "../../../service/services";
import {OrganizationDetails} from "../../../service/domain/organizations";
import Alert, {AlertSeverity} from "../../common/alert";
import EditOrganizationForm from "./organization-edit";
import {CardContent, Typography, Button} from "@material-ui/core";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import Loader from "../../common/loader";
import ErrorPanel from "../../common/error";
import {Link} from "react-router-dom";
import {dismissDialog} from "../common/view-functions";
import Auth from "../auth";
import {UserContext} from "../user-context";
import {AppRoles} from "../../../service/roles";
import EmployeesTable from "../employees/employees-table";
import {HCPRole} from "../../../service/domain/employees";

export interface OrganizationPageProps {
  id: string;
  services: IServices;
}

export interface OrganizationPageState {
  loading: boolean;
  waiting: boolean;
  error?: ApplicationError;
  loadingError?: ApplicationError;
  details: OrganizationDetails | null;
  confirm: ConfirmDialogProps;
}

export default class OrganizationPage extends Component<
  OrganizationPageProps,
  OrganizationPageState
> {
  constructor(props: OrganizationPageProps) {
    super(props);

    this.state = {
      loading: true,
      waiting: false,
      details: null,
      confirm: closedDialog(),
    };
    this.load();
  }

  get id(): string {
    return this.props.id;
  }

  componentDidUpdate(props: OrganizationPageProps): void {
    if (props.id !== this.id) {
      // can happen if the user edits by hand the url
      this.load();
    }
  }

  load(): void {
    const service = this.props.services.organizations;

    if (!this.state.loading) {
      this.setState({
        loading: true,
        loadingError: undefined,
      });
    }

    service.getOrganizationById(this.id).then(
      (data) => {
        this.setState({
          loading: false,
          details: data,
        });
      },
      (error: ApplicationError) => {
        this.setState({
          loading: false,
          loadingError: error,
        });
      }
    );
  }

  onOffboardClick(): void {
    const details = this.state.details;
    if (details === null) {
      return;
    }
    this.setState({
      confirm: {
        open: true,
        title: `Offboard "${details.number || details.name || details.id}"?`,
        description: "This action cannot be undone.",
        close: () => dismissDialog(this),
        confirm: () => this.offboard(),
      },
    });
  }

  offboard(): void {
    const service = this.props.services.organizations;

    this.setState({
      waiting: true,
      error: undefined,
    });

    service.offboardOrganization(this.id).then(
      () => {
        const dialog = this.state.confirm;
        dialog.open = false;

        this.setState({
          waiting: false,
          loading: true,
          confirm: dialog,
          details: null,
        });

        defer(() => {
          // refresh
          this.load();
        });
      },
      (error: ApplicationError) => {
        const dialog = this.state.confirm;
        dialog.open = false;

        this.setState({
          waiting: false,
          error,
          confirm: dialog,
        });
      }
    );
  }

  render(): ReactElement {
    const {services, id} = this.props;
    const {loading, error, details, confirm, waiting, loadingError} =
      this.state;

    return (
      <Panel loading={loading} error={loadingError}>
        {waiting && <Loader className="overlay" />}
        {details === null && (
          <Alert
            title="Organization not found"
            message={`The organization with id ${id} was not found.`}
            severity={AlertSeverity.info}
          />
        )}
        {details !== null && (
          <div>
            <section className="organization-hcps-view">
              <h2>Organization HCPs</h2>
              <EmployeesTable
                employeesService={Services.employees}
                personsService={Services.persons}
                marketsService={Services.markets}
                brandsService={Services.brands}
                organizationsService={Services.organizations}
                role={HCPRole.undef}
                organizationId={id}
                noFilters
              />
            </section>
            <hr />
            <section>
              <h2>Organization settings</h2>
              <UserContext.Consumer>
                {(user) => (
                  <EditOrganizationForm
                    details={details}
                    brandsService={services.brands}
                    marketsService={services.markets}
                    organizationsService={services.organizations}
                    onUpdate={() => {
                      return;
                    }}
                    buttons={[
                      <Link key="back-to-organizations" to="/organizations">
                        <Button>Go to list</Button>
                      </Link>,
                    ]}
                    readonly={!user.hasRole(AppRoles.OrganizationWrite)}
                  />
                )}
              </UserContext.Consumer>
            </section>
            {error && (
              <section>
                <ErrorPanel error={error} />
              </section>
            )}
            <Auth role="OrganizationDelete">
              <section>
                <Alert
                  title="Danger zone"
                  message=""
                  severity={AlertSeverity.warning}
                />
                <div>
                  <dl className="danger no-colons">
                    <dt>
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h6">
                          Organization offboarding
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                        >
                          All the employee-organization assignments, sent
                          invitations, and brand-organization assignments will
                          be removed. Users who do not belong to any other
                          organization will lose their HCP role. The
                          organization itself will not be deleted and it will
                          still exist in the system after the operation.
                        </Typography>
                      </CardContent>
                    </dt>
                    <dd>
                      <Button onClick={this.onOffboardClick.bind(this)}>
                        Offboard the organization
                      </Button>
                    </dd>
                  </dl>
                </div>
              </section>
            </Auth>
          </div>
        )}
        <ConfirmDialog {...confirm} />
      </Panel>
    );
  }
}
