import DynamicMultiCheckbox from "../../common/forms/multi-checkbox-dynamic";
import React, {Component, ReactElement} from "react";
import {ciEquals} from "../../../common/strings";
import {getMarketContext} from "../../../service/context";
import {Market, MarketsApi} from "../../../service/domain/markets";
import {User} from "../../../service/user";
import {UserContext} from "../user-context";

export interface MarketSelectProps {
  marketsService: MarketsApi;
  selected?: Array<Market | string>;
  readonly?: boolean;
}

export interface MarketSelectState {
  error?: string;
  forceSelected?: Array<Market>;
  userMarkets: Array<Market>;
  disabled?: Array<Market | string>;
}

function distinct(markets: Market[]): Market[] {
  const unique: {[key: string]: boolean} = {};
  const distinct: Market[] = [];
  markets.forEach((market) => {
    if (!unique[market.id]) {
      distinct.push(market);
      unique[market.id] = true;
    }
  });
  return distinct;
}

export default class MarketSelect extends Component<
  MarketSelectProps,
  MarketSelectState
> {
  private select: React.RefObject<DynamicMultiCheckbox<Market>>;

  constructor(props: MarketSelectProps) {
    super(props);

    this.select = React.createRef();
    this.state = {userMarkets: []};
  }

  async validate(): Promise<boolean> {
    const value = this.value;
    const {userMarkets} = this.state;

    if (
      !value.filter((item) =>
        userMarkets.find((userMarket) => userMarket.id === item.id)
      ).length
    ) {
      this.setState({
        error: "Please select at least one market.",
      });
      return false;
    }

    this.setState({
      error: undefined,
    });

    return true;
  }

  getCurrentMarkets(allMarkets: Market[]): Market[] {
    const {selected} = this.props;

    if (selected) {
      // there are some selected markets,
      // the use shouldn't be able to alter these but at the same
      // time it's reasonable to show the values to the user
      return allMarkets.filter((item) =>
        selected.find((obj) => {
          if (typeof obj === "string") {
            return obj === item.id;
          }
          return obj.id === item.id;
        })
      );
    }

    return [];
  }

  async getUserMarkets(user: User, allMarkets: Market[]): Promise<Market[]> {
    // returns all markets that are enabled for the user

    if (user.handlesAllMarkets()) {
      // super user or demant HQ
      // still, check if the user is impersonating
      const selectedMarket = getMarketContext();

      if (selectedMarket) {
        return [selectedMarket];
      }
      return allMarkets;
    }

    // local market
    const userMarkets = user.getMarkets();

    return allMarkets.filter((market) => {
      return userMarkets.find((userMarketId) =>
        ciEquals(userMarketId, market.id)
      );
    });
  }

  async configureMarkets(user: User): Promise<Market[]> {
    // returns all markets that must be displayed on the page
    // the user needs to see markets that are currently bound to the
    // organization, even if they are not enabled for the user, and those
    // that can be handled;
    // contextually it configures what markets should be disabled or
    // automatically selected
    const allMarkets = await this.props.marketsService.getMarkets("", "");
    const userMarkets = await this.getUserMarkets(user, allMarkets);
    const currentSelection = this.props.selected;

    const currentMarkets = allMarkets.filter((item) =>
      (currentSelection ?? []).find((obj) => {
        if (typeof obj === "string") {
          return obj === item.id;
        }
        return obj.id === item.id;
      })
    );

    const selectedMarkets: Market[] = [];
    let disabledMarkets: Market[] = [];

    if (userMarkets.length === 1) {
      // disable and select automatically the only market the user can handle
      disabledMarkets.push(userMarkets[0]);
      selectedMarkets.push(userMarkets[0]);
    }

    if (currentMarkets.length) {
      // disable those markets that are already selected and that the user
      // is not authorized to handle
      disabledMarkets = currentMarkets.filter(
        (item) => !userMarkets.find((market) => market.id === item.id)
      );
    }

    this.setState({
      forceSelected: selectedMarkets,
      disabled: disabledMarkets,
      userMarkets: userMarkets,
    });

    return distinct(userMarkets.concat(currentMarkets));
  }

  public get value(): Market[] {
    const control = this.select.current;
    if (control) {
      return control.value;
    }
    return [];
  }

  getAllSelectedMarkets(): Array<Market | string> {
    const {selected} = this.props;

    if (selected !== undefined) {
      return selected;
    }

    const forcedSelected = this.state.forceSelected;

    if (forcedSelected !== undefined) {
      return forcedSelected;
    }
    return [];
  }

  onSelect(): void {
    this.validate();
  }

  render(): ReactElement {
    const {readonly} = this.props;
    const {error, disabled} = this.state;

    return (
      <UserContext.Consumer>
        {(user) => (
          <React.Fragment>
            <DynamicMultiCheckbox<Market>
              load={() => this.configureMarkets(user)}
              readonly={readonly}
              selected={this.getAllSelectedMarkets()}
              onSelect={this.onSelect.bind(this)}
              disabled={disabled}
              ref={this.select}
              autoSelectSingle
            />
            {error && <p className="ui-error">{error}</p>}
          </React.Fragment>
        )}
      </UserContext.Consumer>
    );
  }
}
