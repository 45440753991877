import debounce from "lodash/debounce";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {SortOrder} from "../../common/tables";
import FaceIcon from "@material-ui/icons/Face";
import Public from "@material-ui/icons/Public";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {CommonFiltersState} from "../common/common-filters";
import CommonFiltersView from "../common/common-filters";
import {ApplicationError} from "../../../common/errors";
import {MarketsApi, Market} from "../../../service/domain/markets";
import {
  StaticTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";
import {setMarketContext} from "../../../service/context";
import {dismissDialog} from "../common/view-functions";
import {User} from "../../../service/user";
import {ciEquals} from "../../../common/strings";

export interface MarketsTableProps {
  user: User;
  marketsService: MarketsApi;

  onItemClick?: (item: Market) => void;
}

export interface MarketsTableState {
  loading: boolean;
  error?: ApplicationError;
  items: Market[];
  selectedItem?: Market;
  total: number;
  page: number;
  filters: CommonFiltersState;
  sortProperty: string;
  sortOrder: SortOrder;
  confirm: ConfirmDialogProps;
}

function getProperties(): ItemProperty<Market>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: Market) => {
        return item.id;
      },
    },
    {
      id: "code",
      label: "Code",
      render: (item: Market) => {
        return item.code;
      },
    },
    {
      id: "name",
      label: "Name",
      render: (item: Market) => {
        return item.name;
      },
    },
  ];
}

export default class MarketsTable extends Component<
  MarketsTableProps,
  MarketsTableState
> {
  constructor(props: MarketsTableProps) {
    super(props);

    this.state = {
      loading: true,
      error: undefined,
      items: [],
      total: 0,
      page: 1,
      sortOrder: "asc",
      sortProperty: "name",
      filters: {
        search: "",
        selectedBrandId: "",
        selectedOrganizationId: "",
      },
      confirm: closedDialog(),
    };
  }

  componentDidMount(): void {
    this.load();
  }

  applyFilters = debounce(() => {
    this.load();
  }, 300);

  load(): void {
    this.setState({
      loading: true,
      error: undefined,
    });
    const {user} = this.props;
    const {filters, sortProperty, sortOrder} = this.state;
    const allMarkets = user.handlesAllMarkets();
    const userMarkets = user.getMarkets();

    this.props.marketsService
      .getMarkets(`${sortProperty} ${sortOrder}`, filters.search)
      .then(
        (data: Market[]) => {
          if (!allMarkets) {
            data = data.filter(
              (market) =>
                !!userMarkets.find((item) => ciEquals(item, market.id))
            );
          }
          this.setState({
            items: data,
            total: data.length,
            error: undefined,
            loading: false,
          });
        },
        (error: ApplicationError) => {
          this.setState({
            error,
            loading: false,
          });
        }
      );
  }

  onPageChange(page: number): void {
    this.setState({page});

    setTimeout(() => {
      this.load();
    }, 0);
  }

  onFiltersChange(filters: CommonFiltersState): void {
    this.setState({
      page: 1,
      filters,
    });

    this.applyFilters();
  }

  onSort(prop: string, order: SortOrder): void {
    this.setState({
      sortOrder: order,
      sortProperty: prop,
    });

    this.applyFilters();
  }

  onMarketSelectionClick(item: Market): void {
    const {user} = this.props;
    // If the user is a Demant HQ employee or a Super User that can do
    // everything, it makes sense to speak about "impersonation"

    let title: string;
    let description: string;
    // If the user, instead, is a local market employee having rights over
    // more than one market, then words must be different; because the user
    // can select a market (and can only operate within a specific market)
    if (user.handlesAllMarkets()) {
      title = `Impersonate a local market employee from ${item.name}?`;
      description =
        "You can impersonate a local market employee of this market. " +
        "While you impersonate such user, you can only interact with " +
        "objects that belong to this market. Later, you can stop " +
        "impersonating and return to the current mode.";
    } else {
      title = `Select market context: ${item.name}?`;
      description =
        "Since you are authorized to operate on more than one market, " +
        "you can select a market context. You can only interact with " +
        "objects that belong to a single market at a time.";
    }

    this.setState({
      confirm: {
        open: true,
        title,
        description,
        close: () => dismissDialog(this),
        confirm: () => {
          setMarketContext(item, user.handlesAllMarkets());
          dismissDialog(this);
        },
      },
    });
  }

  render(): ReactElement {
    const {items, loading, error, confirm} = this.state;

    return (
      <div>
        {loading && <Loader className="overlay" />}
        <CommonFiltersView onChange={this.onFiltersChange.bind(this)} />
        {error !== undefined && <ErrorPanel error={error} />}
        {error === undefined && (
          <StaticTable<Market>
            properties={getProperties()}
            defaultSortOrder="asc"
            defaultSortProperty="name"
            onSort={this.onSort.bind(this)}
            actions={this.getActions()}
            items={items}
          />
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<Market>> {
    const user = this.props.user;
    const userMarkets = user.getMarkets();
    const allMarkets = user.handlesAllMarkets();
    return [
      {
        title: allMarkets
          ? "Impersonate a local market employee"
          : "Select market",
        icon: allMarkets ? <FaceIcon /> : <Public />,
        onClick: this.onMarketSelectionClick.bind(this),
        disabled: (item: Market): boolean => {
          if (user.handlesAllMarkets()) {
            return false;
          }
          return !userMarkets.find((marketId) => ciEquals(marketId, item.id));
        },
      },
    ];
  }
}
