import includes from "lodash/includes";
import React, {Component, ReactElement} from "react";
import {NamedItem} from "./select-named";
import {FormControlLabel, Checkbox} from "@material-ui/core";
import * as array from "../../../common/arrays";

export interface MultiCheckboxProps<T> {
  items: T[];
  onSelect?: (items: T[]) => void;
  selected?: Array<T | string>;
  disabled?: Array<T | string>;
  readonly?: boolean;
}

export interface MultiCheckboxState<T> {
  selected: T[];
}

/**
 * Generic component to select many named items using checkboxes.
 * Items are given as props.
 */
export default class MultiCheckbox<T extends NamedItem> extends Component<
  MultiCheckboxProps<T>,
  MultiCheckboxState<T>
> {
  constructor(props: MultiCheckboxProps<T>) {
    super(props);

    this.state = {
      selected: this.getInitiallySelected(props),
    };
  }

  private getInitiallySelected(props: MultiCheckboxProps<T>) {
    const {items, selected} = props;
    if (items.length === 1) {
      return [...items];
    }
    if (!selected) {
      return [];
    }
    return items.filter((item) =>
      selected.find((s) => {
        if (typeof s === "string") {
          return s === item.id;
        }
        return s.id === item.id;
      })
    );
  }

  public get value(): T[] {
    return this.state.selected;
  }

  onChange(checked: boolean, item: T): void {
    const selected = this.state.selected;

    if (checked) {
      selected.push(item);
    } else {
      array.remove(selected, item);
    }

    this.setState({
      selected: [...selected],
    });

    this.onSelect(selected);
  }

  onSelect(selected: T[]): void {
    const onSelect = this.props.onSelect;
    if (onSelect) {
      onSelect(selected);
    }
  }

  getAllChecked(): boolean {
    return this.props.items.length === this.state.selected.length;
  }

  areAllDisabled(): boolean {
    if (!this.props.disabled) {
      return false;
    }
    return this.props.items.length === this.props.disabled.length;
  }

  getSomeChecked(): boolean {
    return !this.getAllChecked() && this.state.selected.length > 0;
  }

  isOptionSelected(item: T): boolean {
    return this.state.selected.indexOf(item) > -1;
  }

  public get enabledItems(): T[] {
    return this.props.items.filter((item) => !this.isDisabled(item));
  }

  public get disabledItems(): T[] {
    return this.props.items.filter((item) => this.isDisabled(item));
  }

  toggleItemsSelection(): void {
    let selected: T[];

    const disabledItems = this.disabledItems;
    if (this.state.selected.length - disabledItems.length) {
      // clear selection:
      // select only those that cannot be disabled
      selected = disabledItems;
    } else {
      selected = this.props.items;
    }

    this.setState({
      selected: [...selected],
    });

    setTimeout(() => {
      this.onSelect(selected);
    }, 0);
  }

  isDisabled(item: T): boolean {
    const {disabled, readonly} = this.props;
    if (readonly) return true;
    if (!disabled) return false;

    return includes(disabled, item) || includes(disabled, item.id);
  }

  render(): ReactElement {
    const {items, readonly} = this.props;

    return (
      <div className="multi-checkbox">
        {items.length > 1 && this.areAllDisabled() === false && (
          <FormControlLabel
            className="toggle-selection"
            control={
              <Checkbox
                checked={this.getAllChecked()}
                indeterminate={this.getSomeChecked()}
                onClick={() => this.toggleItemsSelection()}
                color="primary"
                disabled={readonly}
              />
            }
            label="Toggle selection"
          />
        )}
        {items.map((item) => {
          return (
            <FormControlLabel
              key={item.id}
              control={
                <Checkbox
                  checked={this.isOptionSelected(item)}
                  onChange={(_, checked) => this.onChange(checked, item)}
                  name={item.name}
                />
              }
              label={item.name}
              disabled={this.isDisabled(item)}
            />
          );
        })}
      </div>
    );
  }
}
