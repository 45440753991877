import React, {Component, ReactElement} from "react";
import {Button, Grid, TextField} from "@material-ui/core";
import {changeHandler} from "../../../common/forms";
import {PersonsApi} from "../../../service/domain/persons";
import Loader from "../../common/loader";
import {ApplicationError} from "../../../common/errors";
import ErrorPanel from "../../common/error";

export interface PersonUpdate {
  firstName: string;
  lastName: string;
}

export interface EditPersonProps {
  id: string;
  firstName: string;
  lastName: string;
  service: PersonsApi;
  onCancel?: () => void;
  onUpdate: (update: PersonUpdate) => void;
}

export interface EditPersonState {
  waiting: boolean;
  error?: ApplicationError;
  mod_firstName: string;
  mod_lastName: string;

  firstNameError: boolean;
  firstNameHelperText: string;
  lastNameError: boolean;
  lastNameHelperText: string;
}

export default class EditPersonForm extends Component<
  EditPersonProps,
  EditPersonState
> {
  constructor(props: EditPersonProps) {
    super(props);

    this.state = this.initialState(props);
  }

  initialState(props: EditPersonProps): EditPersonState {
    return {
      waiting: false,
      mod_firstName: props.firstName || "",
      mod_lastName: props.lastName || "",
      firstNameError: false,
      lastNameError: false,
      firstNameHelperText: "",
      lastNameHelperText: "",
    };
  }

  validate(): boolean {
    let anyError = false;
    const {mod_firstName, mod_lastName} = this.state;

    if (!mod_firstName || !mod_firstName.trim()) {
      this.setState({
        firstNameError: true,
        firstNameHelperText: "Please insert a value",
      });
      anyError = true;
    }

    if (!mod_lastName || !mod_lastName.trim()) {
      this.setState({
        firstNameError: true,
        firstNameHelperText: "Please insert a value",
      });
      anyError = true;
    }

    return !anyError;
  }

  hasChanges(): boolean {
    const {firstName, lastName} = this.props;
    const {mod_firstName, mod_lastName} = this.state;
    return firstName !== mod_firstName || lastName !== mod_lastName;
  }

  save(): void {
    if (!this.hasChanges()) {
      return;
    }

    if (!this.validate()) {
      return;
    }

    this.setState({
      waiting: true,
      error: undefined,
    });

    const {id} = this.props;
    const {mod_firstName, mod_lastName} = this.state;

    this.props.service.updatePerson(id, mod_firstName, mod_lastName).then(
      () => {
        this.setState({
          waiting: false,
        });
        this.props.onUpdate({
          firstName: mod_firstName,
          lastName: mod_lastName,
        });
      },
      (error: ApplicationError) => {
        this.setState({
          error,
        });
      }
    );
  }

  cancel(): void {
    this.setState(this.initialState(this.props));

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  render(): ReactElement {
    const {
      waiting,
      mod_firstName,
      mod_lastName,
      firstNameError,
      firstNameHelperText,
      lastNameError,
      lastNameHelperText,
      error,
    } = this.state;
    return (
      <div className="person-edit-form">
        {waiting && <Loader className="overlay" />}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              error={firstNameError}
              helperText={firstNameHelperText}
              name="mod_firstName"
              value={mod_firstName}
              required
              fullWidth
              label="First name"
              autoFocus
              autoComplete="off"
              onChange={changeHandler.bind(this)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={lastNameError}
              helperText={lastNameHelperText}
              name="mod_lastName"
              value={mod_lastName}
              required
              fullWidth
              label="Last name"
              autoFocus
              autoComplete="off"
              onChange={changeHandler.bind(this)}
            />
          </Grid>
          {error && (
            <Grid item xs={12}>
              <ErrorPanel error={error} />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className="form-buttons">
              <Button
                onClick={() => this.cancel()}
                autoFocus
                className="cancel-button"
              >
                Cancel
              </Button>
              <Button
                onClick={() => this.save()}
                className="confirm-button"
                color="secondary"
              >
                Confirm
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}
