import {TokenResponse} from "@azure/msal-browser";
import {isIEorEdge} from "../common/browser";
import {startsWith} from "../common/strings";
/* eslint-disable no-console */
import application from "./config";
import {getAuthStorage} from "./config";

const storage = getAuthStorage();

export const ACCESS_TOKEN_KEY = "ID_TOKEN";

export interface JwtToken {
  aud: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  preferred_username: string;
  roles: string[];
}

export const loginRequest = {
  scopes: ["User.Read"],
};

export function clearSession(): void {
  storage.removeItem(ACCESS_TOKEN_KEY);

  const toRemove: string[] = [];

  for (const x in storage) {
    if (
      x.indexOf("b2c_1a_kisei_signin") > -1 ||
      x.indexOf("login.windows.net") > -1
    ) {
      toRemove.push(x);
    }
  }

  for (const item of toRemove) {
    storage.removeItem(item);
  }
}

export function readSessionData(): {[key: string]: string} | null {
  let key: string;

  for (key in storage) {
    const item = storage.getItem(key);
    if (item && item.indexOf("accessToken") > -1) {
      return JSON.parse(item);
    }
  }

  return null;
}

export function getAccessToken(): string | null {
  return storage.getItem(ACCESS_TOKEN_KEY);
}

export function readSessionAccessToken(): string | null {
  const sessionData = readSessionData();

  if (!sessionData) return null;

  return sessionData["accessToken"];
}

export function readSessionIdToken(): string | null {
  const value = getAccessToken();
  if (value) {
    return value;
  }

  const sessionData = readSessionData();

  if (!sessionData) return null;

  return sessionData["idToken"];
}

export function redirectToLoginPage(): void {
  application.loginRedirect(loginRequest);
}

export function requireLogin(): Promise<void> {
  return new Promise((resolve, reject) => {
    application
      .handleRedirectPromise()
      .then((tokenResponse) => {
        const accountObj = tokenResponse
          ? tokenResponse.account
          : application.getAccount();

        if (accountObj) {
          // Account object was retrieved, continue with app progress
          storage.setItem(ACCESS_TOKEN_KEY, accountObj.idToken);
        } else if (tokenResponse && tokenResponse.tokenType === "Bearer") {
          // No account object available, but access token was retrieved
          storage.setItem(ACCESS_TOKEN_KEY, tokenResponse.idToken);
        } else if (tokenResponse === null) {
          // tokenResponse was null, attempt sign in or enter unauthenticated
          // state for app
          application.loginRedirect(loginRequest);

          return reject();
        } else {
          console.error(
            "tokenResponse was not null but did not have any tokens: " +
              tokenResponse
          );
          return reject();
        }

        resolve();
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function clearTokens(): void {
  let key: string;
  const keysToRemove: string[] = [];

  for (key in storage) {
    if (startsWith(key, "msal.")) {
      keysToRemove.push(key);
    }
  }
  for (key in keysToRemove) {
    storage.removeItem(key);
  }

  storage.removeItem(ACCESS_TOKEN_KEY);
}

export function signOut(): void {
  application.logout();
  clearTokens();
}

export async function refreshToken(): Promise<void> {
  if (isIEorEdge) {
    // IE11 and Edge do not support acquireTokenSilent
    redirectToLoginPage();
    return;
  }

  let tokenResponse: TokenResponse;
  try {
    tokenResponse = await application.acquireTokenSilent(loginRequest);
  } catch (error) {
    // require to repeat sign-in
    clearTokens();
    setTimeout(() => {
      window.location.reload();
    }, 0);
    throw error;
  }

  storage.setItem(ACCESS_TOKEN_KEY, tokenResponse.idToken);
}

export async function checkIfTokenExpired(): Promise<void> {
  // this check is done to avoid polluting the Applications log with
  // failed requests due to 401
  const sessionData = readSessionData();

  if (sessionData === null) {
    await refreshToken();
    return;
  }

  // check if expires with a marging of 5 seconds
  if (
    new Date().getTime() >=
    parseInt(sessionData.expiresOnSec) * 1000 - 5000
  ) {
    await refreshToken();
  }
}
