import Head from "../../common/head";
import InvitationsTable from "./invitations-table";
import NewInvitationForm from "./invitation-new";
import BulkInvitationsForm from "./invitations-bulk";
import React, {ReactElement} from "react";
import {RouteConfiguration} from "../../../common/routing";
import {Services} from "../../../service/services";
import {Tabs} from "../../common/tabs";

function SentInvitations() {
  return (
    <div>
      <Head title="Sent invitations" />
      <InvitationsTable services={Services} />
    </div>
  );
}

function NewInvitation() {
  return (
    <div>
      <Head title="New invitation" />
      <NewInvitationForm services={Services} />
    </div>
  );
}

function BulkInvitations() {
  return (
    <div>
      <Head title="Bulk invitations" />
      <BulkInvitationsForm services={Services} />
    </div>
  );
}

const subRoutes: RouteConfiguration[] = [
  {
    path: "/invitations",
    name: "Sent invitations",
    exact: true,
    main: SentInvitations,
  },
  {
    path: "/invitations/new",
    name: "New invitation",
    exact: false,
    main: NewInvitation,
  },
  {
    path: "/invitations/bulk",
    name: "Bulk invitations",
    exact: false,
    main: BulkInvitations,
  },
];

export default function InvitationsPage(): ReactElement {
  return Tabs(subRoutes);
}
