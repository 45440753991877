import React, {ReactElement} from "react";
import EmployeesTable from "./employees-table";
import {Services} from "../../../service/services";
import Head from "../../common/head";
import {Tabs} from "../../common/tabs";
import {HCPRole} from "../../../service/domain/employees";
import {User} from "../../../service/user";
import {AuthRouteConfiguration} from "../routing";
import {AppRoles} from "../../../service/roles";

function Administrators(): ReactElement {
  return (
    <React.Fragment>
      <Head title="Administrators" />
      <EmployeesTable
        employeesService={Services.employees}
        personsService={Services.persons}
        marketsService={Services.markets}
        brandsService={Services.brands}
        organizationsService={Services.organizations}
        role={HCPRole.administrator}
      />
    </React.Fragment>
  );
}

function HCPs(): ReactElement {
  return (
    <React.Fragment>
      <Head title="HCPs" />
      <EmployeesTable
        employeesService={Services.employees}
        personsService={Services.persons}
        marketsService={Services.markets}
        brandsService={Services.brands}
        organizationsService={Services.organizations}
        role={HCPRole.hcp}
      />
    </React.Fragment>
  );
}

const subRoutes: AuthRouteConfiguration[] = [
  {
    path: "/employees",
    name: "Administrators",
    exact: true,
    main: Administrators,
  },
  {
    path: "/employees/hcps",
    name: "HCPs",
    exact: false,
    main: HCPs,
    auth: (user: User): boolean => {
      return user.hasRole(AppRoles.HCPRead);
    },
  },
];

export function getEmployeesPage(user: User): () => ReactElement {
  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
