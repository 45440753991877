import * as msal from "@azure/msal-browser";
import {isIEorEdge} from "../common/browser";
import ServiceSettings from "../service/settings";

// Notes:
// IE11 and Edge do not work when cacheLocation is sessionStorage

const cacheLocation = isIEorEdge ? "localStorage" : "sessionStorage";

export function getAuthStorage(): Storage {
  if (isIEorEdge) {
    return localStorage;
  }
  return sessionStorage;
}

export const configuration = {
  auth: {
    clientId: ServiceSettings.clientId,
    authority: ServiceSettings.authority,
    redirectUri: ServiceSettings.redirectUrl,
  },
  cache: {
    cacheLocation: cacheLocation,
    storeAuthStateInCookie: isIEorEdge,
  },
};

export default new msal.PublicClientApplication(configuration);
