import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {EmailsApi, EmailTemplate, EmailType} from "../domain/emails";
import {get} from "../../common/fetch";

export default class OneCustomerEmailsApi implements EmailsApi {
  getEmailTemplateUrl(type: EmailType, id: string): string {
    return `${BaseURL}/email-templates/${type}/${id}?inline=true`;
  }

  @cache()
  getEmailTemplate(type: EmailType, id: string): Promise<EmailTemplate> {
    return get<EmailTemplate>(`${BaseURL}/email-templates/${type}/${id}`);
  }
}
