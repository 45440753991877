import React, {ReactElement} from "react";
import {Services} from "../../../service/services";
import Head from "../../common/head";
import {Tabs} from "../../common/tabs";
import {RouteConfiguration} from "../../../common/routing";
import SearchAccountPage from "./search-account";

function SearchAccountTab(): ReactElement {
  return (
    <React.Fragment>
      <Head title="Accounts Support" />
      <SearchAccountPage services={Services} />
    </React.Fragment>
  );
}

const subRoutes: RouteConfiguration[] = [
  {
    path: "/accounts",
    name: "Accounts",
    exact: true,
    main: SearchAccountTab,
  },
];

export default function AccountsSupportPage(): ReactElement {
  return Tabs(subRoutes);
}
