import {BaseURL} from ".";
import {PersonsApi, PersonDetails} from "../domain/persons";
import {getOptional, post} from "../../common/fetch";

export default class OneCustomerPersonsApi implements PersonsApi {
  updatePerson(
    id: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    return post<void>(`${BaseURL}/person/${id}`, {
      firstName,
      lastName,
    });
  }

  getPersonDetails(
    email: string,
    includeProps: boolean
  ): Promise<PersonDetails | null> {
    return getOptional<PersonDetails>(
      `${BaseURL}/email/${btoa(email)}?includeProperties=${
        includeProps ? "true" : "false"
      }`
    );
  }
}
