import React, {Component, ReactElement} from "react";
import {User} from "../../service/user";
import {IServices} from "../../service/services";
import Loader from "../common/loader";
import {ApplicationError} from "../../common/errors";
import {Market} from "../../service/domain/markets";
import {ciEquals} from "../../common/strings";
import ErrorPanel from "../common/error";
import {
  getMarketContext,
  setMarketContext,
  clearMarketContext,
} from "../../service/context";

export const UserContext = React.createContext<User>(new User());

export interface UserContextViewProps {
  user: User;
  services: IServices;
}

interface UserContextViewState {
  loading: boolean;
  error?: ApplicationError;
  warning?: ApplicationError;
}

export class UserContextView extends Component<
  UserContextViewProps,
  UserContextViewState
> {
  constructor(props: UserContextViewProps) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  componentDidMount(): void {
    this.checkIfLocalMarketEmployee(this.props.user);
  }

  private getMatchingMarkets(
    marketIds: string[],
    markets: Market[],
    warnings: string[]
  ): Market[] {
    const matchingMarkets: Market[] = [];

    for (const id of marketIds) {
      const matchingMarket = markets.find((item) => ciEquals(item.id, id));

      if (matchingMarket) {
        matchingMarkets.push(matchingMarket);
      } else {
        warnings.push(`Market not found: ${id}`);
      }
    }

    return matchingMarkets;
  }

  private handleMarkets(marketRoles: string[], systemMarkets: Market[]): void {
    // get matching markets validate markets roles;
    // because market roles could refer to markets that are not configured
    // in the system
    const warnings: string[] = [];
    const matchingMarkets = this.getMatchingMarkets(
      marketRoles,
      systemMarkets,
      warnings
    );

    let error: ApplicationError | undefined;
    let warning: ApplicationError | undefined;

    if (!matchingMarkets.length) {
      const availableMarkets = systemMarkets
        .map((item) => `${item.name} (${item.id.toUpperCase()})`)
        .join(", ");
      error = new ApplicationError(
        `The user is enabled for markets with names: ${marketRoles.join(
          ", "
        )}; but there are no such markets configured in the system.
         Configured markets are: ${availableMarkets}. Please contact
         cloudheroes@demant.com for assistance.`,
        419
      );
    } else if (warnings.length) {
      const availableMarkets = systemMarkets
        .map((item) => item.name)
        .join(", ");
      // some markets roles refer to non existing markets
      warning = new ApplicationError(
        `The user is enabled for markets with names: ${marketRoles.join(
          ", "
        )}; but some of the markets are not configured in the system.
         Configured markets are: ${availableMarkets}.`,
        419
      );
    }

    if (matchingMarkets.length) {
      // if a market context is already set
      const market = getMarketContext();
      if (!market || !matchingMarkets.find((item) => item.id === market.id)) {
        const firstMarket = matchingMarkets[0];
        setMarketContext(firstMarket, false);
      }
    }

    this.setState({
      loading: false,
      error,
      warning,
    });
  }

  private checkIfLocalMarketEmployee(user: User): void {
    const specificMarkets = user.getMarkets();

    if (specificMarkets.length && !user.superUser) {
      // This is a local market employee; meaning that the user can interact
      // with a single market, or some markets

      // Fetch markets list to configure market ID, and validate names in
      // markets roles
      this.props.services.markets.getMarkets("").then(
        (markets) => {
          this.handleMarkets(specificMarkets, markets);
        },
        (error) => {
          this.setState({
            loading: false,
            error,
          });
        }
      );

      return;
    }

    if (!user.superUser) {
      clearMarketContext();
    }
    this.setState({
      loading: false,
    });
  }

  render(): ReactElement {
    const {children, user} = this.props;
    const {loading, error, warning} = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div id="user-context">
        {error !== undefined && <ErrorPanel error={error} />}
        {error === undefined && (
          <React.Fragment>
            {warning !== undefined && (
              <ErrorPanel
                error={warning}
                dismiss={() => {
                  this.setState({
                    warning: undefined,
                  });
                }}
              />
            )}
            <UserContext.Provider value={user}>
              {children}
            </UserContext.Provider>
          </React.Fragment>
        )}
      </div>
    );
  }
}
