/**
 * Reads application settings from metadata elements inside index.html.
 * This way settings can be conveniently modified and
 * configured by environment in automation pipelines, even after the code is
 * built by webpack tasks.
 */
function readFromMetadataElement(settingId: string): string {
  const element = document.getElementById(settingId);

  if (element === null) {
    throw new Error(`Missing "${settingId}" metadata element.`);
  }

  const value = element.getAttribute("content");

  if (!value) {
    throw new Error(`Invalid "${settingId}" metadata element: missing value.`);
  }

  return value;
}

class ServiceSettings {
  private _apiURL: string;
  private _clientId: string;
  private _authority: string;
  private _redirectUrl: string;
  private _monitoringKey: string;

  public get apiURL(): string {
    return this._apiURL;
  }

  public get clientId(): string {
    return this._clientId;
  }

  public get authority(): string {
    return this._authority;
  }

  public get redirectUrl(): string {
    return this._redirectUrl;
  }

  public get monitoringKey(): string {
    return this._monitoringKey;
  }

  constructor() {
    this._apiURL = readFromMetadataElement("api-url");
    this._clientId = readFromMetadataElement("client-id");
    this._authority = readFromMetadataElement("authority");
    this._monitoringKey = readFromMetadataElement("monitoring-key");
    this._redirectUrl = window.location.origin;
  }
}

export default new ServiceSettings();
