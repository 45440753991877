import React, {ReactElement} from "react";
import MarketsTable from "./markets-table";
import {Services} from "../../../service/services";
import Head from "../../common/head";
import {Tabs} from "../../common/tabs";
import {RouteConfiguration} from "../../../common/routing";
import {UserContext} from "../user-context";

function Markets(): ReactElement {
  return (
    <React.Fragment>
      <Head title="Markets" />
      <UserContext.Consumer>
        {(user) => (
          <MarketsTable marketsService={Services.markets} user={user} />
        )}
      </UserContext.Consumer>
    </React.Fragment>
  );
}

const subRoutes: RouteConfiguration[] = [
  {
    path: "/markets",
    name: "Markets",
    exact: true,
    main: Markets,
  },
];

export default function MarketsPage(): ReactElement {
  return Tabs(subRoutes);
}
