import Dashboard from "./reports/dashboard";
import Head from "../common/head";
import React, {ReactElement} from "react";
import {Services} from "../../service/services";

export default function Home(): ReactElement {
  return (
    <div className="wrapper">
      <Head title="Demant Admin Tool - Dashboard" />
      <section>
        <Dashboard services={Services} />
      </section>
    </div>
  );
}
