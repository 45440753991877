import {ApplicationsApi} from "./domain/applications";
import {BrandsApi} from "./domain/brands";
import {EmployeesApi} from "./domain/employees";
import {InvitationsApi} from "./domain/invitations";
import {MarketsApi} from "./domain/markets";
import {OrganizationsApi} from "./domain/organizations";
import {PersonsApi} from "./domain/persons";
import OneCustomerApplicationsApi from "./api/applications";
import OneCustomerBrandsApi from "./api/brands";
import OneCustomerEmailsApi from "./api/emails";
import OneCustomerEmployeesApi from "./api/employees";
import OneCustomerInvitationsApi from "./api/invitations";
import OneCustomerMarketsApi from "./api/markets";
import OneCustomerOrganizationsApi from "./api/organizations";
import OneCustomerPersonsApi from "./api/persons";
import {EmailsApi} from "./domain/emails";
import {ReportsApi} from "./domain/reports";
import OneCustomerReportsApi from "./api/reports";

export interface IServices {
  applications: ApplicationsApi;

  brands: BrandsApi;

  emails: EmailsApi;

  employees: EmployeesApi;

  invitations: InvitationsApi;

  markets: MarketsApi;

  organizations: OrganizationsApi;

  persons: PersonsApi;

  reports: ReportsApi;
}

export const Services: IServices = {
  applications: new OneCustomerApplicationsApi(),

  brands: new OneCustomerBrandsApi(),

  emails: new OneCustomerEmailsApi(),

  employees: new OneCustomerEmployeesApi(),

  invitations: new OneCustomerInvitationsApi(),

  markets: new OneCustomerMarketsApi(),

  organizations: new OneCustomerOrganizationsApi(),

  persons: new OneCustomerPersonsApi(),

  reports: new OneCustomerReportsApi(),
};
