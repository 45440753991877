import React, {Component, ReactElement} from "react";
import {ApplicationDetails} from "../../../service/domain/applications";

/**
 * A read-only view of the application details.
 */
export default class ApplicationDetailsView extends Component<
  ApplicationDetails
> {
  render(): ReactElement {
    const {
      id,
      brands,
      markets,
      name,
      tenantName,
      invitationRedirectUrl,
    } = this.props;

    return (
      <div>
        <dl>
          <dt>Id</dt>
          <dd>{id}</dd>
          <dt>Name</dt>
          <dd>{name}</dd>
          <dt>Tenant name</dt>
          <dd>{tenantName}</dd>
          <dt>Invitation redirect URL</dt>
          <dd>{invitationRedirectUrl}</dd>
          <dt>Brands</dt>
          <dd>
            <ol>
              {brands.map((item) => {
                return <li key={item.id}>{item.name}</li>;
              })}
            </ol>
          </dd>
          <dt>Markets</dt>
          <dd>
            <ol>
              {markets.map((item) => {
                return <li key={item.id}>{item.name}</li>;
              })}
            </ol>
          </dd>
        </dl>
      </div>
    );
  }
}
