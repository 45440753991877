// Generic utilities to work with cultures info

import {endsWith} from "./strings";

export interface CultureInfo {
  name: string;
  displayName: string;
  countryName: string | null;
}

interface CultureItem {
  cultureCode: string;
}

export function filterCulturesByMarket<T extends CultureItem>(
  items: T[],
  countryCode: string,
  countryName: string
): T[] {
  const cultureData = getCulturesDataSync();

  if (cultureData === undefined) {
    // TODO: handle properly this unlikely scenario
    return items;
  }

  return items.filter(
    (item) =>
      item.cultureCode === "en" ||
      cultureData[item.cultureCode]?.countryName === countryName ||
      endsWith(item.cultureCode.toLowerCase(), countryCode.toLowerCase())
  );
}

export function getCulturesDataSync():
  | {[key: string]: CultureInfo}
  | undefined {
  const data = (window as any).CULTURES;
  if (data === undefined) {
    return data;
  }
  return data as {[key: string]: CultureInfo};
}

export async function loadCulturesData(): Promise<
  {[key: string]: CultureInfo} | undefined
  // eslint-disable-next-line indent
> {
  // Cultures information is fetched using an async script,
  // to not slow down the normal execution of the page
  // see ./public/data/cultures.js and index.html.

  // I am happily polluting the global namespace in this scenario,
  // spare me Angularian pedantry if in doubt 👍 (ROPT).
  // This strategy works fine and is used responsibly: it's better than
  // bundling everything together.

  // The following code allows for three retries, awaiting 10ms between each,
  // to fetch the list of cultures.

  let culturesData:
    | {
        [key: string]: CultureInfo;
      }
    | undefined = undefined;
  let attempt = 0;

  while (culturesData === undefined && attempt < 3) {
    culturesData = (window as any).CULTURES;

    if (culturesData === undefined) {
      await new Promise((resolve) => setTimeout(resolve, 10));
    } else {
      return culturesData;
    }

    attempt += 1;
  }

  return culturesData;
}

export function getDisplayName(
  code: string,
  cultures: {[key: string]: CultureInfo} | undefined
): string {
  if (cultures === undefined) {
    return code;
  }
  if (code in cultures) {
    const info = cultures[code];
    return `${info.displayName} - ${info.name}`;
  }

  return code;
}
