import React, {Component, ReactElement, ChangeEvent} from "react";
import {TextField} from "@material-ui/core";
import {changeHandler} from "../../../common/forms";
import BrandFilter from "./brand-filter";
import OrganizationSelect from "../organizations/organization-select";
import {BrandsApi, Brand} from "../../../service/domain/brands";
import {
  OrganizationsApi,
  OrganizationTableItem,
} from "../../../service/domain/organizations";
import {MarketsApi} from "../../../service/domain/markets";
import {InterfaceError} from "../../../common/errors";

export interface CommonFiltersProps {
  brandId?: string;
  brandsService?: BrandsApi;
  marketsService?: MarketsApi;
  organizationsService?: OrganizationsApi;
  onChange: (filters: CommonFiltersState) => void;
  onBrandsLoaded?: (items: Brand[]) => void;
}

export interface CommonFiltersState {
  search: string;
  selectedBrandId: string;
  selectedOrganizationId: string;
}

export default class CommonFilters extends Component<
  CommonFiltersProps,
  CommonFiltersState
> {
  constructor(props: CommonFiltersProps) {
    super(props);

    this.state = {
      search: "",
      selectedBrandId: props.brandId || "",
      selectedOrganizationId: "",
    };
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    // user's interaction
    changeHandler.call(this, event);

    setTimeout(() => {
      this.onFiltersChange();
    }, 0);
  }

  onFiltersChange(): void {
    this.props.onChange(this.state);
  }

  onBrandSelect(brand: Brand | null): void {
    this.setState({
      selectedBrandId: brand ? brand.id : "",
      selectedOrganizationId: "",
    });

    setTimeout(() => {
      this.onFiltersChange();
    }, 0);
  }

  onOrganizationSelect(organization: OrganizationTableItem | null): void {
    this.setState({
      selectedOrganizationId: organization ? organization.id : "",
    });

    setTimeout(() => {
      this.onFiltersChange();
    }, 0);
  }

  renderOrganizationSelect(): ReactElement | null {
    const {brandsService, marketsService, organizationsService} = this.props;
    const {selectedBrandId} = this.state;

    if (!organizationsService) {
      return null;
    }

    if (!brandsService || !marketsService) {
      throw new InterfaceError(
        "If an organization service is configured here, brands and " +
          "markets services are required, too."
      );
    }

    return (
      <OrganizationSelect
        brandId={selectedBrandId}
        brandsService={brandsService}
        marketsService={marketsService}
        organizationsService={organizationsService}
        onSelect={this.onOrganizationSelect.bind(this)}
        disableCreation={true}
      />
    );
  }

  onBrandsLoaded(items: Brand[]): void {
    if (this.props.onBrandsLoaded) {
      this.props.onBrandsLoaded(items);
    }
  }

  render(): ReactElement {
    const {brandId, brandsService, organizationsService} = this.props;
    const {search} = this.state;

    return (
      <div className="filters-region">
        <dl>
          {brandsService && (
            <BrandFilter
              service={brandsService}
              onSelect={this.onBrandSelect.bind(this)}
              onLoaded={this.onBrandsLoaded.bind(this)}
              initialValue={brandId}
              disabled={!!brandId}
            />
          )}
          {organizationsService && this.renderOrganizationSelect()}
          <dt>
            <label>Search</label>
          </dt>
          <dd>
            <TextField
              value={search}
              name="search"
              autoComplete="off"
              onChange={this.onChange.bind(this)}
            />
          </dd>
        </dl>
      </div>
    );
  }
}
