import DynamicMultiCheckbox from "../../common/forms/multi-checkbox-dynamic";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError, InterfaceError} from "../../../common/errors";
import {Brand} from "../../../service/domain/brands";
import {Button} from "@material-ui/core";
import {IServices} from "../../../service/services";
import {Market} from "../../../service/domain/markets";
import {
  UpsertApplicationInput,
  ApplicationDetails,
} from "../../../service/domain/applications";
import {ciEquals} from "../../../common/strings";
import TextInput from "../../common/forms/text-input";
import {validateUUID} from "../../../common/uuid";
import ErrorPanel from "../../common/error";

export interface EditApplicationProps {
  details: ApplicationDetails;
  editableId?: boolean;
  services: IServices;
  onCancel?: () => void;
  onUpsert?: (upsert: UpsertApplicationInput) => void;
  onError?: (error: ApplicationError) => void;
  buttons?: ReactElement[];
}

export interface EditApplicationState {
  waiting: boolean;
  error?: ApplicationError;
}

export default class EditApplicationForm extends Component<
  EditApplicationProps,
  EditApplicationState
> {
  private idField: React.RefObject<TextInput>;
  private nameField: React.RefObject<TextInput>;
  private tenantField: React.RefObject<TextInput>;
  private invitationRedirectUrlField: React.RefObject<TextInput>;
  private brandsControl: React.RefObject<DynamicMultiCheckbox<Brand>>;
  private marketsControl: React.RefObject<DynamicMultiCheckbox<Market>>;

  constructor(props: EditApplicationProps) {
    super(props);

    this.state = this.initialState();

    this.idField = React.createRef();
    this.nameField = React.createRef();
    this.tenantField = React.createRef();
    this.invitationRedirectUrlField = React.createRef();
    this.brandsControl = React.createRef();
    this.marketsControl = React.createRef();
  }

  initialState(): EditApplicationState {
    return {
      waiting: false,
    };
  }

  getMarkets(): Promise<Market[]> {
    return this.props.services.markets.getMarkets("", "");
  }

  async validate(): Promise<boolean> {
    const results = await Promise.all([
      this.idField.current?.validate() || true,
      this.nameField.current?.validate(),
      this.tenantField.current?.validate(),
      this.invitationRedirectUrlField.current?.validate(),
    ]);

    return results.every((item) => item === true);
  }

  async save(): Promise<void> {
    this.setState({
      waiting: true,
      error: undefined,
    });

    if (!(await this.validate())) {
      this.setState({
        waiting: false,
      });
      return;
    }

    const {details, editableId} = this.props;

    const id = editableId ? this.idField.current?.value : details.id;
    const name = this.nameField.current?.value;
    const tenantName = this.tenantField.current?.value;
    const invitationRedirectUrl =
      this.invitationRedirectUrlField.current?.value;
    const brands = this.brandsControl.current?.value;
    const markets = this.marketsControl.current?.value;

    if (
      !id ||
      !name ||
      !brands ||
      !markets ||
      !tenantName ||
      !invitationRedirectUrl
    ) {
      throw new InterfaceError("Expected populated values.");
    }

    const update = {
      id,
      name,
      tenantName,
      invitationRedirectUrl,
      brands: brands.map((item) => item.id),
      markets: markets.map((item) => item.id),
    };

    try {
      await this.props.services.applications.upsertApplication(update);

      this.setState({
        waiting: false,
      });

      if (this.props.onUpsert) this.props.onUpsert(update);
    } catch (error) {
      this.setState({
        waiting: false,
        error,
      });
      if (this.props.onError) this.props.onError(error);
    }
  }

  cancel(): void {
    this.setState(this.initialState());

    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  async validateId(value: string): Promise<string | null> {
    if (!value) {
      return "Please insert a value";
    }

    if (!validateUUID(value)) {
      return "The value is not a valid UUID";
    }

    const service = this.props.services.applications;
    const apps = await service.getApplications("", "", value);

    if (apps.some((item) => ciEquals(value, item.id))) {
      return "There is already an application with the same id.";
    }

    return null;
  }

  async validateName(value: string): Promise<string | null> {
    if (!value) {
      return "Please insert a value";
    }

    const id = this.props.details.id;
    const service = this.props.services.applications;
    const apps = await service.getApplications("", "", value);

    if (apps.some((item) => ciEquals(value, item.name) && item.id !== id)) {
      return "There is already an application with the same name.";
    }

    return null;
  }

  async validateTenant(value: string): Promise<string | null> {
    if (!value) {
      return "Please insert a value";
    }

    return null;
  }

  async validateInvitationRedirectUrl(value: string): Promise<string | null> {
    if (!value) {
      return "Please insert a value";
    }

    if (!value.toLowerCase().startsWith("https://")) {
      return "The value must be a full URL";
    }

    return null;
  }

  render(): ReactElement {
    const {buttons, details, editableId, services, onCancel} = this.props;
    const {waiting, error} = this.state;

    return (
      <div className="application-edit-form">
        {waiting && <Loader className="overlay" />}
        <dl>
          <dt>Id</dt>
          <dd>
            {editableId ? (
              <TextInput
                value={details.id}
                validate={this.validateId.bind(this)}
                ref={this.idField}
                autoFocus
              />
            ) : (
              <span>{details.id}</span>
            )}
          </dd>
          <dt>Name</dt>
          <dd>
            <TextInput
              value={details.name}
              validate={this.validateName.bind(this)}
              ref={this.nameField}
              autoFocus={!editableId}
            />
          </dd>
          <dt>Tenant name</dt>
          <dd>
            <TextInput
              value={details.tenantName}
              validate={this.validateTenant.bind(this)}
              ref={this.tenantField}
            />
          </dd>
          <dt>Invitation redirect URL</dt>
          <dd>
            <TextInput
              value={details.invitationRedirectUrl}
              validate={this.validateInvitationRedirectUrl.bind(this)}
              ref={this.invitationRedirectUrlField}
            />
          </dd>
          <dt>Brands</dt>
          <dd className="checkboxs-wrapper">
            <DynamicMultiCheckbox<Brand>
              load={services.brands.getBrands}
              selected={details.brands}
              ref={this.brandsControl}
            />
          </dd>
          <dt>Markets</dt>
          <dd className="checkboxs-wrapper">
            <DynamicMultiCheckbox<Market>
              load={this.getMarkets.bind(this)}
              selected={details.markets}
              ref={this.marketsControl}
            />
          </dd>
        </dl>
        <div className="form-buttons">
          {onCancel && (
            <Button
              onClick={() => this.cancel()}
              autoFocus
              className="cancel-button"
            >
              Cancel
            </Button>
          )}
          {buttons}
          <Button
            onClick={() => this.save()}
            className="confirm-button"
            color="secondary"
          >
            Confirm
          </Button>
        </div>
        {error && <ErrorPanel error={error} />}
      </div>
    );
  }
}
