import getMuiTheme from "./components/theme";
import Layout from "./components/admin/layout";
import Loader from "./components/common/loader";
import React, {ReactElement, useEffect, useState} from "react";
import {CssBaseline, ThemeProvider} from "@material-ui/core";
import {isImpersonationActive} from "./service/context";
import {requireLogin} from "./auth/api";
import "./styles/global.scss";
import {Services} from "./service/services";
import "./service/monitoring";

function App(): ReactElement {
  const [impersonating, setImpersonating] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line space-before-function-paren
    (async () => {
      window.addEventListener("market-change", () => {
        setImpersonating(isImpersonationActive());
      });
      setImpersonating(isImpersonationActive());
    })();
  });

  useEffect(() => {
    requireLogin().then(
      () => {
        setLoggedIn(true);
      },
      () => {
        // The user is redirected to the login page by MSAL library,
        // there is nothing to do here.
      }
    );
  });

  return (
    <ThemeProvider theme={getMuiTheme(impersonating)}>
      <CssBaseline />
      {loggedIn === false && <Loader />}
      {loggedIn === true && (
        <Layout services={Services} impersonating={impersonating} />
      )}
    </ThemeProvider>
  );
}

export default App;
