import Head from "../../common/head";
import React, {Component, ReactElement} from "react";
import Preferences from "./account-preferences";
import {User} from "../../../service/user";
import {uniqueId} from "lodash";
import {ROLES} from "../../../service/roles";
import {Button} from "@material-ui/core";
import {signOut} from "../../../auth/api";

export interface AccountProps {
  user: User;
}

class AccountPage extends Component<AccountProps> {
  async logout(): Promise<void> {
    signOut();
  }

  render(): ReactElement {
    const {user} = this.props;

    return (
      <div className="wrapper">
        <Head title="Account" />
        <h1>Your account</h1>
        <section>
          <dl>
            <dt>Name</dt>
            <dd>{user.name}</dd>
            <dt>Email</dt>
            <dd>{user.email}</dd>
          </dl>
        </section>
        <section className="dt-strong">
          <h2>Your roles</h2>
          {user.roles.length === 0 && <p>You don`t have any specific role.</p>}
          {user.roles.length > 0 && (
            <dl>
              {user.roles.map((role) => {
                const roleInfo = ROLES[role as keyof typeof ROLES];
                if (roleInfo === undefined) {
                  return <React.Fragment key={uniqueId()}></React.Fragment>;
                }

                return (
                  <React.Fragment key={roleInfo.displayName}>
                    <dt>{roleInfo.displayName}</dt>
                    <dd>{roleInfo.description}</dd>
                  </React.Fragment>
                );
              })}
            </dl>
          )}
        </section>
        <Preferences />
        <section className="log-out-region">
          <Button onClick={() => this.logout()}>Sign-out</Button>
        </section>
      </div>
    );
  }
}

export default function AccountPageRoot(user: User): ReactElement {
  return <AccountPage user={user} />;
}
