import React, {Component, ReactElement} from "react";
import {IServices} from "../../../service/services";
import Loader from "../../common/loader";
import Graphs from "./graphs";

export interface DashboardProps {
  services: IServices;
}

export interface DashboardState {
  loading: boolean;
}

export default class Dashboard extends Component<
  DashboardProps,
  DashboardState
> {
  constructor(props: DashboardProps) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render(): ReactElement {
    const {services} = this.props;
    const {loading} = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <div>
        <Graphs services={services} />
      </div>
    );
  }
}
