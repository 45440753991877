import {Button, ClickAwayListener, Tooltip} from "@material-ui/core";
import React, {Component, ReactElement} from "react";

export interface CopyProps {
  text: string;
}

export interface CopyState {
  showTooltip: boolean;
}

export default class Copy extends Component<CopyProps, CopyState> {
  constructor(props: CopyProps) {
    super(props);

    this.state = {
      showTooltip: false,
    };
  }

  onClick(): void {
    const {text} = this.props;

    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    this.setState({
      showTooltip: true,
    });

    setTimeout(() => {
      this.closeTooltip();
    }, 2000);
  }

  closeTooltip(): void {
    this.setState({
      showTooltip: false,
    });
  }

  render(): ReactElement {
    const {text} = this.props;
    const {showTooltip} = this.state;
    return (
      <span className="copy-to-clipboard">
        <span>{text}</span>
        <ClickAwayListener onClickAway={this.closeTooltip.bind(this)}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={this.closeTooltip.bind(this)}
            open={showTooltip}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied to clipboard!"
          >
            <Button
              color="primary"
              className="copy-to-clipboard"
              onClick={() => this.onClick()}
            >
              <i className="far fa-copy"></i>
            </Button>
          </Tooltip>
        </ClickAwayListener>
      </span>
    );
  }
}
