// Portions of this code are taken from
// https://github.com/RobertoPrevato/KingTable

// TODO (nice to have): add client side export feature to the StaticTable class
// see https://robertoprevato.github.io/demos/kingtable/colors.html

/**
 * Returns a value indicating whether the client side export is supported
 * by the client, or not.
 */
export function supportsClientSideExport(): boolean {
  return (
    !!window.navigator.msSaveBlob ||
    ((): boolean => {
      const link = document.createElement("a");
      return link.download !== undefined;
    })()
  );
}

/**
 * Exports a file; prompting the user for download.
 */
export function exportFile(
  filename: string,
  text: string,
  type: string
): void {
  if (!supportsClientSideExport()) {
    throw new Error("The client does not support file export.");
  }
  const blob = new Blob([text], {type: type});
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);

      link.style.visibility = "hidden";
      link.style.position = "absolute";
      link.style.left = "9999px";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export function readFileAsText(
  file: File,
  encoding: string = "utf-8"
): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (): void => {
      if (typeof reader.result !== "string") {
        reject("The result is not a string.");
        return;
      }

      const text = reader.result;
      resolve(text);
    };

    reader.readAsText(file, encoding);
  });
}
