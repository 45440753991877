import Alert, {AlertSeverity} from "../../common/alert";
import ApplicationEmailTemplateSelect from "../applications/application-email-template-select";
import BrandSelect from "../common/brand-filter";
import OrganizationSelect from "../organizations/organization-select";
import React, {Component, ReactElement} from "react";
import DynamicSelect from "../../common/forms/select-named-dynamic";
import TemplatePreview from "../emails/template-preview";
import {ApplicationTableItem} from "../../../service/domain/applications";
import {Brand} from "../../../service/domain/brands";
import {ConfigurationProblem} from "./common";
import {TemplateCulture} from "../../../service/domain/emails";
import {Grid} from "@material-ui/core";
import {OrganizationTableItem} from "../../../service/domain/organizations";
import {IServices} from "../../../service/services";
import {AppRoles} from "../../../service/roles";
import {UserContext} from "../user-context";

export interface InvitationSettings {
  brand: Brand | null;
  application: ApplicationTableItem | null;
  organization: OrganizationTableItem | null;
  cultureCode: string | null;
}

export interface InvitationSettingsProps {
  selectedBrandId?: string | null;
  selectedApplicationId?: string | null;
  selectedOrganizationId?: string | null;

  services: IServices;
  hideOrganizationSelect?: boolean;

  onBrandsLoaded?: (brands: Brand[]) => void;

  onChange: (configuration: InvitationSettings) => void;
}

export interface InvitationSettingsState {
  selectedBrand: Brand | null;
  selectedApplication: ApplicationTableItem | null;
  selectedOrganization: OrganizationTableItem | null;
  selectedCultureCode: string;
  selectedTemplateId: string;

  problem?: ConfigurationProblem;
}

/**
 * Common view to configure invitations: brand, organization, application
 * this is used both when approving invitation requests and when sending new
 * invitations
 **/
export default class InvitationSettingsView extends Component<
  InvitationSettingsProps,
  InvitationSettingsState
> {
  private applicationSelect: React.RefObject<
    DynamicSelect<ApplicationTableItem>
  >;

  constructor(props: InvitationSettingsProps) {
    super(props);

    this.state = this.initialState();
    this.applicationSelect = React.createRef();
  }

  initialState(): InvitationSettingsState {
    return {
      selectedBrand: null,
      selectedApplication: null,
      selectedOrganization: null,
      selectedCultureCode: "",
      selectedTemplateId: "",
    };
  }

  onBrandSelect(item: Brand | null): void {
    this.setState({
      problem: undefined,
      selectedBrand: item,
      selectedOrganization: null,
      selectedApplication: null,
    });
    this.onChange();
  }

  onApplicationSelect(item: ApplicationTableItem | null): void {
    this.setState({
      problem: undefined,
      selectedApplication: item,
      selectedTemplateId: "",
    });
    this.onChange();
  }

  onCultureSelect(item: TemplateCulture | null): void {
    this.setState({
      problem: undefined,
      selectedCultureCode: item ? item.id : "",
      selectedTemplateId: item ? item.templateId : "",
    });
    this.onChange();
  }

  onApplicationsLoaded(items: ApplicationTableItem[]): void {
    if (!items.length) {
      this.setState({
        problem: {
          title: "Missing applications",
          message:
            "There are no applications configured for the selected " +
            "brand. At least one application must be configured for a brand, " +
            "contact the service administrators for more information.",
          severity: AlertSeverity.info,
        },
      });
    }
  }

  onOrganizationSelect(item: OrganizationTableItem | null): void {
    this.setState({
      selectedOrganization: item,
    });
    this.onChange();
  }

  getApplicationsByBrand(): Promise<ApplicationTableItem[]> {
    const brand = this.state.selectedBrand;

    if (brand === null) {
      return new Promise((resolve) => resolve([]));
    }

    return this.props.services.applications.getApplications(
      brand.id,
      "name desc"
    );
  }

  onBrandsLoaded(brands: Brand[]): void {
    const {onBrandsLoaded, selectedBrandId} = this.props;
    if (onBrandsLoaded) {
      onBrandsLoaded(brands);
    }
    if (selectedBrandId) {
      // find matching
      const brand = brands.find((item) => item.id === selectedBrandId);

      if (brand) {
        this.onBrandSelect(brand);
      }
    }
  }

  onCulturesLoaded(items: TemplateCulture[]): void {
    if (!items.length) {
      this.setState({
        problem: {
          title: "Missing email templates",
          message:
            "There are no email templates configured for the given " +
            "combination of application and brand. " +
            "Contact the service administrators for more information.",
          severity: AlertSeverity.info,
        },
      });
    }
  }

  onChange(): void {
    setTimeout(() => {
      const {
        selectedBrand,
        selectedApplication,
        selectedOrganization,
        selectedCultureCode,
      } = this.state;

      this.props.onChange({
        brand: selectedBrand,
        application: selectedApplication || null,
        organization: selectedOrganization,
        cultureCode: selectedCultureCode,
      });
    }, 0);
  }

  dismissProblem(): void {
    this.setState({
      problem: undefined,
    });
  }

  render(): ReactElement {
    const {
      selectedBrand,
      selectedApplication,
      selectedTemplateId,
      problem,
    } = this.state;
    const {
      services,
      selectedOrganizationId,
      hideOrganizationSelect,
    } = this.props;

    const selectedBrandId = selectedBrand ? selectedBrand.id : "";

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <dl>
            <BrandSelect
              service={services.brands}
              onSelect={this.onBrandSelect.bind(this)}
              onLoaded={this.onBrandsLoaded.bind(this)}
              initialValue={selectedBrandId}
            />
            {!hideOrganizationSelect && selectedBrandId && (
              <UserContext.Consumer>
                {(user) => (
                  <OrganizationSelect
                    brandId={selectedBrandId}
                    brandsService={services.brands}
                    marketsService={services.markets}
                    organizationsService={services.organizations}
                    onSelect={this.onOrganizationSelect.bind(this)}
                    selectedOrganizationId={
                      selectedOrganizationId || undefined
                    }
                    disableCreation={!user.hasRole(AppRoles.OrganizationWrite)}
                  />
                )}
              </UserContext.Consumer>
            )}
            {selectedBrandId && (
              <React.Fragment>
                <dt>Application</dt>
                <dd className="select-wrapper">
                  <DynamicSelect<ApplicationTableItem>
                    by={selectedBrandId}
                    load={() => this.getApplicationsByBrand()}
                    onSelect={this.onApplicationSelect.bind(this)}
                    onLoaded={this.onApplicationsLoaded.bind(this)}
                    initialValue={
                      this.props.selectedApplicationId || undefined
                    }
                    ref={this.applicationSelect}
                  />
                </dd>
              </React.Fragment>
            )}
            {selectedApplication && (
              <ApplicationEmailTemplateSelect
                service={services.applications}
                applicationId={selectedApplication.id}
                brandId={selectedBrandId}
                onSelect={this.onCultureSelect.bind(this)}
                onLoaded={this.onCulturesLoaded.bind(this)}
              >
                {selectedTemplateId && (
                  <TemplatePreview
                    templateId={selectedTemplateId}
                    service={services.emails}
                  />
                )}
              </ApplicationEmailTemplateSelect>
            )}
          </dl>
        </Grid>
        {problem && (
          <Grid item xs={12}>
            <Alert
              title={problem.title}
              message={problem.message}
              severity={problem.severity}
              dismiss={() => this.dismissProblem()}
            />
          </Grid>
        )}
      </Grid>
    );
  }
}
