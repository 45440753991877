import {PaginatedSet} from "./lists";
import {InterfaceError} from "../../common/errors";
import {Brand} from "./brands";
import {Market} from "./markets";

export interface CreateOrganizationInput {
  name: string;
  number: string;
  brands: string[];
  markets: string[];
  categoryId: string | null;
}

export interface UpdateOrganizationInput {
  id: string;
  name: string;
  number: string;
  brands: string[];
  markets: string[];
  categoryId: string | null;
}

export interface OrganizationTableItem {
  id: string;
  name: string | null;
  number: string | null;
  creationTime: string | null;
  updateTime: string | null;
}

export interface OrganizationDetails extends OrganizationTableItem {
  brands: Brand[];
  markets: Market[];
  categoryId: string | null;
  creationTime: string | null;
  updateTime: string | null;
}

export interface OrganizationsApi {
  getOrganizationById(
    organizationId: string
  ): Promise<OrganizationDetails | null>;

  getOrganizations(
    page: number,
    sortBy: string,
    search: string,
    brandId: string,
    name?: string,
    number?: string
  ): Promise<PaginatedSet<OrganizationTableItem>>;

  createOrganization(
    data: CreateOrganizationInput
  ): Promise<OrganizationDetails>;

  updateOrganization(data: UpdateOrganizationInput): Promise<void>;

  offboardOrganization(organizationId: string): Promise<void>;
}

export class EmptyItemsOrganizationsResponseError extends InterfaceError {
  constructor() {
    super(`The server returned a result with a positive total property, but
    no items inside the "items" property. This is a server side error. The
    total property must describe the count of total items that respect filters,
    and items a subset of paginated items.
    `);
  }
}
