import GenericDialog, {
  closedDialog,
  GenericDialogProps,
} from "../../common/dialogs/generic-dialog";
import OrganizationsTable from "./organizations-table";
import React, {Component, ReactElement} from "react";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {ApplicationError} from "../../../common/errors";
import {BrandsApi} from "../../../service/domain/brands";
import {DialogSize} from "../../common/dialogs/size";
import {
  OrganizationsApi,
  OrganizationTableItem,
  OrganizationDetails,
} from "../../../service/domain/organizations";
import {Button, RadioGroup, Radio, FormControlLabel} from "@material-ui/core";
import NewOrganizationForm from "./organization-new";
import {MarketsApi} from "../../../service/domain/markets";
import {UserContext} from "../user-context";

export interface OrganizationInputProps {
  selectedOrganizationId?: string;
  brandId?: string;
  brandsService: BrandsApi;
  marketsService: MarketsApi;
  organizationsService: OrganizationsApi;
  disableCreation?: boolean;
  onSelect: (item: OrganizationTableItem | null) => void;
}

export enum OrganizationInputMode {
  select = "select",
  create = "create",
}

export interface OrganizationInputState {
  waiting: boolean;
  error?: ApplicationError;
  items: OrganizationTableItem[];
  selectedItem?: OrganizationTableItem;
  dialog: GenericDialogProps;
  value: string;
  mode: OrganizationInputMode;
}

export default class OrganizationInput extends Component<
  OrganizationInputProps,
  OrganizationInputState
> {
  private newOrgForm: React.RefObject<NewOrganizationForm>;

  constructor(props: OrganizationInputProps) {
    super(props);

    this.state = {
      waiting: false,
      error: undefined,
      items: [],
      dialog: closedDialog(),
      value: "",
      mode: OrganizationInputMode.select,
    };

    if (props.selectedOrganizationId) {
      // prepopulate with an organization
      this.prefetch(props.selectedOrganizationId);
    }

    this.newOrgForm = React.createRef();
  }

  async prefetch(organizationId: string): Promise<void> {
    try {
      const service = this.props.organizationsService;
      const organization = await service.getOrganizationById(organizationId);

      if (organization) {
        this.onItemClick(organization);
        this.props.onSelect(organization);
      }
    } catch (error) {
      // do nothing in this case
    }
  }

  dismissDialog(): void {
    const dialog = this.state.dialog;
    dialog.open = false;
    this.setState({
      waiting: false,
      dialog,
    });
  }

  componentDidUpdate(props: OrganizationInputProps): void {
    if (props.brandId !== this.props.brandId) {
      this.setState({
        value: "",
      });
    }
  }

  getTitle(): string {
    if (this.props.disableCreation) {
      return "Select an organization";
    }
    return "Select or create an organization";
  }

  onInputClick(): void {
    // display dialog where the user can search an organization and select
    // since we don't like to repeat ourselves, this component reuses the
    // full organizations table component with filters and pagination
    this.setState({
      dialog: {
        open: true,
        title: this.getTitle(),
        description: "",
        close: () => this.dismissDialog(),
        className: "highlight-selected",
        buttons: [
          {
            id: "done",
            label: "Done",
            onClick: async () => {
              // if in create mode, confirm only if the organization
              // can be created
              const {mode} = this.state;
              if (mode === OrganizationInputMode.create) {
                if (!(await this.newOrgForm.current?.save())) {
                  return false;
                }
              }
              if (mode === OrganizationInputMode.select) {
                if (!this.state.selectedItem) {
                  return false;
                }
              }
              this.onDoneClick();
            },
          },
        ],
        size: DialogSize.big,
      },
    });
  }

  onDoneClick(): void {
    const item = this.state.selectedItem || null;
    this.props.onSelect(item);
    this.dismissDialog();
  }

  getItemValue(item: OrganizationTableItem): string {
    // this is only displayed in the input field for user friendliness
    if (item.name && item.number) {
      return `${item.name} (${item.number})`;
    }

    if (item.name) {
      return item.name;
    }

    if (item.number) {
      return `${item.id} (${item.number})`;
    }

    return item.id;
  }

  onItemClick(item: OrganizationTableItem): void {
    this.setState({
      selectedItem: item,
      value: this.getItemValue(item),
    });
  }

  onClearSelectionClick(): void {
    this.setState({
      selectedItem: undefined,
      value: "",
    });
    this.props.onSelect(null);
  }

  onModeChange(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      mode:
        OrganizationInputMode[
          event.target.value as keyof typeof OrganizationInputMode
        ],
    });
  }

  onOrganizationCreated(item: OrganizationDetails): void {
    this.setState({
      selectedItem: item,
      value: this.getItemValue(item),
    });
  }

  render(): ReactElement {
    const {
      brandId,
      brandsService,
      marketsService,
      organizationsService,
      disableCreation,
    } = this.props;
    const {dialog, value, selectedItem, mode} = this.state;

    return (
      <React.Fragment>
        <div className="organization-input">
          <input
            type="text"
            value={value}
            readOnly
            onClick={() => this.onInputClick()}
            className="wide"
          />
          {selectedItem && (
            <Button
              onClick={this.onClearSelectionClick.bind(this)}
              title="Clear selection"
              className="small"
            >
              <RemoveCircleOutlineIcon />
            </Button>
          )}
          <GenericDialog {...dialog}>
            {!disableCreation && (
              <dl className="compact">
                <dt>Input mode</dt>
                <dd className="radios">
                  <RadioGroup
                    name="input-mode"
                    value={mode}
                    onChange={this.onModeChange.bind(this)}
                    row
                  >
                    <FormControlLabel
                      value={OrganizationInputMode.select}
                      control={<Radio />}
                      label="Select existing"
                    />
                    <FormControlLabel
                      value={OrganizationInputMode.create}
                      control={<Radio />}
                      label="Create new"
                    />
                  </RadioGroup>
                </dd>
              </dl>
            )}
            <div
              className={
                mode === OrganizationInputMode.select
                  ? "display-block"
                  : "display-none"
              }
            >
              <UserContext.Consumer>
                {(user) => (
                  <OrganizationsTable
                    brandId={brandId}
                    brandsService={brandsService}
                    marketsService={marketsService}
                    organizationsService={organizationsService}
                    onItemClick={this.onItemClick.bind(this)}
                    noExtraButtons
                    selectable
                    user={user}
                  />
                )}
              </UserContext.Consumer>
            </div>
            {!disableCreation && (
              <div
                className={
                  mode === OrganizationInputMode.create
                    ? "display-block"
                    : "display-none"
                }
              >
                <NewOrganizationForm
                  selectedBrands={brandId ? [brandId] : []}
                  brandsService={brandsService}
                  marketsService={marketsService}
                  organizationsService={organizationsService}
                  onCreated={(item) => this.onOrganizationCreated(item)}
                  ref={this.newOrgForm}
                  disableButtons={true}
                />
              </div>
            )}
          </GenericDialog>
        </div>
      </React.Fragment>
    );
  }
}
