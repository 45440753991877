import Apps from "@material-ui/icons/Apps";
import AssignmentInd from "@material-ui/icons/AssignmentInd";
import Business from "@material-ui/icons/Business";
import DashboardIcon from "@material-ui/icons/Dashboard";
import Email from "@material-ui/icons/Email";
import HomeIcon from "@material-ui/icons/Home";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import RecentActors from "@material-ui/icons/RecentActors";
import PeopleIcon from "@material-ui/icons/People";
import Public from "@material-ui/icons/Public";
import React, {ReactElement} from "react";
import {AppRoles} from "../../service/roles";
import {Link} from "react-router-dom";
import {User} from "../../service/user";

interface MenuItem {
  path: string;
  text: string;
  icon: ReactElement;
  auth?: (user: User) => boolean;
}

function getMenu(items: MenuItem[]): ReactElement {
  // Note: it would be possible to implement dynamic icon by name,
  // but it increases the bundle size (bad design here in the Material UI)
  return (
    <div>
      {items.map((item) => (
        <Link to={item.path} key={item.path}>
          <ListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        </Link>
      ))}
    </div>
  );
}

export function getMainMenu(user: User): ReactElement {
  const hcpsListEnabled = user.hasRole(AppRoles.HCPRead);

  const mainItems: MenuItem[] = [
    {
      path: "/",
      text: "Home",
      icon: <HomeIcon />,
    },
    {
      path: "/employees",
      text: hcpsListEnabled ? "Admins and HCPs" : "Administrators",
      icon: <PeopleIcon />,
    },
    {
      path: "/invitations",
      text: "Invitations",
      icon: <Email />,
    },
    {
      path: "/organizations",
      text: "Organizations",
      icon: <Business />,
    },
    {
      path: "/applications",
      text: "Applications",
      icon: <Apps />,
      auth: (user) => user.superUser,
    },
    {
      path: "/markets",
      text: "Markets",
      icon: <Public />,
      auth: (user: User): boolean => {
        return user.handlesManyMarkets();
      },
    },
    {
      path: "/accounts",
      text: "Accounts",
      icon: <RecentActors />,
      auth: (user: User): boolean => {
        return user.superUser || user.hasRole("Support");
      },
    },
    {
      path: "/reports",
      text: "Reports",
      icon: <DashboardIcon />,
    },
  ];

  return getMenu(
    mainItems.filter((item) => item.auth === undefined || item.auth(user))
  );
}

export const secondaryListItems = getMenu([
  {
    path: "/account",
    text: "Account",
    icon: <AssignmentInd />,
  },
]);
