import EditOutlined from "@material-ui/icons/EditOutlined";
import {CommonFiltersState} from "../common/common-filters";
import HighlightOff from "@material-ui/icons/HighlightOff";
import React, {Component, ReactElement} from "react";
import {
  EmployeesApi,
  EmployeeTableItem,
  HCPRole,
} from "../../../service/domain/employees";
import {PaginatedSet} from "../../../service/domain/lists";
import {BrandsApi} from "../../../service/domain/brands";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {ApplicationError} from "../../../common/errors";
import EditPersonForm, {PersonUpdate} from "../persons/person-edit-form";
import CommonFiltersView from "../common/common-filters";
import {PersonsApi} from "../../../service/domain/persons";
import {OrganizationsApi} from "../../../service/domain/organizations";

import {
  DynamicTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";
import Loader from "../../common/loader";
import {MarketsApi} from "../../../service/domain/markets";
import OrganizationLink from "../organizations/organization-link";
import {dismissDialog, addErrorToDialog} from "../common/view-functions";
import PersonalField from "./personal-field";
import Copy from "../../common/copy";
import formatDate from "../../../common/format-date";

export interface EmployeesTableProps {
  employeesService: EmployeesApi;
  marketsService: MarketsApi;
  personsService: PersonsApi;
  brandsService: BrandsApi;
  organizationsService: OrganizationsApi;
  role: HCPRole;
  organizationId?: string;
  noFilters?: boolean;
}

export interface EmployeesTableState {
  loading: boolean;
  filters: CommonFiltersState;
  confirm: ConfirmDialogProps;
  canDisplayTable: boolean;
}

function getProperties(
  table: EmployeesTable
): ItemProperty<EmployeeTableItem>[] {
  return [
    {
      id: "id",
      label: "Id",
      notSortable: true,
      render: (item: EmployeeTableItem) => {
        return item.id;
      },
    },
    {
      id: "email",
      label: "Email",
      render: (item: EmployeeTableItem) => {
        return <Copy text={item.email} />;
      },
    },
    {
      id: "firstName",
      label: "First name",
      render: (item: EmployeeTableItem) => {
        return (
          <PersonalField
            value={item.firstName}
            anonymous={!item.hasPI}
            onShowClick={() => {
              table.loadPersonalInformation(item);
            }}
          />
        );
      },
    },
    {
      id: "lastName",
      label: "Last name",
      render: (item: EmployeeTableItem) => {
        return (
          <PersonalField
            value={item.lastName}
            anonymous={!item.hasPI}
            onShowClick={() => {
              table.loadPersonalInformation(item);
            }}
          />
        );
      },
    },
    {
      id: "organizationName",
      label: "Organization name",
      render: (item: EmployeeTableItem) => {
        return (
          <OrganizationLink organization={item.organization}>
            {item.organization.name}
          </OrganizationLink>
        );
      },
    },
    {
      id: "organizationNumber",
      label: "Organization number",
      render: (item: EmployeeTableItem) => {
        return (
          <OrganizationLink organization={item.organization}>
            {item.organization.number}
          </OrganizationLink>
        );
      },
    },
    {
      id: "creationTime",
      label: "Creation time",
      render: (item: EmployeeTableItem) => {
        return formatDate(item.creationTime);
      },
    },
    {
      id: "role",
      label: "Role",
      notSortable: true,
      render: (item: EmployeeTableItem) => {
        return item.role;
      },
    },
  ];
}

export default class EmployeesTable extends Component<
  EmployeesTableProps,
  EmployeesTableState
> {
  private table: React.RefObject<
    DynamicTable<EmployeeTableItem, CommonFiltersState>
  >;

  constructor(props: EmployeesTableProps) {
    super(props);

    this.state = {
      loading: false,
      filters: {
        search: "",
        selectedBrandId: "",
        selectedOrganizationId: props.organizationId ?? "",
      },
      confirm: closedDialog(),
      canDisplayTable: props.noFilters ? true : false,
    };
    this.table = React.createRef();
  }

  async loadPersonalInformation(item: EmployeeTableItem): Promise<void> {
    if (item.hasPI) {
      return;
    }
    const {employeesService} = this.props;
    try {
      const tableState = this.table.current?.state;

      if (!tableState) {
        return;
      }

      const data = await employeesService.getPersonInformation(item.personId);

      const matchingRecords = tableState.items.filter(
        (record) => record.personId === item.personId
      );

      for (const record of matchingRecords) {
        record.hasPI = true;
        record.firstName = data.firstName;
        record.lastName = data.lastName;
        record.countryCode = data.countryCode;
      }

      this.table.current?.refresh();
    } catch (error) {
      alert("An error occurred while fetching personal information.");
    }
  }

  remove(item: EmployeeTableItem): void {
    this.setState({
      loading: true,
    });

    this.props.employeesService.removeEmployee(item.id).then(
      () => {
        dismissDialog(this);
        // refresh the table
        this.table.current?.load();
      },
      (error: ApplicationError) => {
        addErrorToDialog(this, error);
      }
    );
  }

  handlePersonUpdate(update: PersonUpdate, input: EmployeeTableItem): void {
    input.firstName = update.firstName;
    input.lastName = update.lastName;

    // Note: if the user is sorting by first name or last name,
    // the item could disappear from the list if we reloaded, but we don't
    // force reload because it would be confusing.
    dismissDialog(this);
  }

  fetch(
    page: number,
    sortBy: string,
    filters: CommonFiltersState
  ): Promise<PaginatedSet<EmployeeTableItem>> {
    return this.props.employeesService.getEmployees(
      page,
      sortBy,
      filters.search,
      filters.selectedBrandId,
      filters.selectedOrganizationId,
      this.props.role
    );
  }

  onFiltersChange(filters: CommonFiltersState): void {
    this.setState({
      filters,
    });
  }

  private prepareItems(data: PaginatedSet<EmployeeTableItem>): void {
    data.items.forEach((item) => {
      if (item.role === "Joined") {
        item.role = "HCP";
      }
    });
  }

  onBrandsLoaded(): void {
    setTimeout(() => {
      this.setState({
        canDisplayTable: true,
      });
    }, 50);
  }

  render(): ReactElement {
    const {noFilters, brandsService, marketsService, organizationsService} =
      this.props;
    const {loading, confirm, filters, canDisplayTable} = this.state;
    const properties = getProperties(this);

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        {!noFilters && (
          <CommonFiltersView
            brandsService={brandsService}
            marketsService={marketsService}
            organizationsService={organizationsService}
            onChange={this.onFiltersChange.bind(this)}
            onBrandsLoaded={this.onBrandsLoaded.bind(this)}
          />
        )}
        {canDisplayTable && (
          <DynamicTable<EmployeeTableItem, CommonFiltersState>
            items={[]}
            defaultSortOrder="desc"
            defaultSortProperty="creationTime"
            properties={properties}
            filters={filters}
            provider={this.fetch.bind(this)}
            actions={this.getActions()}
            onDataFetched={this.prepareItems.bind(this)}
            ref={this.table}
          />
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<EmployeeTableItem>> {
    return [
      {
        title: "Edit",
        icon: <EditOutlined />,
        onClick: this.onEditClick.bind(this),
      },
      {
        title: "Remove",
        icon: <HighlightOff />,
        onClick: this.onRemoveClick.bind(this),
      },
    ];
  }

  async onEditClick(item: EmployeeTableItem): Promise<void> {
    if (!item.hasPI) {
      this.setState({
        loading: true,
      });
      await this.loadPersonalInformation(item);
      this.setState({
        loading: false,
      });
    }

    this.setState({
      confirm: {
        open: true,
        title: `Edit employee ${this.getUserDisplayName(item)}.`,
        description: "",
        fragment: (
          <EditPersonForm
            service={this.props.personsService}
            id={item.personId}
            firstName={item.firstName}
            lastName={item.lastName}
            onUpdate={(update) => this.handlePersonUpdate(update, item)}
            onCancel={() => dismissDialog(this)}
          />
        ),
        close: () => {
          return;
        },
        confirm: () => {
          return;
        },
        noButtons: true,
      },
    });
  }

  getUserDisplayName(item: EmployeeTableItem): string {
    const parts: string[] = [];

    if (item.firstName && item.lastName) {
      parts.push(item.firstName);
      parts.push(item.lastName);
    }

    if (item.email) {
      parts.push(`(${item.email})`);
    }

    return parts.join(" ");
  }

  getOrganizationDisplayName(item: EmployeeTableItem): string {
    const organization = item.organization;

    if (!organization) {
      return "";
    }

    if (organization.name && organization.number) {
      return `${organization.name} ${organization.number}`;
    }

    return organization.name || organization.number || "the organization";
  }

  onRemoveClick(item: EmployeeTableItem): void {
    this.setState({
      confirm: {
        open: true,
        title:
          `Remove user ${this.getUserDisplayName(item)} ` +
          `from ${item.organization.name}?`,
        description:
          "If confirmed, this person won't have access to " +
          `${this.getOrganizationDisplayName(item)} anymore.`,
        close: () => dismissDialog(this),
        confirm: () => this.remove(item),
      },
    });
  }
}
