import React, {Component, ReactElement} from "react";
import {OrganizationTableItem} from "../../../service/domain/organizations";
import {Link} from "react-router-dom";

export interface OrganizationLinkProps {
  organization?: OrganizationTableItem | null;
}

export default class OrganizationLink extends Component<
  OrganizationLinkProps
> {
  render(): ReactElement {
    const {children, organization} = this.props;

    if (organization) {
      return <Link to={`/organizations/${organization.id}`}>{children}</Link>;
    }
    return <React.Fragment></React.Fragment>;
  }
}
