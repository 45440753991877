import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {ApplicationRedirectURL} from "../../../service/domain/applications";
import {Brand} from "../../../service/domain/brands";
import {IServices} from "../../../service/services";
import {loadData} from "../common/view-functions";
import {
  StaticTable,
  ItemProperty,
  TableButton,
} from "../../common/tables/tables";

interface ApplicationRedirectURLsViewProps {
  services: IServices;
  applicationId: string;
}

interface ApplicationRedirectURLsViewStats {
  loading: boolean;
  error?: ApplicationError;
  items: ApplicationRedirectURL[];
}

function getProperties(
  table: ApplicationRedirectURLsView
): ItemProperty<ApplicationRedirectURL>[] {
  return [
    {
      id: "id",
      label: "Id",
      hidden: true,
      notSortable: true,
      render: (item: ApplicationRedirectURL) => {
        return item.id;
      },
    },
    {
      id: "role",
      label: "Role",
      notSortable: true,
      render: (item: ApplicationRedirectURL) => {
        return item.appRole.toUpperCase();
      },
    },
    {
      id: "brand",
      label: "Brand",
      notSortable: true,
      render: (item: ApplicationRedirectURL) => {
        const brand = table.brands?.find((b) => b.id === item.brandId);

        if (brand) {
          return brand.name;
        }
        return item.brandId;
      },
    },
    {
      id: "culture",
      label: "Culture",
      notSortable: true,
      render: (item: ApplicationRedirectURL) => {
        return item.cultureCode;
      },
    },
    {
      id: "invitationRedirectUrl",
      label: "Invitation Redirect URL",
      notSortable: true,
      render: (item: ApplicationRedirectURL) => {
        return item.invitationRedirectUrl;
      },
    },
  ];
}

export default class ApplicationRedirectURLsView extends Component<
  ApplicationRedirectURLsViewProps,
  ApplicationRedirectURLsViewStats
> {
  brands: Brand[] | null;

  constructor(props: ApplicationRedirectURLsViewProps) {
    super(props);

    this.state = {
      loading: true,
      items: [],
    };
    this.brands = null;
  }

  componentDidMount(): void {
    this.load();
  }

  get id(): string {
    return this.props.applicationId;
  }

  async load(): Promise<void> {
    const {applications, brands} = this.props.services;
    const data = await loadData(this, () => {
      return applications.getApplicationRedirectURLs(this.id);
    });

    if (this.brands === null) {
      this.brands = await brands.getBrands();
    }

    if (data !== null) {
      data.sort(
        (a: ApplicationRedirectURL, b: ApplicationRedirectURL): number => {
          const brandA = this.brands?.find((brand) => brand.id === a.brandId);
          const brandB = this.brands?.find((brand) => brand.id === b.brandId);

          if (brandA && brandB) {
            if (brandA.name > brandB.name) return 1;
            if (brandA.name < brandB.name) return -1;
          }
          if (a.cultureCode > b.cultureCode) return 1;
          if (a.cultureCode < b.cultureCode) return -1;

          return 0;
        }
      );
    }

    if (data) {
      this.setState({
        items: data,
      });
    }
  }

  getTableButtons(): Array<TableButton<ApplicationRedirectURL>> {
    return [];
  }

  render(): ReactElement {
    const {loading, error, items} = this.state;

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        <StaticTable<ApplicationRedirectURL>
          properties={getProperties(this)}
          defaultSortOrder="asc"
          defaultSortProperty="cultureCode"
          items={items}
          buttons={this.getTableButtons()}
          selectable={false}
        />
        {loading === false && !error && items.length === 0 && (
          <i>The application has no redirect URLs configured.</i>
        )}
        {error && <ErrorPanel error={error} />}
      </div>
    );
  }
}
