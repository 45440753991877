import React, {Component, ReactElement} from "react";
import {NamedItem} from "./select-named";
import {ApplicationError} from "../../../common/errors";
import MultiCheckbox from "./multi-checkbox";
import Loader from "../loader";
import ErrorPanel from "../error";

export interface DynamicMultiCheckboxProps<T> {
  by?: string;
  load: () => Promise<T[]>;
  onSelect?: (items: T[]) => void;
  onLoaded?: (items: T[]) => void;
  selected?: Array<T | string>;
  disabled?: Array<T | string>;
  readonly?: boolean;
  autoSelectSingle?: boolean;
}

export interface DynamicMultiCheckboxState<T> {
  loading: boolean;
  error?: ApplicationError;
  items: T[];
}

/**
 * Generic component to select many named items using checkboxes,
 * with options that are fetched dynamically.
 */
export default class DynamicMultiCheckbox<
  T extends NamedItem
> extends Component<
  DynamicMultiCheckboxProps<T>,
  DynamicMultiCheckboxState<T>
> {
  private multiCheckbox: React.RefObject<MultiCheckbox<T>>;

  constructor(props: DynamicMultiCheckboxProps<T>) {
    super(props);

    this.state = {
      loading: true,
      items: [],
    };
    this.multiCheckbox = React.createRef();
  }

  public get value(): T[] {
    const control = this.multiCheckbox.current;
    if (control) {
      return control.value;
    }
    return [];
  }

  load(): void {
    this.setState({
      loading: true,
      error: undefined,
    });
    this.props.load().then(
      (items) => {
        if (this.props.onLoaded) {
          this.props.onLoaded(items);
        }

        // sort items by name
        items.sort((a: NamedItem, b: NamedItem): number => {
          if (a.name > b.name) return 1;
          if (a.name < b.name) return -1;
          return 0;
        });

        if (items.length === 1 && this.props.autoSelectSingle === true) {
          if (this.props.onSelect) this.props.onSelect(items);
        }

        this.setState({
          items,
          loading: false,
        });
      },
      (error: ApplicationError) => {
        this.setState({
          loading: false,
          error,
        });
      }
    );
  }

  componentDidUpdate(props: DynamicMultiCheckboxProps<T>): void {
    if (props.by !== this.props.by) {
      this.load();
    }
  }

  componentDidMount(): void {
    this.load();
  }

  renderOptions(): ReactElement {
    const {autoSelectSingle, onSelect, selected, disabled, readonly} =
      this.props;
    const {error, loading, items} = this.state;

    if (error) {
      return <ErrorPanel error={error} />;
    }

    if (loading) {
      return <Loader className="mini" />;
    }

    return (
      <MultiCheckbox
        ref={this.multiCheckbox}
        items={items}
        onSelect={onSelect}
        selected={
          items.length === 1 && autoSelectSingle ? [items[0]] : selected
        }
        disabled={disabled}
        readonly={readonly}
      />
    );
  }

  render(): ReactElement {
    return (
      <React.Fragment>
        {this.renderOptions()}
        {this.props.children}
      </React.Fragment>
    );
  }
}
