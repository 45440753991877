import Query from "../../common/query";
import {ApplicationEmailTemplate, EmailType} from "../domain/emails";
import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {del, get, getOptional, put} from "../../common/fetch";
import {
  ApplicationDetails,
  ApplicationsApi,
  ApplicationTableItem,
  UpsertApplicationInput,
  ApplicationRedirectURL,
} from "../domain/applications";

export default class OneCustomerApplicationsApi implements ApplicationsApi {
  @cache(5000)
  getApplicationEmailTemplates(
    applicationId: string,
    type: EmailType,
    brandId: string
  ): Promise<ApplicationEmailTemplate[]> {
    return get<ApplicationEmailTemplate[]>(
      `${BaseURL}/application/${applicationId}/email-templates` +
        `?type=${type}&brandId=${brandId}`
    );
  }

  @cache(2000)
  getApplications(
    brand: string,
    sortBy: string,
    search?: string
  ): Promise<ApplicationTableItem[]> {
    const query = Query.write({
      sortBy,
      brand,
      search: search,
    });
    return get<ApplicationTableItem[]>(`${BaseURL}/applications${query}`);
  }

  @cache(2000)
  getApplicationDetails(
    applicationId: string
  ): Promise<ApplicationDetails | null> {
    return getOptional<ApplicationDetails>(
      `${BaseURL}/applications/${applicationId}`
    );
  }

  @cache(2000)
  getApplicationRedirectURLs(
    applicationId: string
  ): Promise<ApplicationRedirectURL[]> {
    return get<ApplicationRedirectURL[]>(
      `${BaseURL}/applications/${applicationId}/redirect-urls`
    );
  }

  upsertApplication(data: UpsertApplicationInput): Promise<void> {
    return put<void>(`${BaseURL}/applications/${data.id}`, data);
  }

  deleteApplication(applicationId: string): Promise<void> {
    return del(`${BaseURL}/applications/${applicationId}`);
  }
}
