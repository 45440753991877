import React, { Component, ReactElement } from "react";
import { EmailsApi, EmailType } from "../../../service/domain/emails";
import Visibility from "@material-ui/icons/Panorama";
import { Button } from "@material-ui/core";
import GenericDialog, { GenericDialogProps, closedDialog } from "../../common/dialogs/generic-dialog";
import { DialogSize } from "../../common/dialogs/size";


export interface EmailTemplatePreviewProps {
  service: EmailsApi;
  templateId: string
}


export interface EmailTemplatePreviewState {
  dialog: GenericDialogProps
}


export default class EmailTemplatePreview
  extends Component<EmailTemplatePreviewProps, EmailTemplatePreviewState> {

  constructor(props: EmailTemplatePreviewProps) {
    super(props);

    this.state = {
      dialog: closedDialog()
    };
  }

  onClick(): void {
    this.displayData();
  }

  dismissDialog(): void {
    const dialog = this.state.dialog;
    dialog.open = false;
    this.setState({
      dialog
    });
  }

  displayData(): void {
    this.setState({
      dialog: {
        open: true,
        title: "Email preview",
        description: "",
        close: () => this.dismissDialog(),
        buttons: [
          {
            id: "done",
            label: "Done",
            onClick: () => {
              this.dismissDialog();
            }
          }
        ],
        size: DialogSize.full
      }
    });
  }

  getEmailTemplateUrl(): string {
    const {
      templateId
    } = this.props;

    return this.props.service.getEmailTemplateUrl(
      EmailType.Invitation, templateId
    );
  }

  render(): ReactElement {
    const {
      dialog
    } = this.state;
    return (
      <span className="template-preview-btn">
        <Button
          onClick={() => this.onClick()}
          title="See template preview"
          className="small"
        >
          <Visibility />
        </Button>
        <GenericDialog {...dialog}>
          <iframe
            title="email-preview"
            src={this.getEmailTemplateUrl()}
            height="100%"
            width="100%"
          >
          </iframe>
        </GenericDialog>
      </span>
    );
  }
}
