import Alert, {AlertSeverity} from "../../common/alert";
import ApplicationRedirectURLsView from "./application-redirect-urls";
import EditApplicationForm from "./application-edit-form";
import ErrorPanel from "../../common/error";
import InfoIcon from "@material-ui/icons/Info";
import Loader from "../../common/loader";
import Panel from "../../common/panel";
import React, {Component, ReactElement} from "react";
import {ApplicationError} from "../../../common/errors";
import {Button, CardContent, Typography} from "@material-ui/core";
import {dismissDialog} from "../common/view-functions";
import {IServices} from "../../../service/services";
import {Link, Redirect} from "react-router-dom";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";
import {
  ApplicationDetails,
  ApplicationsApi,
} from "../../../service/domain/applications";

const RedirectURLsInfo = `Redirect URLs are used by the OneCustomer API to
 integrate with the Switchboard API: their URL is set as the state of OAuth
 flows, and used by the switchboard to redirect the user to the configured
 endpoint.`;

export interface ApplicationPageProps {
  id: string;
  services: IServices;
}

export interface ApplicationPageState {
  loading: boolean;
  waiting: boolean;
  error?: ApplicationError;
  loadingError?: ApplicationError;
  details: ApplicationDetails | null;
  confirm: ConfirmDialogProps;
  deleted: boolean;
}

export default class ApplicationPage extends Component<
  ApplicationPageProps,
  ApplicationPageState
> {
  constructor(props: ApplicationPageProps) {
    super(props);

    this.state = {
      loading: true,
      waiting: false,
      details: null,
      confirm: closedDialog(),
      deleted: false,
    };
    this.load();
  }

  get id(): string {
    return this.props.id;
  }

  get service(): ApplicationsApi {
    return this.props.services.applications;
  }

  componentDidUpdate(props: ApplicationPageProps): void {
    if (props.id !== this.id) {
      // can happen if the user edits by hand the url
      this.load();
    }
  }

  load(): void {
    const service = this.props.services.applications;

    if (!this.state.loading) {
      this.setState({
        loading: true,
        loadingError: undefined,
      });
    }

    service.getApplicationDetails(this.id).then(
      (data) => {
        this.setState({
          loading: false,
          details: data,
        });
      },
      (error: ApplicationError) => {
        this.setState({
          loading: false,
          loadingError: error,
        });
      }
    );
  }

  onDeleteClick(): void {
    const details = this.state.details;
    if (details === null) {
      return;
    }
    this.setState({
      confirm: {
        open: true,
        title: `Delete "${details.name}"?`,
        description: "This action cannot be undone.",
        close: () => dismissDialog(this),
        confirm: () => this.delete(),
      },
    });
  }

  async delete(): Promise<void> {
    this.setState({
      waiting: true,
      error: undefined,
    });

    try {
      await this.service.deleteApplication(this.id);
      this.setState({
        waiting: false,
        deleted: true,
      });
    } catch (error) {
      this.setState({
        waiting: false,
        error,
      });
    }
  }

  render(): ReactElement {
    const {services, id} = this.props;
    const {
      deleted,
      loading,
      error,
      details,
      confirm,
      waiting,
      loadingError,
    } = this.state;

    if (deleted) {
      return <Redirect to="/applications" />;
    }

    return (
      <Panel loading={loading} error={loadingError}>
        {waiting && <Loader className="overlay" />}
        {details === null && (
          <Alert
            title="Application not found"
            message={`The organization with id ${id} was not found.`}
            severity={AlertSeverity.info}
          />
        )}
        {details !== null && (
          <div>
            <section>
              <h2>Application settings</h2>
              <EditApplicationForm
                details={details}
                services={services}
                buttons={[
                  <Link key="back-to-applications" to="/applications">
                    <Button>Go to list</Button>
                  </Link>,
                ]}
              />
            </section>
            <section>
              <h2>
                Redirect URLs
                <span title={RedirectURLsInfo}>
                  <InfoIcon />
                </span>
              </h2>
              <ApplicationRedirectURLsView
                applicationId={details.id}
                services={services}
              />
            </section>
            {error && (
              <section>
                <ErrorPanel error={error} />
              </section>
            )}
            <section>
              <Alert
                title="Danger zone"
                message=""
                severity={AlertSeverity.warning}
              />
              <div>
                <dl className="danger no-colons">
                  <dt>
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="h6">
                        Delete application
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        All the application-brand, application-market, and
                        application email templates will be deleted.
                      </Typography>
                    </CardContent>
                  </dt>
                  <dd>
                    <Button onClick={this.onDeleteClick.bind(this)}>
                      Delete the application
                    </Button>
                  </dd>
                </dl>
              </div>
            </section>
          </div>
        )}
        <ConfirmDialog {...confirm} />
      </Panel>
    );
  }
}
