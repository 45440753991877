import React, {ReactElement} from "react";
import {Tabs} from "../../common/tabs";
import OrganizationsTable from "./organizations-table";
import NewOrganizationForm from "./organization-new";
import {Services} from "../../../service/services";
import Head from "../../common/head";
import OrganizationPage from "./organization-page";
import {useParams} from "react-router-dom";
import {UserContext} from "../user-context";
import {AppRoles} from "../../../service/roles";
import {User} from "../../../service/user";
import {AuthRouteConfiguration} from "../routing";

function Organizations() {
  return (
    <div>
      <Head title="Organizations" />
      <UserContext.Consumer>
        {(user) => (
          <OrganizationsTable
            brandsService={Services.brands}
            marketsService={Services.markets}
            organizationsService={Services.organizations}
            user={user}
          />
        )}
      </UserContext.Consumer>
    </div>
  );
}

function OrganizationDetails() {
  const {id} = useParams();
  return (
    <div>
      <Head title="Organization details" />
      <OrganizationPage id={id} services={Services} />
    </div>
  );
}

function NewOrganization() {
  return (
    <div>
      <Head title="New organization" />
      <UserContext.Consumer>
        {(user) => (
          <NewOrganizationForm
            brandsService={Services.brands}
            marketsService={Services.markets}
            organizationsService={Services.organizations}
            readonly={!user.hasRole(AppRoles.OrganizationWrite)}
          />
        )}
      </UserContext.Consumer>
    </div>
  );
}

const subRoutes: AuthRouteConfiguration[] = [
  {
    path: "/organizations",
    name: "Organizations",
    exact: true,
    main: Organizations,
  },
  {
    path: "/organizations/new",
    name: "New organization",
    exact: true,
    main: NewOrganization,
    auth: (user: User): boolean => {
      return user.hasRole(AppRoles.OrganizationWrite);
    },
  },
  {
    path: "/organizations/:id",
    name: "OrganizationDetails",
    exact: false,
    hidden: true,
    main: OrganizationDetails,
  },
];

export function getOrganizationsPage(user: User): () => ReactElement {
  return (): ReactElement =>
    Tabs(
      subRoutes.filter((route) => route.auth === undefined || route.auth(user))
    );
}
