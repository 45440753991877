import Query from "../../common/query";
import {BaseURL} from ".";
import {cache} from "../../common/caching";
import {get, getOptional, post, put} from "../../common/fetch";
import {PaginatedSet} from "../domain/lists";
import {
  OrganizationTableItem,
  OrganizationsApi,
  CreateOrganizationInput,
  UpdateOrganizationInput,
  OrganizationDetails,
} from "../domain/organizations";

export default class OneCustomerEmployeesApi implements OrganizationsApi {
  @cache(2000)
  getOrganizationById(
    organizationId: string
  ): Promise<OrganizationDetails | null> {
    return getOptional<OrganizationDetails>(
      `${BaseURL}/organizations/${organizationId}`
    );
  }

  @cache(2000)
  getOrganizations(
    page: number,
    sortBy: string,
    search: string,
    brandId: string,
    name?: string,
    number?: string
  ): Promise<PaginatedSet<OrganizationTableItem>> {
    const query = Query.write({
      page,
      search,
      brand: brandId,
      sortBy,
      name,
      number,
    });

    return get<PaginatedSet<OrganizationTableItem>>(
      `${BaseURL}/organizations${query}`
    );
  }

  createOrganization(
    data: CreateOrganizationInput
  ): Promise<OrganizationDetails> {
    return post<OrganizationDetails>(`${BaseURL}/organizations`, data);
  }

  updateOrganization(data: UpdateOrganizationInput): Promise<void> {
    return put<void>(`${BaseURL}/organizations/${data.id}`, data);
  }

  offboardOrganization(organizationId: string): Promise<void> {
    return post<void>(
      `${BaseURL}/organizations/${organizationId}/offboarding`
    );
  }
}
