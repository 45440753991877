import CommonFiltersView from "../common/common-filters";
import Copy from "../../common/copy";
import formatDate from "../../../common/format-date";
import HighlightOff from "@material-ui/icons/HighlightOff";
import Loader from "../../common/loader";
import OrganizationLink from "../organizations/organization-link";
import React, {Component, ReactElement} from "react";
import {addErrorToDialog, dismissDialog} from "../common/view-functions";
import {ApplicationError} from "../../../common/errors";
import {CommonFiltersState} from "../common/common-filters";
import {InvitationTableItem} from "../../../service/domain/invitations";
import {IServices} from "../../../service/services";
import {PaginatedSet} from "../../../service/domain/lists";
import ConfirmDialog, {
  ConfirmDialogProps,
  closedDialog,
} from "../../common/dialogs/confirm-dialog";

import {
  DynamicTable,
  ItemProperty,
  ItemAction,
} from "../../common/tables/tables";

const Properties: ItemProperty<InvitationTableItem>[] = [
  {
    id: "id",
    label: "Id",
    notSortable: true,
    render: (item: InvitationTableItem) => {
      return item.id;
    },
  },
  {
    id: "email",
    label: "Email",
    render: (item: InvitationTableItem) => {
      return <Copy text={item.email} />;
    },
  },
  {
    id: "creationTime",
    label: "Creation time",
    render: (item: InvitationTableItem) => {
      return formatDate(item.creationTime);
    },
  },
  {
    id: "culture",
    label: "Culture",
    render: (item: InvitationTableItem) => {
      return item.cultureCode;
    },
  },
  {
    id: "role",
    label: "Role",
    render: (item: InvitationTableItem) => {
      return item.role;
    },
  },
  {
    id: "status",
    label: "Status",
    render: (item: InvitationTableItem) => {
      return item.status;
    },
  },
  {
    id: "organizationName",
    label: "Organization name",
    render: (item: InvitationTableItem) => {
      return (
        <OrganizationLink organization={item.organization}>
          {item.organization?.name}
        </OrganizationLink>
      );
    },
  },
  {
    id: "organizationNumber",
    label: "Organization number",
    render: (item: InvitationTableItem) => {
      return (
        <OrganizationLink organization={item.organization}>
          {item.organization?.number}
        </OrganizationLink>
      );
    },
  },
  {
    id: "confirmationTime",
    label: "Confirmation time",
    render: (item: InvitationTableItem) => {
      return formatDate(item.confirmationTime);
    },
  },
];

export interface InvitationsTableProps {
  services: IServices;
}

export interface InvitationsTableState {
  loading: boolean;
  filters: CommonFiltersState;
  confirm: ConfirmDialogProps;
  canDisplayTable: boolean;
}

export default class InvitationsTable extends Component<
  InvitationsTableProps,
  InvitationsTableState
> {
  private table: React.RefObject<
    DynamicTable<InvitationTableItem, CommonFiltersState>
  >;

  constructor(props: InvitationsTableProps) {
    super(props);

    this.state = {
      loading: false,
      filters: {
        search: "",
        selectedBrandId: "",
        selectedOrganizationId: "",
      },
      confirm: closedDialog(),
      canDisplayTable: false,
    };
    this.table = React.createRef();
  }

  private prepareItems(data: PaginatedSet<InvitationTableItem>): void {
    data.items.forEach((item) => {
      if (item.role === "Joined") {
        if (item.organization !== null) {
          // user invited as HCP
          item.role = "HCP";
        } else {
          item.role = "Customer"; // ?? TODO: not sure about this
        }
      }
    });
  }

  onFiltersChange(filters: CommonFiltersState): void {
    this.setState({
      filters,
    });
  }

  remove(item: InvitationTableItem): void {
    this.setState({
      loading: true,
    });

    this.props.services.invitations.deleteInvitation(item.id).then(
      () => {
        dismissDialog(this);
        this.table.current?.load();
      },
      (error: ApplicationError) => {
        addErrorToDialog(this, error);
      }
    );
  }

  getDeleteDescription(item: InvitationTableItem): string {
    if (item.status === "ACTIVE") {
      return (
        "If confirmed, the recipient won`t be able to accept the " +
        "invitation."
      );
    }

    return "This action cannot be undone.";
  }

  onDeleteClick(item: InvitationTableItem): void {
    this.setState({
      confirm: {
        open: true,
        title: `Delete invitation for "${item.email}"?`,
        description: this.getDeleteDescription(item),
        close: () => dismissDialog(this),
        confirm: () => this.remove(item),
      },
    });
  }

  fetch(
    page: number,
    sortBy: string,
    filters: CommonFiltersState
  ): Promise<PaginatedSet<InvitationTableItem>> {
    return this.props.services.invitations.getInvitations(
      page,
      sortBy,
      filters.search,
      filters.selectedBrandId,
      filters.selectedOrganizationId
    );
  }

  onBrandsLoaded(): void {
    setTimeout(() => {
      this.setState({
        canDisplayTable: true,
      });
    }, 10);
  }

  render(): ReactElement {
    const {services} = this.props;
    const {loading, confirm, filters, canDisplayTable} = this.state;

    return (
      <div className="relative">
        {loading && <Loader className="covering" />}
        <CommonFiltersView
          brandsService={services.brands}
          marketsService={services.markets}
          organizationsService={services.organizations}
          onChange={this.onFiltersChange.bind(this)}
          onBrandsLoaded={this.onBrandsLoaded.bind(this)}
        />
        {canDisplayTable && (
          <DynamicTable<InvitationTableItem, CommonFiltersState>
            items={[]}
            defaultSortOrder="desc"
            defaultSortProperty="creationTime"
            properties={Properties}
            filters={filters}
            provider={this.fetch.bind(this)}
            actions={this.getActions()}
            onDataFetched={this.prepareItems.bind(this)}
            ref={this.table}
          />
        )}
        <ConfirmDialog {...confirm} />
      </div>
    );
  }

  getActions(): Array<ItemAction<InvitationTableItem>> {
    return [
      {
        title: "Delete",
        icon: <HighlightOff />,
        onClick: this.onDeleteClick.bind(this),
      },
    ];
  }
}
