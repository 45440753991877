import React, {Component, ReactElement, ChangeEvent} from "react";
import {Chip, TextField} from "@material-ui/core";
import {changeHandler} from "../../../common/forms";
import {splitEmailsCollectErrors} from "../../../common/emails";
import Alert, {AlertProps} from "../../common/alert";
import {debounce, defer} from "lodash";

export interface EmailInputProps {
  onChange: (values: string[]) => void;
}

export interface EmailInputState {
  error: boolean;
  value: string;
  values: string[];
  helperText: string;
  problem?: AlertProps;
}

function errorLabel(errors: string[]): string {
  return errors.length === 1 ? "Invalid value: " : "Invalid values: ";
}

export interface EmailValidationOutput {
  emails: string[];
  errors: string[];
}

export default class EmailInput extends Component<
  EmailInputProps,
  EmailInputState
> {
  constructor(props: EmailInputProps) {
    super(props);

    this.state = {
      error: false,
      value: "",
      values: [],
      helperText: "",
    };
  }

  get value(): string {
    return this.state.value;
  }

  set value(newValue: string) {
    this.setState({value: newValue});

    defer(() => {
      this.checkEmails(false);
    });
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    // user's interaction
    changeHandler.call(this, event);

    this.onValueChange();
  }

  checkEmails(setValue: boolean = true): EmailValidationOutput {
    const value = this.state.value;
    const errors: string[] = [];
    const emails = splitEmailsCollectErrors(value, errors);

    if (errors.length) {
      this.setState({
        error: true,
        helperText: `${errorLabel(errors)} "${errors
          .map((v) => `"${v}"`)
          .join(", ")}".`,
      });
    } else {
      this.setState({
        error: false,
        helperText: "",
      });
    }

    if (emails.length >= 0) {
      this.setState({problem: undefined});
    }

    if (!errors.length) {
      if (setValue) {
        this.setState({
          value: emails.join("; "),
          values: emails,
        });
      } else {
        this.setState({
          values: emails,
        });
      }
      this.props.onChange(emails);
    } else {
      // here we invalidate all emails,
      // it is unclear whether the user should be able to confirm even
      // when having some errors in the list and at least one good email
      // address
      this.props.onChange([]);
    }

    return {
      emails,
      errors,
    };
  }

  validateEmails(): boolean {
    const validation = this.checkEmails();
    return !!validation.emails.length && !validation.errors.length;
  }

  onBlur(): void {
    this.checkEmails();
  }

  onValueChange = debounce(
    () => {
      this.checkEmails(false);
    },
    250,
    {leading: false, trailing: true}
  );

  render(): ReactElement {
    const {error, value, helperText, problem, values} = this.state;

    return (
      <React.Fragment>
        <TextField
          error={error}
          helperText={helperText}
          name="value"
          value={value}
          required
          autoComplete="off"
          autoFocus={true}
          onChange={this.onChange.bind(this)}
          onBlur={this.onBlur.bind(this)}
          variant="outlined"
          multiline
          rows={3}
          fullWidth
          className="wide"
        />
        {problem && <Alert {...problem} />}
        <div className="input-summary">
          {values.map((item) => (
            <Chip key={item} label={item} />
          ))}
        </div>
        {values.length === 0 && (
          <em>
            To invite more than one HCP, you can type several email addresses
            separated by <strong>;</strong> or <strong>,</strong>
          </em>
        )}
      </React.Fragment>
    );
  }
}
