export interface ApplicationEmailTemplate {
  id: string;
  brandId?: string;
  cultureCode: string;
}

export enum EmailType {
  Invitation = "Invitation",
  PasswordReset = "PasswordReset",
}

export interface EmailTemplate {
  id: string;
  cultureCode: string;
  tenantName: string | null;
  applicationClientId: string | null;
  brandId: string | null;
  version: string | null;
  emailFrom: string | null;
  emailFromName: string | null;
  subjectTemplate: string;
  bodyTemplate: string;
  emailUrlOverride: string | null;
}

export interface TemplateCulture {
  id: string;
  name: string;
  templateId: string;
}

export interface EmailsApi {
  getEmailTemplateUrl(type: EmailType, id: string): string;

  getEmailTemplate(type: EmailType, id: string): Promise<EmailTemplate>;
}
