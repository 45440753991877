import React, {Component, ReactElement} from "react";
import {
  OrganizationsApi,
  OrganizationTableItem,
} from "../../../service/domain/organizations";
import OrganizationInput from "./organization-input";
import {BrandsApi} from "../../../service/domain/brands";
import {MarketsApi} from "../../../service/domain/markets";

export interface OrganizationSelectProps {
  brandId?: string;
  selectedOrganizationId?: string;
  brandsService: BrandsApi;
  marketsService: MarketsApi;
  organizationsService: OrganizationsApi;
  onSelect: (item: OrganizationTableItem | null) => void;
  disableCreation?: boolean;
}

export default class OrganizationSelect extends Component<
  OrganizationSelectProps
> {
  render(): ReactElement {
    const {
      brandId,
      brandsService,
      marketsService,
      selectedOrganizationId,
      organizationsService,
      onSelect,
      disableCreation,
    } = this.props;

    return (
      <React.Fragment>
        <dt>Organization</dt>
        <dd>
          <OrganizationInput
            brandId={brandId}
            brandsService={brandsService}
            marketsService={marketsService}
            organizationsService={organizationsService}
            disableCreation={disableCreation}
            selectedOrganizationId={selectedOrganizationId}
            onSelect={onSelect}
          />
        </dd>
      </React.Fragment>
    );
  }
}
