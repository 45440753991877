import defer from "lodash/defer";
import React, {Component, ReactElement, ChangeEvent} from "react";
import {
  OrganizationsApi,
  OrganizationTableItem,
} from "../../../service/domain/organizations";
import {ApplicationError} from "../../../common/errors";
import {TextField} from "@material-ui/core";
import {changeHandler} from "../../../common/forms";
import {PaginatedSet} from "../../../service/domain/lists";

export interface OrganizationNumberFieldProps {
  id?: string;
  organizationsService: OrganizationsApi;
  onChange?: (value: string) => void;
  value?: string | null;
  disabled?: boolean;
}

export interface OrganizationNumberFieldState {
  error?: ApplicationError;
  value: string;
  valueError: boolean;
  valueHelperText: string;
}

export default class OrganizationNumberField extends Component<
  OrganizationNumberFieldProps,
  OrganizationNumberFieldState
> {
  constructor(props: OrganizationNumberFieldProps) {
    super(props);

    this.state = {
      value: props.value || "",
      valueError: false,
      valueHelperText: "",
    };
  }

  public get value(): string {
    return this.state.value;
  }

  onChange(
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | {name?: string; value: unknown}
    >
  ): void {
    changeHandler.call(this, event);

    defer(() => {
      if (this.props.onChange) {
        this.props.onChange(this.state.value);
      }
    });
  }

  async validate(): Promise<boolean> {
    let error = "";
    let organizations: PaginatedSet<OrganizationTableItem>;
    const id = this.props.id;
    const value = this.state.value.trim();

    if (value) {
      // check if unique
      try {
        organizations = await this.props.organizationsService.getOrganizations(
          1,
          "",
          "",
          "",
          "",
          value
        );

        if (organizations.total > 0) {
          // check if we are editing this same organization
          if (
            organizations.total === 1 &&
            id &&
            id === organizations.items[0]?.id
          ) {
            // everything is fine
          } else {
            error = "There is already an organization with this number.";
          }
        }
      } catch (exception) {
        if (exception instanceof ApplicationError) {
          this.setState({
            error: exception,
          });
          error = "Validation failed";
        } else {
          throw exception;
        }
      }
    } else {
      error = "Please insert a value";
    }

    if (error) {
      this.setState({
        valueError: true,
        valueHelperText: error,
      });
      return false;
    }

    this.setState({
      valueError: false,
      valueHelperText: "",
    });
    return true;
  }

  onBlur(): void {
    const value = this.state.value;

    if (value !== value.trim()) {
      this.setState({
        value: value.trim(),
      });
    }
    defer(() => {
      this.validate();
    });
  }

  render(): ReactElement {
    const {value, valueError, valueHelperText} = this.state;
    return (
      <TextField
        variant="outlined"
        error={valueError}
        helperText={valueHelperText}
        name="value"
        value={value}
        autoComplete="off"
        onChange={this.onChange.bind(this)}
        onBlur={this.onBlur.bind(this)}
        disabled={this.props.disabled}
      />
    );
  }
}
