export interface TableHeadItem {
  id: string;
  label: string;
  notSortable?: true;
}

export type SortOrder = "asc" | "desc";

export function swapSortOrder(order: SortOrder): SortOrder {
  return order === "asc" ? "desc" : "asc";
}

export interface TableItem<T> {
  checked?: boolean;
  selectable?: boolean;
  data: T;
}
