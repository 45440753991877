import {CustomHeaders} from "../common/fetch";
import {Market} from "./domain/markets";
import {trigger} from "../common/events";

// This file contains functions that are client-side specific, and specific
// to the Admin Tool SPA. In a central place, it controls how market context
// is read and written in the SPA.
const MarketContextKey = "MARKET";
const MarketCustomHeader = "X-Market";
const ImpersonatingKey = "IMPERSONATING-LME";

export function isImpersonationActive(): boolean {
  return (
    getMarketContext() !== null &&
    localStorage.getItem(ImpersonatingKey) === "1"
  );
}

export function setRequestsMarketContext(marketId: string): void {
  CustomHeaders[MarketCustomHeader] = marketId;
}

export function unsetRequestsMarketContext(): void {
  delete CustomHeaders[MarketCustomHeader];
}

/**
 * Configures market context in both HTML5 storage and custom headers settings.
 */
export function setMarketContext(
  market: Market,
  impersonating: boolean = true
): void {
  localStorage.setItem(MarketContextKey, JSON.stringify(market));
  setRequestsMarketContext(market.id);

  if (impersonating) {
    localStorage.setItem(ImpersonatingKey, "1");
  } else {
    localStorage.removeItem(ImpersonatingKey);
  }

  trigger(window, "market-change", market);
}

/**
 * Clears market context in both HTML5 storage and custom headers settings.
 */
export function clearMarketContext(): void {
  localStorage.removeItem(MarketContextKey);
  unsetRequestsMarketContext();

  // "market-change"
  trigger(window, "market-change", null);
}

/**
 * Reads the market context stored in the HTML5 storage.
 */
export function getMarketContext(): Market | null {
  const data = localStorage.getItem(MarketContextKey);

  if (data) {
    try {
      const market = JSON.parse(data) as Market;

      if (market.id && market.name) {
        return market;
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  return null;
}

/**
 * Restores market context when the web page loads.
 */
export function restoreMarketContext(): Market | null {
  const marketContext = getMarketContext();

  if (marketContext) {
    setRequestsMarketContext(marketContext.id);
  }

  return marketContext;
}
