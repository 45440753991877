import Alert, {AlertSeverity} from "../../common/alert";
import ErrorPanel from "../../common/error";
import Loader from "../../common/loader";
import React, {Component, ReactElement} from "react";
import {ApplicationError, InterfaceError} from "../../../common/errors";
import {Button, Grid} from "@material-ui/core";
import {ConfigurationProblem} from "./common";
import InvitationSettingsView, {
  InvitationSettings,
} from "./invitation-settings";
import EmailInput from "../emails/email-input";
import {HCPRole} from "../../../service/domain/employees";
import {IServices} from "../../../service/services";
import OperationsTable, {
  Operation,
  OperationStatus,
} from "../../common/forms/operations-table";
import {InvitationOutput} from "../../../service/domain/invitations";
import {ApplicationTableItem} from "../../../service/domain/applications";

export class OperationSummary<T> {
  email: string;
  result: T | null;
  error?: ApplicationError;

  constructor(email: string, result: T | null, error?: ApplicationError) {
    this.email = email;
    this.result = result;
    this.error = error;
  }
}

export interface NewInvitationFormProps {
  services: IServices;
}

export interface NewInvitationFormState {
  settings?: InvitationSettings;
  emails?: string[];
  waiting: boolean;
  error?: ApplicationError;
  problem?: ConfigurationProblem;
  confirmButtonDisabled: boolean;

  summary?: OperationSummary<InvitationOutput>[];
}

export default class NewInvitationForm extends Component<
  NewInvitationFormProps,
  NewInvitationFormState
> {
  private emailInput: React.RefObject<EmailInput>;

  constructor(props: NewInvitationFormProps) {
    super(props);

    this.state = this.initialState();
    this.emailInput = React.createRef();
  }

  initialState(): NewInvitationFormState {
    return {
      waiting: false,
      confirmButtonDisabled: true,
    };
  }

  validate(): boolean {
    const validEmails = this.emailInput.current?.validateEmails();

    if (validEmails !== true) {
      return false;
    }

    return this.enableConfirmButton();
  }

  _mapEmailToOperation(
    email: string,
    settings: InvitationSettings,
    application: ApplicationTableItem
  ): Promise<OperationSummary<InvitationOutput>> {
    return new Promise<OperationSummary<InvitationOutput>>((resolve) => {
      this.props.services.invitations
        .sendInvitation({
          email,
          role: HCPRole.administrator,
          organizationId: settings.organization?.id || "",
          cultureCode: settings.cultureCode || "",
          brand: settings.brand?.id || "",
          redirectUrl: application.invitationRedirectUrl,
          clientId: application.id,
          tenant: application.tenantName,
        })
        .then(
          (data) => {
            resolve(new OperationSummary(email, data));
          },
          (error: ApplicationError) => {
            // Note: resolve instead of rejection here because we want
            // other operations in the group to continue even if one fails
            resolve(
              new OperationSummary<InvitationOutput>(email, null, error)
            );
          }
        );
    });
  }

  save(): void {
    if (this.state.confirmButtonDisabled) {
      return;
    }
    if (!this.validate()) {
      return;
    }

    const {emails, settings} = this.state;

    if (!emails) {
      throw new InterfaceError("Missing emails");
    }

    if (!settings) {
      throw new InterfaceError("Missing settings");
    }

    const {application} = settings;

    if (!application) {
      throw new InterfaceError("Missing application");
    }

    this.setState({
      waiting: true,
      error: undefined,
    });

    const operations = emails.map((email) =>
      this._mapEmailToOperation(email, settings, application)
    );

    Promise.all(operations).then(
      (results) => {
        this.setState({
          summary: results,
          waiting: false,
        });
      },
      (error) => {
        this.handleError(error);
      }
    );
  }

  handleError(error: ApplicationError): void {
    if (error.status === 400) {
      const details = error.data;

      if (details) {
        // we can display relevant information to the user.
        this.setState({
          waiting: false,
          problem: {
            title: "Cannot complete the operation",
            message: typeof details === "string" ? details : details.error,
            severity: AlertSeverity.warning,
          },
        });
        return;
      }
    }
    this.setState({
      waiting: false,
      error,
    });
  }

  enableConfirmButton(
    settings?: InvitationSettings,
    emails?: string[]
  ): boolean {
    if (!settings) {
      settings = this.state.settings;
    }
    if (!emails) {
      emails = this.state.emails;
    }
    if (
      settings &&
      settings.application &&
      settings.organization &&
      settings.brand &&
      settings.cultureCode &&
      emails &&
      emails.length > 0
    ) {
      return true;
    }

    return false;
  }

  onSettingsChange(settings: InvitationSettings): void {
    this.setState({
      settings,
      confirmButtonDisabled: !this.enableConfirmButton(settings),
    });
  }

  onEmailsChange(emails: string[]): void {
    this.setState({
      emails,
      confirmButtonDisabled: !this.enableConfirmButton(undefined, emails),
    });
  }

  dismissSummary(): void {
    const emailInput = this.emailInput.current;
    if (emailInput) {
      emailInput.value = "";
    }
    this.setState({
      emails: [],
      summary: undefined,
    });
  }

  private mapSummary(
    summary: OperationSummary<InvitationOutput>[]
  ): Operation[] {
    return summary.map((item) => {
      if (item.error) {
        return {
          description: item.email,
          status: OperationStatus.failure,
          error: item.error || null,
        };
      }
      return {
        description: item.email,
        status: OperationStatus.success,
        error: null,
      };
    });
  }

  renderSummaryTitle(summary: OperationSummary<InvitationOutput>[]): string {
    const allSucceeded = summary.every((item) => item.error === undefined);

    if (allSucceeded) {
      return summary.length > 1 ? "Invitations sent!" : "Invitation sent!";
    }

    const allFailed = summary.every((item) => item.error !== undefined);

    if (allFailed) {
      return "All invitations failed.";
    }

    return "Some invitations failed";
  }

  renderSummary(summary: OperationSummary<InvitationOutput>[]): ReactElement {
    return (
      <>
        <h2>{this.renderSummaryTitle(summary)}</h2>
        <OperationsTable operations={this.mapSummary(summary)} />
      </>
    );
  }

  render(): ReactElement {
    const {waiting, error, confirmButtonDisabled, problem, summary} =
      this.state;
    const {services} = this.props;

    return (
      <div className={summary ? "summary" : "edit"}>
        <div className="summary-view">
          {summary && (
            <Grid item xs={12}>
              {this.renderSummary(summary)}
              <Button onClick={() => this.dismissSummary()}>
                Send a new invitation
              </Button>
            </Grid>
          )}
        </div>
        <div className="edit-view">
          {waiting && <Loader className="overlay" />}
          <InvitationSettingsView
            services={services}
            onChange={this.onSettingsChange.bind(this)}
          />
          <dl>
            <dt>Email address(es)</dt>
            <dd>
              <EmailInput
                onChange={this.onEmailsChange.bind(this)}
                ref={this.emailInput}
              />
            </dd>
          </dl>

          <Grid container spacing={2}>
            {error && (
              <Grid item xs={12}>
                <ErrorPanel error={error} />
              </Grid>
            )}
            {problem && (
              <Grid item xs={12}>
                <Alert
                  title={problem.title}
                  message={problem.message}
                  severity={problem.severity}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              {/*
              NB: do not use the button disabled api because it removes
                tabindex and when the user is fast, navigating with TAB selects
                a navigation element (causing page refresh if the user rapidly
                  does TAB + Enter)
              */}
              <div
                className={
                  "form-buttons " +
                  (confirmButtonDisabled ? "ui-disabled" : "ui-enabled")
                }
              >
                <Button
                  onClick={() => this.save()}
                  className="confirm-button"
                  color="secondary"
                >
                  Confirm
                </Button>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
