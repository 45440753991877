import {PaginatedSet} from "../domain/lists";
import {
  InvitationTableItem,
  InvitationsApi,
  InvitationRequestTableItem,
  ApproveInvitationOutput,
  ApproveInvitationInput,
  InvitationInput,
  InvitationOutput,
  BulkInvitationsInput,
  BulkInvitationsOutput,
} from "../domain/invitations";
import {get, del, post} from "../../common/fetch";
import Query from "../../common/query";
import {BaseURL} from ".";

export default class OneCustomerInvitationsApi implements InvitationsApi {
  getInvitations(
    page: number,
    sortBy: string,
    search: string,
    brandId: string,
    organizationId: string
  ): Promise<PaginatedSet<InvitationTableItem>> {
    const query = Query.write({
      page,
      search,
      brand: brandId,
      organization: organizationId,
      sortBy: sortBy,
      role: "Admin",
    });

    return get<PaginatedSet<InvitationTableItem>>(
      `${BaseURL}/invitations${query}`
    );
  }

  getInvitationsRequests(
    page: number,
    sortBy: string,
    search: string
  ): Promise<PaginatedSet<InvitationRequestTableItem>> {
    const query = Query.write({
      page,
      search,
      sortBy: sortBy,
    });

    return get<PaginatedSet<InvitationRequestTableItem>>(
      `${BaseURL}/invitations/invitationrequests${query}`
    );
  }

  approveInvitationRequest(
    data: ApproveInvitationInput
  ): Promise<ApproveInvitationOutput> {
    return post<ApproveInvitationOutput>(
      `${BaseURL}/invitation-requests/${data.requestId}/approve`,
      {
        brandId: data.brandId,
        applicationId: data.applicationId,
        cultureCode: data.cultureCode,
      }
    );
  }

  deleteInvitation(id: string): Promise<void> {
    return del<void>(`${BaseURL}/invitations/${id}`);
  }

  deleteInvitationRequest(id: string): Promise<void> {
    return del<void>(`${BaseURL}/invitation-requests/${id}`);
  }

  sendInvitation(input: InvitationInput): Promise<InvitationOutput> {
    return post<InvitationOutput>(
      `${BaseURL}/organizations/${input.organizationId}/invite`,
      input
    );
  }

  submitBulkInvitations(
    input: BulkInvitationsInput
  ): Promise<BulkInvitationsOutput> {
    return post<BulkInvitationsOutput>(`${BaseURL}/invitations/bulk`, input);
  }
}
